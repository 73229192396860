//////////////////////////////////////////////////
//                  Form
//////////////////////////////////////////////////

[class^="sf-icon-"], [class*=" sf-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $fontIcon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sf-icon-conf:before {
    content: "\e91a";
}
.sf-icon-loop:before {
    content: "\e918";
}
.sf-icon-viber:before {
    content: "\e917";
}
.sf-icon-add:before {
    content: "\e145";
}
.sf-icon-remove:before {
    content: "\e15b";
}
.sf-icon-arrows:before {
    content: "\e5c7";
}
.sf-icon-done:before {
    content: "\e5ca";
}
.sf-icon-close:before {
    content: "\e5cd";
}
.sf-icon-check-check:before {
    content: "\e834";
}
.sf-icon-check:before {
    content: "\e835";
}
.sf-icon-radio:before {
    content: "\e836";
}
.sf-icon-radio-check:before {
    content: "\e837";
}
.sf-icon-right-long:before {
    content: "\e8e4";
}
.sf-icon-camera:before {
    content: "\e900";
}
.sf-icon-top:before {
    content: "\e901";
}
.sf-icon-cart:before {
    content: "\e902";
}
.sf-icon-facebook:before {
    content: "\e903";
}
.sf-icon-gift:before {
    content: "\e904";
}
.sf-icon-googleplus:before {
    content: "\e905";
}
.sf-icon-heart:before {
    content: "\e906";
}
.sf-icon-instagram:before {
    content: "\e907";
}
.sf-icon-left:before {
    content: "\e908";
}
.sf-icon-login:before {
    content: "\e909";
}
.sf-icon-look:before {
    content: "\e90a";
}
.sf-icon-phone:before {
    content: "\e90b";
}
.sf-icon-right:before {
    content: "\e90c";
}
.sf-icon-search:before {
    content: "\e90d";
}
.sf-icon-mail:before {
    content: "\e90e";
}
.sf-icon-dollar:before {
    content: "\e90f";
}
.sf-icon-clock:before {
    content: "\e910";
}
.sf-icon-twitter:before {
    content: "\e911";
}
.sf-icon-user:before {
    content: "\e912";
}
.sf-icon-vk:before {
    content: "\e913";
}
.sf-icon-star:before {
    content: "\e914";
}
.sf-icon-zoom:before {
    content: "\e915";
}
.sf-icon-menu:before {
    content: "\e916";
}
.sf-icon-way:before {
    content: "\e919";
}
.sf-icon-delivery:before {
    content: "\e91f";
}












