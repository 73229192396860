@charset "utf-8";
/* CSS Document */

/* This is the zoom window. */
.cloud-zoom-big { overflow:hidden; position:absolute; top:1px!important; left:1px !important; background:#fff; }

.filterColor input[type="radio"], .filterSize input[type="radio"]{display:none}

.prodOneParam .filterColor input:checked + .color217 {
    background-position: -29px -804px;
}

div.prodOneParam div.filterColor label.color216, .basketProdOne span.color216 {background-color:#f0ead8;}
div.prodOneParam div.filterColor label.color217, .basketProdOne span.color217 {background-color: #fff;}
div.prodOneParam div.filterColor label.color223, .basketProdOne span.color223 {background-color:#8accda;}
div.prodOneParam div.filterColor label.color224, .basketProdOne span.color224 {background-color:#D7EF0F;}
div.prodOneParam div.filterColor label.color225, .basketProdOne span.color225 {background-color:#37ac14;}
div.prodOneParam div.filterColor label.color229, .basketProdOne span.color229 {background-color:#9B6A28;}
div.prodOneParam div.filterColor label.color231, .basketProdOne span.color231 {background-color:#f00;}
div.prodOneParam div.filterColor label.color241, .basketProdOne span.color241 {background-color:#F26F0F;}
div.prodOneParam div.filterColor label.color244, .basketProdOne span.color244 {background-color:#FA15DC;}
div.prodOneParam div.filterColor label.color255, .basketProdOne span.color255 {background-color:#ccc;}
div.prodOneParam div.filterColor label.color256, .basketProdOne span.color256 {background-color:#00f;}
div.prodOneParam div.filterColor label.color266, .basketProdOne span.color266 {background-color:#851776;}
div.prodOneParam div.filterColor label.color267, .basketProdOne span.color267 {background-color:#63703E;}
div.prodOneParam div.filterColor label.color268, .basketProdOne span.color268 {background-color:#000;}
div.prodOneParam div.filterColor label.color271, .basketProdOne span.color271 {background-color:#C22929;}

.color217,
.color216 {
    border: 1px solid #f7f7f7;

    &:before {
        border-color: #000 !important;
    }
}