//////////////////////////////////////////////////
//                  Sidebar
//////////////////////////////////////////////////


.left-col {
    float: left;
    width: 211px;
    margin-right: 29px;
    margin-bottom: 28px;
    position: relative;
    z-index: 10;
}

.brand-page .left-col {
    padding-top: 19px;
}

.left-col ul {
    margin: 0;
    padding: 0;
}

.left-col .category-title {
    display: block;
    position: relative;
    padding: 16px 20px 14px;
    background: #474747 /* #fe5135 */;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
}

.left-col .category-list-wrap {
    margin-bottom: 4px;
    list-style: none;
}

.left-col .category-title.open {
    cursor: default;
    background: #fe5135;
}

.left-col .category-title.open:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -8px;
    margin-left: -8px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #fe5135;
    border-bottom-width: 0;
}

.left-col .category-list {
    display: none;
    padding: 8px 0;
    background: #f3f3f3;
}

.left-col .category-list li {
    position: relative;
    padding: 0 20px;
    height: 27px;
    list-style: none;
    line-height: 26px;
}

.left-col .category-list li:hover a {
    color: #fe5135;
}

.left-col .category-list .active {
    background: #fff;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

.left-col .category-list .active:after {
    content: "";
    position: absolute;
    top: 1px;
    right: -24px;
    width: 0;
    height: 0;
    border: 13px solid transparent;
    border-left-width: 11px;
    border-left-color: #fff;
}

.left-col .category-list .active:before {
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: -2px;
    width: 4px;
    background: #fff;
    border-radius:0 3px 3px 0;
}

.left-col .category-list .active a {
    color: #fe5135;
}

.left-col .category-list a {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    color: #474747;
    text-decoration: none;
    /*letter-spacing:-0.01em;*/
}

.left-col .category-list li > span{
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.left-col .category-list .brend_name{
    color: #ababab;
    font-size: 12px;
    /*display: inline-block;*/
}

.left-col .category-list .active .brend_name{
    color: #ffac9e;
}

.left-col + .page-content {
    position: relative;
}

.left-col + .page-content .head-catalog:after {
    clear: none;
    display: none;
}

.left-col + .page-content .product-promo:first-child {
    /*    margin-left: 0;*/
}



/*----------------side brends menu----------------*/
.side-brends{
    margin: 20px 0;
}
.side-brends .side-brends-menu{
    padding: 8px 0;
    background: #f3f3f3;
}
.side-brends-menu li{
    list-style-type: none;
    padding: 4px 20px;
    line-height: 18px;
}
.side-brends-menu a{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    text-decoration: none;
}
.side-brends-menu a:hover{
    color: #fe5135;
}
.side-brends-menu .active a ,
.side-brends-menu a.active{
    color: #fe5135;
}