.filterBtn {
    border: none;
    background: #f15732;
    color: #fff;
    margin: 20px -15px;
    width: 735px;
    display: none;
}

.filterBlock {
    border-bottom: 2px solid #f7f7f7;
    //padding: 16px 0 14px;
    padding: 0;
    width: 100%;
    position: relative;
    min-height: 48px;

    @media screen and (min-width: 1025px){
        opacity: .7;
        transition: opacity $time;

        &:hover,
        &.open {
            opacity: 1;
        }
    }

    .filterBlock {
        border-bottom: 0;

        @media screen and (min-width: 1025px){
            opacity: .7;
            transition: opacity $time;

            &:hover,
            &.open {
                opacity: 1;
            }
        }

        .filterTop {
            .btnTurn,
            .btnTurnIn {

            }

            & + div {
                display: none;
                opacity: 0;
            }

        }

        &.open {
            .filterTop {

                & + div {
                    display: block;
                    opacity: 1;
                }
            }
        }

    }

    &.open {
        padding-bottom: 15px;
        max-height: 100000px;

        & > .filterTop {
            a {
                color: $color-blue;

                @media screen {
                    &:hover {
                        color: darken($color-blue, 7%);
                    }
                }
            }
        }

        .filterTop {
            margin: 0 0 15px;

            span {
                display: inline-block;
                font-weight: 400;
                //font-family: $font;
            }
        }

        .filterTop + div,
        .moreFilters,
        .moreBrands {
            display: block;
        }

        .btnTurn,
        .btnTurnIn {
            i {
                &:after {
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(.001);
                }
            }
        }
    }

    .filterTop {
        line-height: 17px;
        font-size: 13px;
        font-weight: 600;
        //font-family: $fontBold;
        margin: 0;
        padding: 0;
        position: relative;
        //text-transform: uppercase;
        text-transform: none;

        &:first-letter {
            text-transform: uppercase;
        }

        span {
            font-size: 12px;
            margin: 0 0 0 5px;
            text-transform: none;
            display: none
        }

        & + div {
            display: none;
        }
    }

    .btnTurn,
    .btnTurnIn {
        color: #231f20;
        display: block;
        padding: 16px 0 14px;

        i {
            background: transparent;
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 21px;
            right: 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                margin-top: -1px;
                height: 2px;
                background-color: #1d1d1d;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                width: 2px;
                margin-left: -1px;
                height: 100%;
                background-color: #1d1d1d;
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
        }

        @media screen and (min-width: 1025px) {
            transition: color $time;

            &:after {
                transition: opacity .3s, transform .3s, visibility .3s, background-color $time;
            }

            &:hover {
                color: rgba($text, .8);

                i:before,
                i:after {
                    background-color: rgba($text, .8);
                }
            }
        }
    }
    .btnTurnIn {
        display: none;
    }

    .no-option {
        padding-left: 10px;
    }

}

.filterBlock .btnTurnIn i,
.fltSize .overBlock .btnTurn i,
.fltColor .overBlock .btnTurn i {
    display: none
}

.filterBlock.withoutBtn {
    height: auto;
    overflow: hidden;
    padding: 0
}

.filterBlock.withoutBtn .btn {
    display: none
}

.attribute_box input[type="checkbox"],
.filterBrands input[type="checkbox"],
.stockBlock input[type="checkbox"],
.filterSize input[type="checkbox"],
.filterSize input[type="radio"],
.filterColor input[type="checkbox"],
.filterColor input[type="radio"],
.thanksQuiz input[type="radio"] {
    display: none;

    &[disabled] {
        display: none;

        & + label {
            display: none !important;
        }
    }
}

.filterColor {
    margin-left: -5px;
    margin-bottom: -5px;
    width: calc(100% + 5px);

    label {
        display: inline-block;
        //width: 18px;
        width: 40px;
        height: 18px;
        border-radius: 3px;
        margin: 0 0 5px 5px;
        border: 0;
        cursor: pointer;
        position: relative;

        &:before {
            @extend %before;
            width: 4px;
            height: 7px;
            border: 2px solid #fff;
            border-left: 0;
            border-top: 0;
            transform: rotate(45deg);
            top: 5px;
            left: 50%;
            margin-left: -1px;
            opacity: 0;
            visibility: hidden;
        }

        @media screen {
            transition: box-shadow $time;
            &:hover {
                box-shadow: 0 0 5px rgba(#000,.5);
            }
        }

    }
    input:checked + label {
        box-shadow: 0 0 5px rgba(#000,.5);
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }


    .filterBlock & {
        margin-left: 0;
        margin-bottom: 0;
        width: initial;
    }
}

.filterSize {
    margin: 0;
    float: none !important;
    @extend %clear;

    label {
        cursor: pointer;
        display: block;
        float: left;
        font-size: 11px;
        margin: 0 5px 5px 0;
        border-radius: 3px;
        width: 50px;
        height: 20px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #eee;
        color: $text;
        position: relative;

        &:before {
            @extend %before;
            width: 4px;
            height: 7px;
            border: 2px solid #fff;
            border-left: 0;
            border-top: 0;
            transform: rotate(45deg);
            top: 5px;
            left: 7px;
            opacity: 0;
            visibility: hidden;
        }

        &[title="Oversize"] {
            width: auto !important;
            padding: 0 5px 0 18px;
        }
    }

    input:checked {
        & + label {
            border-color: $color-blue;
            color: #fff;
            background-color: $color-blue;

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }
    }

}

.quickRight {
    .filterSize {
        label {
            @media screen and (min-width: 1025px) {
                &:hover {
                    background-color: $color-blue;
                    color: #fff;
                    border-color: $color-blue;
                }
            }
        }
    }
}

//.prodOneParam .filterColor input:checked + label {
//    border: 1px solid #f15732 !important
//}

//.prodOneParam .filterColor label {
//    @media screen and (min-width: 1025px) {
        //&:hover {
        //    border: 1px solid #f15732 !important
        //}
    //}
//}

//.prodOneParam .filterColor input:checked + label {
//    background: url(../../images/icons_sprite.png) 21px -804px no-repeat
//}

.white {
    background-color: #fff;
    //border: 1px solid #dfdfdf
}

//.prodOneParam .filterColor input:checked + .white {
//    background-position: -29px -804px
//}

//.catalogLeft .filterColor label {
//    border: none;
//    font-size: 13px;
//    height: auto;
//    text-align: left
//}


//.catalogLeft .filterColor {
//    &.fltCol {
//        column-count: 2;
//    }
//}

//.catalogLeft .filterColor input:checked + label {
//    background-color: transparent
//}

//.catalogLeft .filterColor label {
//    @media screen and (min-width: 1025px) {
//        &:hover {
//            background-color: transparent
//        }
//    }
//}

.fltPrice {
    ins {
        text-decoration: none;
        font-style: normal;
    }

    @media screen and (min-width: 1025px) {
        ins {
            &.desctop-hide {
                display: none;
            }
            &.mobile-hide {
                display: initial;
                margin: 0 7px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        ins {
            &.desctop-hide {
                display: initial;
            }
            &.mobile-hide {
                display: none;

                & + .priceInp {
                    margin: 0 5px 0 10px;
                }
            }
        }
    }
}

.noUi-target,
.noUi-background {
    border: none;
    border-radius: 0;
    background: #F5F5F5;
    box-shadow: none;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.noUi-origin {
    border-radius: 0
}

.noUi-connect {
    background: #c0c0c0;
    box-shadow: none
}

.noUi-horizontal {
    height: 6px;
    margin: 20px 0;
    padding: 0 0 0 10px;

    .noUi-handle {
        border: none;
        box-shadow: none;
        background-color: #c0c0c0;
        border-radius: 0;
        cursor: pointer;
        width: 10px;
        height: 18px;
        left: -10px;

        &:after,
        &:before {
            display: none;
        }

    }
}

.priceInp {
    border: 1px solid #dfdfdf;
    display: inline-block;
    height: 30px;
    line-height: 29px;
    width: 69px;
    text-align: center;
    margin: 0 10px;
    padding: 0 3px;

    #priceSlider + & {
        margin: 0 10px 0 0
    }

    & + span {
        margin-left: 10px;
    }

    @media screen and (min-width: 1025px) {
        margin: 0;
    }

    @media screen and (max-width: 1200px) and (min-width: 1025px) {
        width: 55px;
    }
}
.hiddenblockoption_13.open .btnTurn i:before{
	transform: rotate(90deg);
}
.sizeBlock {
    column-count: 3;

    .ajax {
        display: inline-block;
        position: relative;
        @extend %toh;
        font-size: 13px;
        font-weight: 400;
        color: $grey;
        line-height: 16px;
        padding: 0 0 0 20px;
        margin: 0 0 10px;
        width: 100%;
		//min-width: 50px;

        &:empty {
            display: none;
        }

        &:before {
            @extend %before;
            top: 1px;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 4px;
        }

        &:after {
            @extend %before;
            top: 3px;
            left: 5px;
            width: 4px;
            height: 8px;
            border: 2px solid #fff;
            border-left-width: 0;
            border-top-width: 0;
            opacity: 0;
            transform: rotate(35deg);
        }

        @media screen {
            transition: color $time ease-in-out .1s;

            &:before {
                transition: border-color $time ease-in-out .1s;
            }

            &:after {
                transition: opacity $time ease-in-out .1s;
            }

            &:hover {
                color: $text;

                &:before {
                    border-color: #ccc;
                }
            }
        }

        &.is-checked {
            color: $text;

            &:before {
                border-color: $color-blue;
                background-color: $color-blue;
            }
            &:after {
                opacity: 1;
            }
        }
    }
}

.stockBlock,
.scroll-perfect {
    .ajax {
        display: block;
        position: relative;
        @extend %toh;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        color: $grey;
        line-height: 16px;
        padding: 0 0 0 25px;
        margin: 0 0 10px;

        &:empty {
            display: none;
        }

        &:before {
            @extend %before;
            top: 1px;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 4px;
        }

        &:after {
            @extend %before;
            top: 3px;
            left: 5px;
            width: 4px;
            height: 8px;
            border: 2px solid #fff;
            border-left-width: 0;
            border-top-width: 0;
            opacity: 0;
            transform: rotate(35deg);
        }

        @media screen {
            transition: color $time ease-in-out .1s;

            &:before {
                transition: border-color $time ease-in-out .1s;
            }

            &:after {
                transition: opacity $time ease-in-out .1s;
            }

            &:hover {
                color: $text;

                &:before {
                    border-color: #ccc;
                }
            }
        }

        &.is-checked {
            color: $text;

            &:before {
                border-color: $color-blue;
                background-color: $color-blue;
            }
            &:after {
                opacity: 1;
            }
        }
    }
}

.stockBlock {
    .ajax {
        &:nth-child(3) {
            color: $color-red !important;
        }
    }
}

.scroll-perfect {
    position: relative;
    max-height: 180px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        max-height: initial !important;
    }
}

.foot-filter-block {
    //padding-top: 15px;
    //border-top: 1px solid #e9e9e9;
    //margin-top: 15px;
    //text-align: right;
    text-align: left;

    button {
        //padding: 0 15px;
        padding: 0;
        background-color: transparent;
        height: 24px;
        font-size: 13px;
        color: $color-blue;
        border: 0;
        border-bottom: 1px solid rgba($color-blue, .5);

        @media screen {
            transition: border-color $time;

            &:hover {
                border-bottom-color: rgba($color-blue,0);
            }
        }
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.hiddenblockcategories {
     .scroll-perfect {
         max-height: initial !important;
     }
}

//.hiddenblockoption_13 {
//    .scroll-perfect {
//        column-count: 2;
//    }
//}

.is-full-view {
    .scroll-perfect {
        max-height: initial !important;
    }
}

.filterColor {
    &.nofltCol {
        .scroll-perfect {
            @extend %clear;

            .ajax {
                float: left;
                width: 50% !important;
                margin: 0 0 10px;
            }
        }
    }
}

.fltBrands {

    .sf-icon-search {
        display: none;
    }

    @media screen and (min-width: 1025px) {

        &.is-modal-open {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000000;
            background-color: rgba(#000, .8);

            .filterTop {
                display: none;
            }

            .filter-brand-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 1000;
                background-color: transparent;
                height: 100%;
                cursor: pointer;
            }

            .sf-icon-search {
                position: absolute;
                display: block;
                top: 100px;
                left: 50%;
                margin-left: 485px;
                color: #fff;
                cursor: pointer;
                font-size: 24px;
                line-height: 50px;

                &:before {
                    content: "\e5cd";
                }
            }

            #searchbrand {
                position: absolute;
                top: 100px;
                height: 50px;
                font-size: 24px;
                width: 940px;
                left: 50%;
                margin-left: -470px;
                z-index: 10000;
                border-width: 0 0 1px 0;
                border-radius: 4px 4px 0 0;

                &:-ms-input-placeholder {
                    opacity: 1;
                }
                &:-moz-placeholder {
                    opacity: 1;
                }
                &::-moz-placeholder {
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    opacity: 1;
                }
            }

            .is-full-view {
                bottom: 0;
                overflow-y: scroll;
                background-color: #fff;
                z-index: 10000;
                position: absolute;
                width: 940px;
                left: 50%;
                margin-left: -470px;
                max-height: 500px;
                top: 150px;
                padding: 20px;

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #f7f7f7;
                }

                &::-webkit-scrollbar-thumb {
                    cursor: pointer;
                }

                .scroll-perfect {
                    overflow-y: initial;
                }
            }
        }
    }

}

.filterBrands {
    .foot-filter-block {
        border-top: 0;
        margin-top: 0;
        text-align: left;
    }

    &.is-short {
        #searchbrand,
        .foot-filter-block {
            display: none !important;
        }
        .scroll-perfect {
            overflow: initial !important;
        }
    }

    @media screen and (min-width: 1025px) {
        &.is-full-view {

            .scroll-perfect {
                column-count: 4;
                column-gap: 30px;
                //max-width: 80%;
                width: 100%;
                margin: 0 auto;
            }

            .foot-filter-block {
                position: fixed;
                padding: 0;
                border: 0;
                margin: 0;
                right: 0;
                z-index: 10200;
                top: 0;
                -webkit-backface-visibility: hidden;

                button {
                    width: 40px;
                    text-indent: 100px;
                    overflow: hidden;
                    padding: 0;
                    height: 40px;
                    background: transparent;
                    border: 0;
                }
            }

        }
    }
}

.catalogLeft {

    .sortBlock {
        .sortTitle + a + a + a + a {
            display: none;
        }
    }

    .filtersSort {
        margin-bottom: 0;

        & > span {
            display: block;
            position: relative;
            padding-bottom: 7px;
            line-height: 31px;
        }

        a {
            &.clear {
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}

//  Widget Category on filter

.tree {

    .filterTop {
        margin-bottom: 0 !important;
    }

    .tree {
        border: 0 !important;
        min-height: 16px;

        .filterTop {
            padding: 0;

            a {
                padding: 10px 0 8px 15px;
                font-weight: 400;
                //font-family: $font;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                position: relative;

                &:before {
                    font-family: $fontIcon;
                    content: "\e5c7";
                    position: absolute;
                    left: -5px;
                    top: 50%;
                    font-size: 16px;
                    transform: rotate(90deg) translateX(-50%);
                    color: $grey;
                }

                span {
                    display: inline;
                    color: #8e8e8e;
                }

                i {
                    right: initial;
                    left: 0;
                    overflow: hidden;
                    top: 14px;
                    width: 8px;
                    height: 8px;
                    display: none;

                    &:before {
                        display: none;
                    }
                    &:after {
                        background-color: #eee;
                        width: 100%;
                        transform: rotate(45deg);
                        top: 0;
                        left: -4px;
                        opacity: 1;
                        visibility: visible;
                    }
                }

                @media screen {
                    &:hover {
                        color: $color-blue;
                        span {
                            color: $color-blue;
                        }
                    }
                }

            }

            & + div {
                display: none;
            }
        }

        label {
            a {
                color: $grey;

                span {
                    color: #8e8e8e;
                }

                &:hover {
                    color: $dark;

                    span {
                        color: darken($grey, 10%);
                    }
                }
            }
        }

        input:checked + label {
            a {
                color: $dark;
            }
        }

        &.open {
            .filterTop {
                a {
                    font-weight: 400;
                    //font-family: $fontSbold;
                    color: $text;

                    &:before {
                        transform: rotate(180deg) translateY(50%);

                    }

                    i {
                        top: 17px;
                        display: none;
/*                        transform: rotate(90deg);*/

                        &:after {
                            background-color: $text;
                        }
                    }
                }

                & + div {
                    display: block;
                    padding: 5px 0 0 15px;
                }
            }
        }

    }

    &.scroll {
        height: 160px;
        line-height: 16px;
        margin: 0 0 18px
    }

    input {

        &[type="checkbox"] {
            display: none;
        }

    }

}

//  Brands
.filterBrands {
    position: relative;

    //.sf-icon-search {
    //    fill: $text;
    //    width: 12px;
    //    height: 12px;
    //    position: absolute;
    //    top: 17px;
    //    left: 10px;
    //    margin-top: -7px;
    //    z-index: 2;
    //
    //    & + input {
    //        padding-left: 30px;
    //    }
    //}

    .scroll-perfect {
        //max-height: initial;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f7f7f7;
        }

        &::-webkit-scrollbar-thumb {
            cursor: pointer;
        }

        //.ajax {
        //    &:first-child {
        //        position: absolute;
        //        left: -100000px;
        //        top: -100px;
        //    }
        //}

        @media screen and (max-width: 1024px) {
            overflow: initial;
        }
    }

    //.collapse-filter-btn {
    //    display: none;
    //
    //    //display: initial;
    //    width: 100%;
    //    text-align: left;
    //    padding: 0 12px;
    //    background-color: transparent;
    //    border: 1px solid $red;
    //    margin-top: 15px;
    //    position: relative;
    //    height: 40px;
    //
    //    &:before {
    //        @extend %before;
    //        background: url(../../images/icons_sprite.png) no-repeat 0 -1211px;
    //        width: 5px;
    //        height: 9px;
    //        top: 20px;
    //        right: 10px;
    //        margin-top: -5px;
    //    }
    //
    //    &.is-open {
    //        &:before {
    //            background-position: -391px -1216px;
    //            width: 9px;
    //            height: 5px;
    //            margin-top: -3px;
    //        }
    //    }
    //}

    input[type="checkbox"] {
        &.inp-hidden {
            & + label {
                display: none !important;
            }
        }
    }

    label {
        &:empty {
            display: none;
        }
    }

}

//  atributes
.attribute_box {

    .scroll-perfect {
        //max-height: initial;
    }

    &.open .filterTop{
        display:block !important;
    }
    a {
        color: $color-grey;

        &.is-checked {
            color: $color-black;
			position: relative;
			padding-left: 20px;
			&:before {
				@extend %before;
				top: 4px;
				left: 0;
				width: 14px;
				height: 14px;
				border: 1px solid #e0e0e0;
				border-radius: 4px;
				border-color: $color-blue;
				background-color: $color-blue;
			}

			&:after {
				@extend %before;
				top: 6px;
				left: 5px;
				width: 4px;
				height: 8px;
				border: 2px solid #fff;
				border-left-width: 0;
				border-top-width: 0;
				transform: rotate(35deg);
				opacity: 1;
			}
        }
    }

    input[type="checkbox"] {
        &.inp-hidden {
            & + label {
                display: none !important;
            }
        }
    }

}

//  Fixation

.catalog {

    .col15-lg-3 {

    }

    .filter-result {
        display: none;
        background-color: $dark;
        padding: 12px 20px;
        text-align: right;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @extend %clear;

        button {
            border: 1px solid #fff;
            padding: 0 10px;
            background: $dark;
            color: #fff;
            height: 36px;
            border-radius: 4px;

            &.filter-button-reset {
                float: left;
            }

            &.filter-button-apply {
                &.is-active {
                    border-color: $color-blue;
                    background-color: $color-blue;
                }
            }
        }

    }

    .filter-button-close {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border: 0;
        padding: 0 20px;
        text-align: left;
        background-color: $dark;
        color: #fff;
        font-size: 15px;
        height: 50px;
        line-height: 50px;

        i {
            font-size: 24px;
            vertical-align: middle;
            position: relative;
            top: -1px;
        }

        span {
            display: block;
            border: 1px solid rgba($red, 0);
            background: rgba(#000, 0);
            width: 40px;
            height: 40px;
            position: absolute;
            top: 25px;
            margin-top: -20px;
            right: 10px;
            cursor: pointer;
            text-indent: -200px;
            overflow: hidden;

            &:before,
            &:after {
                @extend %before;
                width: 15px;
                height: 1px;
                top: 50%;
                left: 50%;
                margin-left: -7px;
                background-color: #fff;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        @media screen and (min-width: 1025px) {

            span {
                transition: border-color $time;

                &:before,
                &:after {
                    transition: background-color $time;
                }

                &:hover {
                    border-color: rgba($red, .5);

                    &:before,
                    &:after {
                        background-color: $red;
                    }
                }
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 0 15px;
            display: initial;
        }

        @media screen and (max-width: 800px) {
            span {
                right: 0;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .catBanner {
            display: none;
        }
        .col15-lg-3 {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000000;
            overflow: hidden;
            transform: translate3d(-100%, 0, 0);
            transition: transform $time;
            //overflow-y: auto;
            //-webkit-overflow-scrolling: touch;

            &.is-open {
                transform: translate3d(0, 0, 0);
            }
        }
        .filter-result {
            display: block;
            //position: fixed;
            //bottom: 0;
            //left: -100%;
            //width: 100%;
            //transition: left $time;
        }
    }

    @media screen and (max-width: 500px) {
        .filter-result {
            .filter-button-close {
                right: 0;
            }
        }
    }

}

.super-filter {
    position: static;

    form {
        & ~ .ps-scrollbar-y-rail {
            right: 8px !important;
        }
    }

    .catalogLeft {

        &.is-fixed {
            top: 20px;
            position: fixed;
            //opacity: 1;

            //&.is-top-down {
            //    top: 90px;
            //}

            form {
                position: relative;
                padding-right: 30px;
                margin-right: -30px;
            }
        }

        &.is-abs {
            position: absolute;
            bottom: 20px;
            top: auto;

            form {
                position: relative;
                padding-right: 30px;
                margin-right: -30px;
            }
        }

        @media screen and (min-width: 1025px) {
            transition: top $time;

            & > .ps-scrollbar-y-rail {
                visibility: hidden;
            }

            &:hover {
                & > .ps-scrollbar-y-rail {
                    visibility: visible;
                }
            }

            &.is-fixed {
                .filterBlock {
                    & ~ .similarLinks {
                        display: none;
                    }
                }

                form {
                    & ~ .similarLinks {
                        display: none;
                    }
                }
            }

            &.catalogLeftBrand {
                &.is-fixed{
                    form {
                        & ~ .similarLinks {
                            display: block;
                        }
                    }
                }
            }

        }

    }

    @media screen and (max-width: 1024px) {
        transition: left $time;
        background-color: #fff;
        padding: 0;
        z-index: 100000;

        .catalogLeft {
            position: absolute;
            top: 50px;
            bottom: 60px;
            left: 0;
            right: 0;
            padding: 0 20px;
            opacity: 1;
            margin: 0;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            .filterBlock {
                & ~ .similarLinks {
                    display: none;
                }
            }

            form {
                & ~ .similarLinks {
                    display: none;
                }
            }

            &.catalogLeftBrand {
                form {
                    & ~ .similarLinks {
                        display: block;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        .catalogLeft {
            padding: 0 15px;
        }
    }

}


.header-view {
    .catalogLeft.is-fixed {
        top: 90px;
    }
    .filter-sort-wrap.is-fixed {
        .filtersSort {
            top: 64px;
        }
    }
}

#products_filtered {
    position: absolute;
    top: -1000000px;
    left: -10000000px;
    display: block;
}

.filterBrands {
    input.hbrand+label {
	    display: none !important;
    }
    .ajax {
        &.is-search-brand {
            display: none !important;
        }
    }
}

#searchbrand {
    width: 100%;
    margin-bottom: 20px;
    height: 30px;
    border: 2px solid #f7f7f7;
    background-color: #f7f7f7;
    font-size: 13px;
    padding: 0 10px;
    border-radius: 4px;

    &:focus {
        background-color: #fff;
    }

    //&:-ms-input-placeholder {
    //    opacity: 0;
    //}
    //&:-moz-placeholder {
    //    opacity: 0;
    //}
    //&::-moz-placeholder {
    //    opacity: 0;
    //}
    //&::-webkit-input-placeholder {
    //    opacity: 0;
    //}
}

.attribute_box.open .filterTop {
	margin: 0 !important;
}

.attribute_box .filterTop{
	margin: 0 !important;
}

.tree{
	display: none;
}
.filterBlock {
	display: block;
}
.tree.open>.tree{
	display: block;
}
.tree>p{
	display: none;
}
.tree.open>p{
	display: block;
}
.filterBlock>p{
	display: block;
}
.tree>label{
	display: none;
}
.tree.open>label{
	display: block;
}

.tree>.filterTop a i{
	transform: rotate(0deg);
}
.tree.open>.filterTop a i{
	transform: rotate(90deg);
}
#tree0>.filterTop a i {
    -webkit-transform: rotate(0deg) !important;
    -ms-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
}

.tree .tree .tree .tree {

    .filterTop {

        a {
            max-height: 0;
            margin-top: -18px;
            text-indent: -10000px;
            overflow: visible;

            &:before {
                text-indent: 0;
                z-index: 40;
                transform: rotate(90deg) translateX(17px) translateY(11px);
                top: 0;
            }
        }

        & + .ajax {
            &.is-checked {
                & + .tree {
                    display: block !important;
                }
            }
        }
    }

    .tree {
        padding-bottom: 0 !important;
        margin-left: 10px;
        opacity: 1;
        display: none !important;
    }

    &.open {
        .tree {
            display: block !important;
        }
    }




}



.filter-action-result {

    .clear {
        box-shadow: 0 1px 1px -1px rgba(#000,.1);
    }

    @media screen and (min-width: 1025px) {
        display: none;
    }
}


.hrefAdd {
    float: left;
    background-color: #fff;
    font-size: 16px;
    opacity: 0;
    width: 20px;
    display: block;
    position: relative;
    z-index: 1;
    line-height: 16px;
    text-align: left;
    transform: translateX(-1px);

    i {
        font-family: $fontIcon;
        font-style: normal;

        &:before {
            content: "\e145";
        }
    }

    &.is-remove {
        i:before {
            content: "\e15b";
        }
    }

    @media screen {
        transition: opacity $time;

        &:hover {
            opacity: 1;
        }
    }

    & + .ajax {
        margin-left: -20px !important;
        //margin-bottom: 15px !important;
        float: left;

        //&.is-checked {
        //    z-index: 2;
        //}
    }

}

.hiddenblockcategories2 .scroll-perfect {
    max-height: initial !important;
}

.subscribe-widget {
    //background-color: #fff;
    background-color: #b6e4ff;
    text-align: center;
    padding: 20px;
    margin-left: -15px;
    margin-bottom: 15px;
    //margin-top: 15px;
    border-radius: 4px;
    border: 1px solid #8cc7ea;
    box-shadow: 0 1px 3px rgba($color-grey,.25);

    h4 {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin: 10px 0 15px;
    }

    p {
        font-size: 13px;
        margin-bottom: 25px;
        color: #666;
        //color: #a8a8a8;
    }

    input {
        width: 100%;
        height: 30px;
        border: 1px solid #ddd;
        font-size: 13px;
        background-color: #f7f7f7;
        padding: 0 10px;
        box-sizing: border-box;
        vertical-align: top;
        border-radius: 4px;

        @media screen {
            transition: background-color $time ease-in-out;

            &:focus {
                background-color: #fff;
            }
        }
    }

    button {
        width: 100%;
        margin-top: 10px;
        border: 0;
        background-color: $color-blue;
        color: #fff;
        font-size: 13px;
        border-radius: 4px;
        height: 30px;

        @media screen {
            transition: box-shadow $time ease-in-out;

            &:hover {
                box-shadow: 0 1px 3px rgba($color-grey,.25);
            }
        }
    }

}