//////////////////////////////////////////////////
//                  Sliders
//////////////////////////////////////////////////

/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none;
    }
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider {
    .slick-track,
    .slick-list {
        transform: translate3d(0, 0, 0);
    }
}


.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;

    &:before,
    &:after {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }

    &:focus {
        outline: none;
    }
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;

    img {
        display: inline-block;
        max-width: 100%;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    &:focus {
        outline: 0;
    }
}

.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}


.slick-arrow {
    height: 40px;
    width: 40px;
    padding-left: 40px;
    overflow: hidden;
    color: $color-black;
    border: 0;
    background-color: #fff;

    &.slick-hidden {
        display: none;
    }

    &:before {
        font-family: $fontIcon;
        font-size: 14px;
        content: "\e919";
        display: block;
        width: 40px;
        line-height: 40px;
        text-align: center;
        position: relative;
        left: -40px;
        transform: rotate(180deg);
    }

    &.slick-prev {
        &:before {
            transform: rotate(0deg);
        }
    }

    @media screen {
        transition: color $time;

        &:hover {
            color: $color-blue;
        }
    }
}

//  Site sliders

.slider-line {
    padding: 30px 0;

    &-head {
        font-size: 16px;
        display: block;
        text-transform: none;
        padding: 10px 0;
        margin: 0;
        font-weight: 600;
    }

    &-wrap {
        margin-left: -10px;
        margin-right: -10px;
    }

    .p-card-item {
        margin: 0 10px;
    }

    .slick-list {
        padding: 10px 0 5px;
    }

    .slick-arrow {
        position: absolute;
        height: 30px;
        top: -37px;
        right: 7px;
        border: 1px solid #eee;

        &:before {
            line-height: 28px;
        }

        &.slick-prev {
            right: 48px;
        }

        @media screen and (max-width: 1024px) {
            background-color: transparent;
            border-color: #d3d3d3;
        }
    }

    &-hits {
        @media screen and (max-width: 1023px) {
            padding-top: 15px;
        }
    }
}


.product-slides {
    @media screen and (min-width: 426px) {
        &.is-modal-open {

            .prs-thumbs {
                position: fixed;
                left: 15px;
                top: 80px;
                z-index: 10000001;
            }
        }
    }
}

.prs-slides {

    .prs-s-item {
        text-align: center;
        position: relative;
        cursor: pointer;
        height: initial;
        min-height: 424px;

        img {
            display: inline-block;
            max-height: 462px;
        }

        &:before {
            @extend %before;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#fff, 0);
            z-index: 2;
            transition: background-color $time;
        }
        i {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            top: 50%;
            left: 50%;
            z-index: 3;
            font-size: 30px;
            transform: translate(-50%, -50%);
            transition: visibility $time, opacity $time;
        }

        @media screen and (min-width: 1025px) {
            &:hover {
                &:before {
                    background-color: rgba(#fff, .8);
                }
                i {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        @media screen and (max-width: 1024px) {
             i {
                 bottom: 25px;
                 right: 0;
                 top: initial;
                 left: initial;
                 transform: none;
                 opacity: 1;
                 visibility: visible;
             }
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        background-color: transparent;

        &.slick-prev {
            left: 0;
            opacity: 0;
            visibility: hidden;
        }

        &.slick-next {
            right: 0;
        }

        @media screen and (max-width: 1024px) {
            &.slick-next {
                right: -15px;
            }
        }
    }

}

.prs-thumbs {
    position: absolute;
    top: 20px;
    left: 0;
    width: 60px;
    z-index: 5;

    .prs-t-item {
        border: 2px solid transparent;
        transition: border-color $time;

        &.slick-current {
            border-color: $color-blue;
        }
    }

    @media screen and (max-width: 1024px) {
        left: 15px;
    }
    @media screen and (max-width: 425px) {
        opacity: 0;
        visibility: hidden;
    }
}

.pps-slider {
    height: 100vh;

    .prs-b-item {
        height: 100vh;
        text-align: center;

        .easyzoom {
            display: inline-block;
            position: relative;
            width: 100%;
        }

        .easyzoom-flyout {
            img {
                max-width: initial;
                max-height: initial;
                height: initial;
                width: initial;
            }
        }

        a {
            //display: inline-block;
            display: flex;
            text-align: center;
            height: 100vh;
            line-height: 100vh;
            width: 100%;
        }

        img {
            //display: inline-block;
            //vertical-align: middle;
            max-width: 100%;
            max-height: calc(100vh - 100px);
            margin: auto;
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        background-color: transparent;

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;
        }
    }

}

.quick-slides {
    border: 15px solid #fff;

    .qs-item {
        min-height: 400px;
        text-align: center;

        a {
            display: inline-block;
            text-align: center;
            height: 400px;
            line-height: 400px;
            width: 100%;
        }

        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            max-height: calc(100vh - 100px);
        }

        .easyzoom {
            display: inline-block;
            position: relative;
            width: 100%;
        }

        .easyzoom-flyout {
            img {
                max-width: initial;
                max-height: initial;
                height: initial;
                width: initial;
            }
        }

    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        z-index: 10;

        &.slick-prev {
            left: -15px;
        }

        &.slick-next {
            right: -15px;
        }
    }

}

.slide-buy {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 200px;
    border: 0;
    border-radius: 4px;
    background-color: $color-blue;
    color: #fff;
    z-index: 100;
    font-size: 14px;
    font-weight: 600;
    height: 40px;

    @media screen {
        transition: box-shadow $time;

        &:hover {
            box-shadow: 0 0 10px rgba(#000, .5);
        }
    }

    @media screen and (min-width: 1001px) {
        display: none;
    }
}