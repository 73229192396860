//////////////////////////////////////////////////
//                  Simple
//////////////////////////////////////////////////


.simple-content {
    margin: 0px 5px;
}
.simple-content label {
    float: none !important;
    width: auto !important;
}
.simple-content .buttons {
    margin: 0px 0px 15px 0px;
    border: none;
}
#simplecheckout_login .buttons {
    margin: 0;
    padding: 0;
    border: none;
}
.simplecheckout {
    overflow:hidden;
    width:100%;
}
.simplecheckout-three-column {
    float:left;
    margin-right:10px;
    min-width: 200px;
    max-width: 500px;
    width:32%;
}
.simplecheckout-left-column {
    float: left;
    margin-bottom: 20px;
}
.simplecheckout-right-column {
    float: right;
    margin-bottom: 20px;
}
@media only screen and (max-width:768px) {
    .simplecheckout-left-column {
        display:block;
        float: none;
        min-width: auto !important;
        max-width: 1900px !important;
        width: 100%;
    }
    .simplecheckout-right-column {
        display:block;
        float: none;
        min-width: auto !important;
        max-width: 1900px !important;
        width: 100%;
    }
}
.simplecheckout-block {
    clear: both;
    /*margin-bottom: 20px;*/
    position: relative;
    overflow: hidden;
}
.simplecheckout-block-heading {
    overflow:hidden;
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #DBDEE1;
    background: none repeat scroll 0 0 #F8F8F8;
    color: #555555;
    //font-weight: bold;
    font-weight: 700;
    //font-family: $fontBold;
    font-size: 13px;
}
.simplecheckout-block-heading-button {
    float:right;
}
.simplecheckout-block-heading-button a {
    display:inline-block;
}
.simplecheckout-block-content {
    overflow:hidden;
}
.simpleregister {
    margin-bottom: 5px;
}
.simpleregister-block-content {
    overflow:hidden;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #EEEEEE;
}
.simpleregister-block-content .simplecheckout-customer {
    width: 50%;
}
@media only screen and (max-width:600px) {
    .simpleregister-block-content .simplecheckout-customer {
        width: 100%;
    }
}
.simpleregister-button-block {
    overflow: auto;
    margin-bottom: 20px;
    padding: 6px;
    border: 1px solid #EEEEEE !important;
    background: none repeat scroll 0 0 #FFFFFF;
    text-align:right;
}
.simpleregister-button-right {
    float: right;
    text-align: right;
}
.simpleregister-button-left {
    float: left;
    text-align: left;
}
.simplecheckout-methods-table {
    width: 100%;
}
.simplecheckout-methods-table td.code {
    width: 1px;
}
.simplecheckout-methods-table td.title {
    padding: 5px;
}
.simplecheckout-methods-table td.title label {
    display: block !important;
}
.simplecheckout-methods-table td.quote {
    width: 15%;
    text-align: right;
}
.simplecheckout-required {
    color: #FE5135;/*#B61015*/
    /*font-weight: bold;*/
}
.simplecheckout-button-block {
    clear: both;
    overflow: auto;
    margin-bottom: 20px;
    padding: 6px;
    border: 1px solid #EEEEEE !important;
    background: none repeat scroll 0 0 #FFFFFF;
    text-align:right;
}
.simplecheckout-button-right {
    float: right;
    text-align: right;
}
.simplecheckout-button-left {
    float: left;
    text-align: left;
}
.simplecheckout-button {
    display: inline-block;
    padding: 6px 12px 6px 12px;
    border-radius: 7px;
    //background: url(../image/button.png) top left repeat-x;
    box-shadow: 0 2px 2px #DDDDDD;
    color: #FFFFFF;
    text-decoration: none;
    //font-weight: bold;
    font-weight: 700;
    //font-family: $fontBold;
    font-size: 12px;
    font-family: sans-serif;
    line-height: 12px;
    cursor: pointer;
}
.simplecheckout-button-left .button {
    margin: 0;
}
@media only screen and (max-width:780px) {
    .simplecheckout-button-left {
        display: none;
    }
}
.simplecheckout-button:hover {
    //background: url(../image/button-active.png) repeat-x;
}

#simplecheckout_customer .simplecheckout-block-content {
    text-align: center;
}
.simplecheckout-login {
    display: inline-block;
}
.simplecheckout-login-left {
    width:50%;
    text-align: left;
}
.simplecheckout-login-right {
    width:50%;
    text-align:left;
}
.simplecheckout-login-right .button {
    float: left;
    margin: 0;
}
.simplecheckout-login-right input {
    max-width: 160px !important;
    text-align:left;
}
.simplecheckout-customer-block {
    //overflow: hidden;
    /*padding: 5px;*/
}
.simplecheckout-company-header {
    display: block;
    margin-bottom: 10px;
    text-align: left;
    font-weight:700;
    //font-family: $fontBold;
}
.simplecheckout-customer-left {
    /*padding-right: 10px;
    width: 50%;
    text-align: left;*/
    width:138px;
    font:14px/32px 'heliosregular', sans-serif;
    color:#474747;
    padding-bottom:8px;
}
.simplecheckout-customer-right {
    /*width: 50%;
    text-align: left;*/
    text-align:right;
    padding-bottom:8px;
    padding-right: 0;
}
.simplecheckout-customer-right input[type=text],.simplecheckout-customer-right input[type=password],.simplecheckout-customer-right textarea,.simplecheckout-customer-right select {
    width: 99%;
}

.simplecheckout-customer-one-column {
    margin-bottom: 0px;
    width: 100%;/*98%*/
    text-align: left;
    border-spacing: 0;
}

.simplecheckout-customer-one-column td{
}

.simplecheckout-customer-two-column-left {
    float: left;
    margin-right: 20px;
    margin-bottom: 0px;
    width: 48%;
    text-align: left;
}
.simplecheckout-customer-two-column-right {
    float: left;
    margin-bottom: 0px;
    width: 48%;
    text-align: left;
}
@media only screen and (max-width:640px) {
    .simplecheckout-customer-two-column-left {
        margin-bottom: 0px;
        width: 98%;
        text-align: left;
    }
    .simplecheckout-customer-two-column-right {
        margin-bottom: 0px;
        width: 98%;
        text-align: left;
    }
}
.simplecheckout-customer-type-select {
    margin-bottom: 10px;
    text-align: left;
}
.simplecheckout-customer-address {
    margin-bottom: 10px;
    text-align: left;
}
.simplecheckout-cart {
    margin: 0px !important;
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
}
/*.simplecheckout-cart-total {
    overflow: hidden;
    padding: 7px 3px;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    text-align: right;
}*/
.simplecheckout-cart-total input {
    margin-right: 0px !important;
    max-width: 100px;
}
.simplecheckout-cart-total span.inputs {
    margin-right: 3px;
}
.simplecheckout-cart-total span.inputs input[type=text]{
    margin: 0px;
}
.simplecheckout-cart-total-value {
    display: inline-block;
    margin: 0px 3px;
    min-width: 60px;
}
.simplecheckout-cart-total-remove {
    display: inline-block;
    width: 18px;
    text-align: center;
}
.simplecheckout-cart-total-remove img {
    vertical-align: -2px;
    cursor: pointer;
}
.simplecheckout-cart col.image {
    width: 5%;
}
.simplecheckout-cart col.name {

}
.simplecheckout-cart col.model {
    width: 10%;
}
.simplecheckout-cart col.quantity {

}
.simplecheckout-cart col.price {
    width: 10%;
}
.simplecheckout-cart col.total {
    width: 10%;
}
.simplecheckout-cart col.remove {
    width: 7px;
}
.simplecheckout-cart th {
    padding: 8px;
    border-bottom: 1px solid #DDDDDD;
    background-color: #F8F8F8;
    color: #4D4D4D;
    //font-weight: bold;
    font-weight: 700;
    //font-family: $fontBold;
}
.simplecheckout-cart th.image {
    width: 1px;
    text-align: center;
}
.simplecheckout-cart th.name {
    text-align: center;
}
.simplecheckout-cart th.model {
    text-align: center;
}
.simplecheckout-cart th.quantity {
    text-align: center;
}
.simplecheckout-cart th.price {
    text-align: right;
}
.simplecheckout-cart th.total {
    text-align: right;
}
.simplecheckout-cart th.remove {
    width: 7px;
    text-align: center;
}
.simplecheckout-cart td {
    padding: 7px;
    border-bottom: 1px solid #DDDDDD;
    vertical-align: top;
}
.simplecheckout-cart td.image {
    text-align: center;
}
.simplecheckout-cart td.name {
    text-align: center;
}
.simplecheckout-cart td.name div.image {
    display: none;
}
.simplecheckout-cart td.model {
    text-align: center;
}
.simplecheckout-cart td.quantity {
    min-width:70px;
    vertical-align:top;
    text-align: center;
}
.simplecheckout-cart td.quantity input[type=text] {
    margin:0;
    padding:0;
    width:20px;
    vertical-align:top;
    text-align:right;
}
.simplecheckout-cart td.quantity img {
    display:inline-block;
    border: 0px;
    cursor:pointer;
}
.simplecheckout-cart td.price {
    text-align: right;
}
.simplecheckout-cart td.total {
    text-align: right;
}
.simplecheckout-cart td.remove {
    padding-right: 3px;
    padding-left: 3px;
    width: 7px;
    vertical-align: top;
    text-align: center;
}
.simplecheckout-cart td.remove img {
    max-width: 30px !important;
}
.simplecheckout-cart td.product-warning {
    color: #FF0000;
    //font-weight: bold;
    //font-family: $fontBold;
    font-weight: 700;
}
.simplecheckout-cart td.right {
    vertical-align: middle;
    text-align: right;
}
@media only screen and (max-width:640px) {
    .simplecheckout-cart col.image,.simplecheckout-cart th.image,.simplecheckout-cart td.image {
        display: none;
    }
    .simplecheckout-cart col.model,.simplecheckout-cart th.model,.simplecheckout-cart td.model {
        display: none;
    }
    .simplecheckout-cart col.price,.simplecheckout-cart th.price,.simplecheckout-cart td.price {
        display: none;
    }
    .simplecheckout-cart td.name div.image {
        display: block;
    }
    .simplecheckout-cart td.name div.options {
        display: none;
    }
}
@media only screen and (max-width:1000px) {
    .simplecheckout-cart col.model,.simplecheckout-cart th.model,.simplecheckout-cart td.model {
        display: none;
    }
}
.simplecheckout-three-column .simplecheckout-cart col.image,
.simplecheckout-three-column .simplecheckout-cart th.image,
.simplecheckout-three-column .simplecheckout-cart td.image,
.simplecheckout-three-column .simplecheckout-cart col.price,
.simplecheckout-three-column .simplecheckout-cart th.price,
.simplecheckout-three-column .simplecheckout-cart td.price,
.simplecheckout-three-column .simplecheckout-cart col.model,
.simplecheckout-three-column .simplecheckout-cart th.model,
.simplecheckout-three-column .simplecheckout-cart td.model {
    display: none;
}
.simplecheckout-three-column .simplecheckout-cart td.name .image {
    display: block;
}
.simplecheckout-scroll{
    overflow-y:scroll;
    max-height:300px;
}
.simplecheckout-error-text {
    display: inline-block;
    color: #FF0000;
    overflow: hidden;
    padding-top: 3px;
}
.simplecheckout-warning-text {
    margin: 5px 0;
    //font-weight: bold;
    //font-family: $fontBold;
    font-weight: 700;
}
.simplecheckout-warning-block {
    clear: both;
    margin-bottom: 15px;
    padding: 10px;/*10px 10px 10px 33px*/
    border: 1px solid #F8ACAC;
    border-radius: 3px;/*5px 5px 5px 5px*/
    background: #FFD1D1;/*url("../image/warning.png") no-repeat scroll 10px center #FFD1D1*/
    color: #555555;
    text-align: left;
}
.simplecheckout-warning-block:first-child{
    margin-top: 15px;
}
.simpleregister-warning-block {
    margin-bottom: 15px;
    padding: 10px 10px 10px 33px;
    border: 1px solid #F8ACAC;
    border-radius: 5px 5px 5px 5px;
    //background: url(../image/warning.png) no-repeat scroll 10px center #FFD1D1;
    color: #555555;
    text-align: left;
}
.simplecheckout-proceed-payment {
    display: none;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    //font-weight: bold;
    //font-family: $fontBold;
    font-weight: 700;
}
#simple_payment_address_block {
    margin-top: 15px;
}
.simplecheckout-customer-same-address {
    text-align: left;
}
#simplecheckout_login {
    margin: auto;
    padding:30px;
    text-align: center;
}
.simplecheckout-customer-same-address {
    margin: 15px 0px;
}
.simplecheckout-company {
    margin: 15px 0px 0px 0px;
}
#simplecheckout_payment_form {
    padding: 5px;
}
#simplecheckout_payment_form .checkout-content {
    display: block;
}
#simplecheckout_payment_form div.buttons {
    display: none;
}
.simplecheckout-red-border {

}
#simple_login_layer {
    position:absolute;
    top:0;
    left:0;
    z-index:99990;
    display:none;
    width:100%;
    background-color:#000000;
}
#simple_login {
    position:fixed;
    z-index:99991;
    display: none;
    overflow:hidden;
    padding:5px;
    width:300px;
    height:250px;
    border:1px solid #FFD9FF;
    border-radius:7px;
    background:none repeat scroll 0 0 #FFFFFF;
}
#simple_login_header {
    padding: 0px 0px;
    text-align: right;
}
#simple_login_header img {
    margin: 0 0 0 auto;
}
.you-will-be-registered {
    //font-weight: bold;
    //font-family: $fontBold;
    font-weight: 700;
}
.simplecheckout-customer-right label input {
    margin-right:3px;
}
.simplecheckout label, .simpleregister label {
    display: inline-block !important;
    margin: 0;
}
#simple_summary {
    display: none;
}
.simple-step {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    background-color: #D9D9D9;
    color: #000000;
    font-size: 13px;
}
.simple-step-current {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    background-color: #38B0E3;
    color: #FFFFFF;
    font-size: 13px;
}
.simple-step-delimiter {
    display: inline-block;
    vertical-align: middle;
    line-height: 13px;
}
.simple-step-completed {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    background-color: #38B0E3;
    color: #FFFFFF;
    font-size: 13px;
}
.simple-step-completed a {
    color: #FFFFFF !important;
    text-decoration: none !important;
    font-size: 13px !important;
}
.simplecheckout-summary-info {
    margin-top: 15px !important;
}
.simplecheckout-summary-info th.name {
    text-align: left;
}
.simplecheckout-summary-info td {
    text-align: left;
}
