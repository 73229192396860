//////////////////////////////////////////////////
//                  Home Widgets
//////////////////////////////////////////////////

.home-row {
    background-color: #f7f7f7;
    position: relative;
    padding-bottom: 15px;

    .container {
        padding: 0;
    }

    @media screen and (max-width: 1023px) {
        padding-bottom: 0;
    }
}

@media screen and (min-width: 1024px) {

    .home-column {

        .widget-home {
            opacity: 0;
            transform: translateY(150px);
            transition: transform $time ease-in-out, opacity $time ease-in-out;
        }

        &:nth-of-type(1) {
            .widget-home {
                &:nth-of-type(1) {
                    transition-delay: .15s;
                }
                &:nth-of-type(2) {
                    transition-delay: .45s;
                }
            }
        }

        &:nth-of-type(2) {
            .widget-home {
                &:nth-of-type(1) {
                    transition-delay: .25s;
                }
                &:nth-of-type(2) {
                    transition-delay: .55s;
                }
            }
        }

        &:nth-of-type(3) {
            .widget-home {
                &:nth-of-type(1) {
                    transition-delay: .35s;
                }
                &:nth-of-type(2) {
                    transition-delay: .45s;
                }
            }
        }

    }

    .load-complete {
         .home-column {
             .widget-home {
                 opacity: 1;
                 transform: translateY(0);
             }
         }
    }

}

.home-column {
    width: calc((100% - 30px) / 3);
    margin-left: 15px;
    float: left;
    margin-top: 15px;
    @extend %clear;

    &:first-child {
        margin-left: 0;
    }

    &.is-right {
        float: right;
    }

    .p-slide-cover {
        //font-family: $font-title;
        color: #fff;
    }

    @media screen and (max-width: 1023px) {
        float: none;
        margin-top: 20px;
        margin-left: 0;
        width: 100%;
        clear: both;

        &.is-right {
            float: none;
        }
    }

    @media screen and (max-width: 375px) {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;

        &:first-child {
            margin-left: -15px;
        }
    }
}

.widget-home {
    //margin-bottom: 20px;
    text-align: center;
    box-shadow: 0 1px 0 rgba(#000,.1);

    &-header {
        //position: relative;
        //height: 0;
        text-align: center;

        a {
            display: inline-block;
            position: relative;
            z-index: 1;
            width: initial;
            //left: 50%;
            //transform: translateX(-50%);
            font-size: 24px;
            font-weight: 300;
            padding: 40px 0 18px;
            color: $color-black;
            transition: color $time ease-in-out;

            &:before {
                @extend %before;
                bottom: 10px;
                height: 1px;
                left: 0;
                width: 100%;
                transform: scale(.7);
                background-color: currentColor;
                opacity: 0;
                transition: opacity $time ease-in-out, transform $time ease-in-out;
            }

            @media screen {
                &:hover {
                    color: $color-blue;

                     &:before {
                         opacity: 1;
                         transform: scale(1);
                     }
                }
            }
        }
    }

    & + .widget-slider {
        margin-top: 200px;

        @media screen and (max-width: 1023px) {
            margin-top: 0;
        }
    }

    .wh-head {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;

        &-sub {
            font-size: 13px;
        }
    }

    .wh-foot {
        padding-bottom: 20px;

        a {
            font-weight: 600;
            display: inline-block;
            vertical-align: top;
            position: relative;
            color: $color-black;

            &:before,
            &:after {
                @extend %before;
                height: 1px;
                width: 50%;
                background-color: currentColor;
                bottom: 0;
                transition: transform $time ease-in-out;
            }

            &:after {
                right: 0;
            }

            &:before {
                left: 0;
            }

            @media screen {
                transition: color $time ease-in-out;

                &:hover {
                    color: $color-blue;

                    &:before {
                        transform: rotate(10deg) translateY(10px) translateX(2px);
                    }
                    &:after {
                        transform: rotate(-10deg) translateY(10px) translateX(-2px);
                    }
                }
            }
        }
    }

    &.widget-sale {
        background-color: #ec2647;
        color: #fff;
        padding: 40px 0 10px;

        @media screen and (min-width: 1024px) {
            margin-top: 200px;
        }

        a {
            color: #fff;
        }

        .ws-count {
            //font-family: $font-title;
            font-size: 120px;
            font-weight: 700;
            white-space: nowrap;
            line-height: 1;
            padding: 80px 0 166px 0;

            span {
                display: block;
                font-weight: 400;
                font-size: 20px;
            }
        }

        .wh-foot {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;

            a {
                @media screen {
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

    &.widget-brands-list {
        background-color: #fff;
        //margin-top: -17px;
        padding: 40px 0 10px;
        //margin-bottom: 20px;

        .wh-head {
            text-align: center;

            a {
                display: inline-block;
                position: relative;
                width: initial;
                font-size: 24px;
                font-weight: 300;
                color: $color-black;
                transition: color $time ease-in-out;

                &:before {
                    @extend %before;
                    bottom: -5px;
                    height: 1px;
                    left: 0;
                    width: 100%;
                    transform: scale(.7);
                    background-color: currentColor;
                    opacity: 0;
                    transition: opacity $time ease-in-out, transform $time ease-in-out;
                }

                @media screen {
                    &:hover {
                        color: $color-blue;

                        &:before {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        ul {
            column-count: 2;
            column-gap: 30px;
            margin: 25px 0 20px;
            position: relative;

            &:before {
                @extend %before;
                top: 0;
                bottom: 0;
                width: 1px;
                left: 50%;
                background-color: #efeded;
            }

            li {
                a {
                    display: block;
                    @extend %toh;
                    color: $color-grey;
                    font-size: 15px;
                    line-height: 30px;

                    @media screen {
                        transition: color $time;

                        &:hover {
                            color: $color-blue;
                        }
                    }

                    @media screen and (max-width: 1100px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: 1023px) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: 375px) {
                        font-size: 14px;
                    }
                }
            }
        }

        @media screen and (max-width: 1023px) {
            margin-top: 0;
            margin-bottom: 15px;
        }
    }

    &.widget-weather {
        background-color: #fff;
    }

    &.widget-slider {
        //padding-top: 80px;
        background-color: #fff;

        .slick-arrow {
            position: absolute;
            bottom: 45px;
            background-color: transparent;
            z-index: 5;

            &.slick-next {
                right: 10px;
            }

            &.slick-prev {
                left: 10px;
            }
        }

        .slick-slide img {
            max-height: 274px;
        }

        &.is-sale + .widget-slider {
            .p-slide-info {
                padding-top: 20px;
                padding-bottom: 10px;
            }
            .p-slide-brand {
                display: none;
            }
        }

        @media screen and (max-width: 1023px) {
            width: calc(50% - 10px);
            float: left;

            & + .widget-slider {
                margin-left: 20px;
            }
        }

        @media screen and (max-width: 800px) {
            float: none;
            width: 100%;
            margin-left: 0;

            & + .widget-slider {
                margin-left: 0;
            }
        }
    }

    &.widget-last-size {
        background-color: #fff;
    }

    //@media screen and (max-width: 1023px) {
    //    margin-bottom: 0;
    //
    //    //& + .widget-home {
    //    //    margin-top: 20px;
    //    //}
    //}

}

.product-slide {
    position: relative;

    .p-slide {

        &-for {
            font-size: 15px;
            color: $color-grey;
            opacity: 0;
            transition: transform $time ease-in-out .15s, opacity $time ease-in-out .15s;
        }

        &-brand {
            font-size: 13px;
            transform: translateX(50px);
            opacity: 0;
            transition: transform $time ease-in-out .25s, opacity $time ease-in-out .25s;

            a {
                color: $color-black;
            }
        }

        &-price {
            font-size: 13px;
            margin-bottom: 20px;
            opacity: 0;
            transition: transform $time ease-in-out .35s, opacity $time ease-in-out .35s;

            span + span {
                margin-left: 10px;
            }

            &-old {
                text-decoration: line-through;
                color: #8e8e8e;
                font-size: 11px;
            }

            &-new {
                font-weight: 600;
                color: $color-black;
            }

        }

        &-cover {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            max-width: 100%;
            max-height: 100%;
            font-size: 86px;
            z-index: -1;
            line-height: 1;
            white-space: nowrap;
            margin-top: -40px;

            &.is-num {
                font-size: 200px;
            }
        }

        &-thumb {
            padding: 15px 0;
        }
    }

}

.widget-slider {

    //&.is-next {
    //    .product-slide {
    //        .p-slide {
    //            &-price {
    //                transform: translateX(25px);
    //            }
    //            &-brand {
    //                transform: translateX(50px);
    //            }
    //            &-for {
    //                transform: translateX(75px);
    //                opacity: 1;
    //            }
    //        }
    //    }
    //}
    //
    //&.is-prev {
    //    .product-slide {
    //        .p-slide {
    //            &-price {
    //                transform: translateX(-25px);
    //            }
    //            &-brand {
    //                transform: translateX(-50px);
    //            }
    //            &-for {
    //                transform: translateX(-75px);
    //                opacity: 1;
    //            }
    //        }
    //    }
    //}

    .product-slide {

        .p-slide {
            &-price {
                transform: translateX(25px);
            }
            &-brand {
                transform: translateX(-25px);
            }
            &-for {
                transform: translateX(25px);
            }
        }

        //&.slick-current,
        //&.slick-active, {
        //
        //}

        &[aria-hidden="false"] {
            .p-slide {
                &-price,
                &-brand,
                &-for {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
    }
}

.product-wide {
    @extend %clear;

    .pw-thumb {
        float: right;
        margin-right: 15px;

        a {
            display: block;
            width: 200px;
            height: 200px;
            position: relative;

            &:before {
                @extend %before;
                top: 10px;
                bottom: 7px;
                left: -4px;
                right: -4px;
                border: 4px solid rgba(#fce9a9, .5);
                z-index: 1;
            }
        }
    }

    .pw-info {
        overflow: hidden;
        padding: 30px 0 0;
    }

    .pw-head {
        font-size: 15px;
        font-weight: 700;
        color: $color-black;
        margin-bottom: 5px;
    }

    .pw-time {
        color: $color-blue;
        font-size: 13px;
        margin-bottom: 15px;
    }

    .pw-brand {
        font-size: 15px;

        a {
            color: $color-black;

            @media screen {
                transition: color $time;

                &:hover {
                    color: $color-blue;
                }
            }
        }
    }

    .pw-option {
        font-size: 11px;
    }

    .pw-price {
        font-size: 13px;
        margin-bottom: 20px;

        span + span {
            margin-left: 10px;
        }

        &-old {
            text-decoration: line-through;
            color: #8e8e8e;
            font-size: 11px;
        }

        &-new {
            font-weight: 600;
            color: $color-black;
        }

    }

}