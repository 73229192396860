////////////////////////////////////////
//		Mixin
////////////////////////////////////////


@mixin row-grid($ml: 35px) {
	display: table;
	font-size: 0 !important;
	margin-left: (-1)*$ml;
	@if $ml == 0 {
		width: 100%;
	} @else {
		width: calc(100% + #{$ml});
	}
}


@mixin grid-item($gicol: '3', $giml: 35px, $gimb: 35px){
	display: inline-block;
	vertical-align: top;
	margin-left: $giml;
	margin-bottom: $gimb;

	@if $gicol == 0 {
		width: calc(100% - #{$giml});
	} @else {
		width: calc((100% / #{$gicol}) - #{$giml});
	}
    //
	//@if $ml == 0 {
	//	width: 100%;
	//} @else {
	//	width: calc(100% + #{$ml});
	//}

}

@mixin fade-up-hide(){
	transition: margin $time, opacity $time, visibility $time;
	margin-top: 20px;
	opacity: 0;
	visibility: hidden;
}

@mixin fade-up-show(){
	margin-top: 0;
	opacity: 1;
	visibility: visible;
}





