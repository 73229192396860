//////////////////////////////////////////////////
//                  NoUIslider
//////////////////////////////////////////////////

/*! noUiSlider - 7.0.10 - 2014-12-27 14:50:47 */

.noUi-target, .noUi-target * {
    -webkit-touch-callout: none;
    -ms-touch-action: none;
    box-sizing: border-box;
    user-select: none;
}

.noUi-target {
    position: relative;
    direction: ltr
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0
}

.noUi-handle {
    position: relative;
    z-index: 1
}

.noUi-stacking .noUi-handle {
    z-index: 10
}

.noUi-state-tap .noUi-origin {
    transition: left .3s, top .3s
}

.noUi-state-drag * {
    cursor: inherit !important
}

.noUi-base {
    transform: translate3d(0, 0, 0)
}

.noUi-horizontal {
    height: 18px
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    left: -17px;
    top: -6px
}

.noUi-vertical {
    width: 18px
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px
}

.noUi-background {
    background: #FAFAFA;
    box-shadow: inset 0 1px 1px #f0f0f0
}

.noUi-connect {
    background: #3FB8AF;
    box-shadow: inset 0 0 3px rgba(51, 51, 51, .45);
    transition: background 450ms
}


.noUi-origin {
    border-radius: 2px
}

.noUi-target {
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}

.noUi-target.noUi-connect {
    box-shadow: inset 0 0 3px rgba(51, 51, 51, .45), 0 3px 6px -5px #BBB
}

.noUi-dragable {
    cursor: w-resize
}

.noUi-vertical .noUi-dragable {
    cursor: n-resize
}

.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}

.noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}

.noUi-handle:after, .noUi-handle:before {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 14px;
    top: 6px
}

.noUi-handle:after {
    left: 17px
}

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px
}

.noUi-vertical .noUi-handle:after {
    top: 17px
}

[disabled] .noUi-connect, [disabled].noUi-connect {
    background: #B8B8B8
}

[disabled] .noUi-handle {
    cursor: not-allowed
}