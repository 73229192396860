//////////////////////////////////////////////////
//                  Header
//////////////////////////////////////////////////

.sf-header {
    padding: 0;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 102;
    //transition: top $time ease-out, left $time;
    transform-origin: top center;
    transition: transform $time ease-out;
    background-color: #fff;
    box-shadow: 0 0 0 1px #e0e0e0;

    .sf-pre-header {
        &.no-back-phone {
            .sf-call-me {
                display: none;
            }
        }
    }

    &.is-fixed {

        @media screen and (min-width: 1025px) {
            top: -45px;
            transform: rotateX(90deg);

            &.is-fixed-view {
                position: fixed;

                &.is-show {
                    transform: rotateX(0deg);
                }
            }
        }
    }

    button {
        border: 0;
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    & + section {
        padding-top: 109px;
        position: relative;
    }

    &.sf-header-cart {
        top: -45px;
        height: 109px;
        background-color: #fff;
        border-bottom: 1px solid #eee;
        position: absolute !important;

        .sf-logo {
            width: 120px;
            overflow: hidden;
            margin: 14px 0 14px 20px;

            a {
                display: block;
            }

            img {
                display: block;
                height: 40px;
            }
        }

        h1 {
            position: absolute;
            top: 45px;
            line-height: 65px;
            margin: 0;
            padding: 0 120px 0 0;
            left: 120px;
            font-weight: 300;
            text-transform: none;
            right: 401px;
            text-align: center;
        }
    }

    .sf-wish-list {
        display: inline-block;
        vertical-align: top;
        position: absolute;
        top: 0;
        margin-top: 0;
        margin-right: 10px;
        padding: 0;
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        font-size: 13px;
        right: 100%;
        color: $color-grey;
        z-index: 5;

        i {
            font-size: 16px;
            margin-right: 6px;
            position: relative;
            top: 2px;
        }

        span {
            //position: absolute;
            position: relative;
            left: 0;
            font-size: 11px;
            top: 7px;
            background-color: $color-grey;
            color: #fff;
            min-width: 14px;
            height: 14px;
            line-height: 14px;
            text-align: center;
            padding: 0;
            border-radius: 50%;
            display: inline-block;
            vertical-align: top;
            margin-left: -15px;
        }

        b {
            font-weight: 400;
            display: none;
        }

        @media screen {
            transition: color $time;

            &:hover {
                color: $color-black;

                span {
                    color: #fff;
                    background-color: $color-blue;
                }
            }
        }

        @media screen and (max-width: 1024px) {
            display: none;
        }

    }

    @media screen and (max-width: 1024px) {
        height: 51px;
        background-color: #fff;
        border-bottom: 1px solid #eee;

        & + section {
            padding-top: 51px;
        }

        .sf-pre-header {
            position: absolute;
            top: -100000px;
            z-index: 100001;

            .sf-pre-phone {
                display: none;
                position: fixed;
                background-color: rgba(#000, .5);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 111000;

                .sf-phone-popup {
                    display: block;
                    position: absolute;
                    width: 300px;
                    padding: 60px 15px;
                    top: 35%;
                    margin-left: -150px;
                    background-color: #fff;
                    left: 50%;
                    line-height: 30px;
                    z-index: 2;
                    text-align: left;
                    border: 1px solid #000;

                    a {
                        display: inline-block;
                        text-align: center;
                        color: $color-blue;
                        margin: 0 5px;
                        font-size: 13px;
                        line-height: 18px;
                        border-bottom: 1px dashed rgba($color-blue, .3);

                        &.sf-call-me {
                            top: auto;
                            bottom: 10px;
                            left: 13px;
                            right: auto;
                            border: 0;
                            height: 30px;
                            line-height: 30px;
                            background-color: $color-blue;
                            border-radius: 4px;
                            color: #fff;
                            padding: 0 15px;

                            i {
                                display: none;
                            }
                        }
                    }

                    i {
                        display: block;
                        position: absolute;
                        height: 30px;
                        width: 30px;
                        margin: 0;
                        top: 5px;
                        right: 5px;
                        cursor: pointer;
                        z-index: 5;
                        border: 1px solid rgba($red, 0);
                        background: rgba(#000, 0);
                        text-indent: -200px;
                        overflow: hidden;

                        &:before,
                        &:after {
                            display: block;
                        }
                    }
                }

                .sf-pre-phone-bg {
                    display: block;
                    cursor: pointer;
                }

                span {
                    display: none;
                }

                ins {
                    display: block;
                    text-decoration: none;
                    text-align: left;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: $text;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    padding: 15px 20px;
                    line-height: 1;
                    z-index: 3;
                    background-color: #f7f7f7;
                }

                &.no-back-phone {
                    .sf-phone-popup {
                        padding: 60px 15px 20px;
                        top: 40%;
                    }
                }
            }
        }

        &.sf-header-cart {
            top: 0;
            height: 51px;

            .sf-logo {
                width: 88px;
                overflow: hidden;
                margin: 10px 0 10px 20px;

                img {
                    height: 30px;
                    width: initial;
                }
            }

            h1 {
                top: 0;
                line-height: 50px;
                right: 0;
                padding-right: 205px;
            }
        }

        &.is-search-open {
            z-index: 10000;

            @media screen and (max-width: 1024px) {
                & + .sitepage {
                    #content {
                        padding-top: 101px !important;
                    }
                }
            }

        }
    }

    @media screen and (max-width: 900px) {
        &.sf-header-cart {
            h1 {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: 599px) {
        &.sf-header-cart {
            h1 {
                padding-right: 0;
            }
        }
    }

}

.sf-container {
    position: relative;
    background-color: #fff;

    .sf-logo {
        display: inline-block;
        vertical-align: top;
        width: 85px;
        height: 30px;
        margin: 19px 0;
        position: absolute;
        top: 0;
        left: 20px;
        z-index: 20;

        a {
            display: inline-block;
            vertical-align: top;
        }

    }

    @media screen and (max-width: 1024px) {

        .sf-logo {
            left: 50px;
            top: 10px;
            margin: 0;

            img {
                height: 30px;
                width: initial;
            }

        }
    }

}

.back-to-store {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    right: 15px;
    color: $red;
    border-bottom: 1px dashed rgba($red, .3);

    @media screen and (min-width: 1025px) {
        margin-top: 10px;
        transition: color $time, border-color $time;

        &:hover {
            color: $text;
            border-bottom-color: rgba($text, .3);
        }
    }
}

//  Black panel
//_______________________________

.sf-pre-header {
    //background-color: $dark;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    //color: #fff;
    color: $grey;
    text-align: center;
    position: relative;
    z-index: 7;

    .container {
        //width: calc(100% - 20px);
        //margin: 0 0 0 20px;
        position: relative;
    }

    .sf-pre-nav {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        a {
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
            font-size: 13px;
            //font-weight: 600;
            //font-family: $fontSbold;
            //padding: 6px 10px 5px;
            padding: 5px 10px;
            //color: #fff;
            color: #8b8c8d;

            &:first-child {
                margin-left: -10px;
            }

            &.is-active {
                color: $red;
            }

            @media screen {
                transition: color $time;

                &:hover {
                    color: $dark;
                    //color: $red;
                }
            }

        }

        @media screen and (min-width: 1025px) and (max-width: 1150px) {
            a {
                margin-left: 0;

                &:first-child {
                    margin-left: -10px;
                }
            }
        }

        @media screen and (min-width: 1025px) {
            z-index: 8;
        }

    }

    .sf-button-nav {
        display: none;
    }

    .sf-pre-phone {
        display: inline-block;
        width: 100%;
        position: relative;
        z-index: 5;
        font-size: 13px;
        line-height: 40px;
        padding: 0;

        a {
            color: $dark;
            text-decoration: none;

            &.sf-call-me {
                display: inline-block;
                position: absolute;
                top: 0;
                height: 40px;
                line-height: 40px;
                white-space: nowrap;
                right: 180px;
                font-size: 13px;
                color: $grey;

                b {
                    font-weight: 400;
                }

                i {
                    font-size: 16px;
                    margin-right: 3px;
                    position: relative;
                    top: 2px;
                }

            }
        }

        .sf-ua-flag {
            display: inline-block;
            vertical-align: middle;
            height: 10px;
            width: 15px;
            margin-right: 5px;
            position: relative;
            top: -1px;

            &:before,
            &:after {
                @extend %before;
                left: 0;
                right: 0;
                height: 5px;
            }

            &:before {
                top: 0;
                background-color: #3c75c2;
            }

            &:after {
                bottom: 0;
                background-color: #f8dc18;
            }
        }

        i:not(.sf-icon-phone) {
            margin: 0 10px;
            position: relative;

            &:before,
            &:after {
                @extend %before;
                display: none;
                width: 15px;
                height: 1px;
                top: 50%;
                left: 50%;
                margin-left: -7px;
                background-color: $text;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        ins {
            display: none;
        }

        @media screen {
            .sf-call-me {
                transition: color $time;

                &:hover {
                    color: $dark;
                }
            }
        }

    }

    .sf-phone-popup {
        //display: inline;
        display: inline-block;
        vertical-align: top;
    }

    .sf-button-dashed {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 11pt;
        height: 16px;
        color: #fff;
        line-height: 1;
        border-bottom: 1px dashed rgba(#fff, .7);
    }

    .dropdown {
        display: inline-block;
        vertical-align: top;
        padding-right: 6px;
    }

    .sf-pre-phone-bg {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    & + .container {
        margin: 0;
        padding: 0;
        //width: 100%;

        @media screen and (min-width: 1441px) {
            margin: 0 auto;
            width: 1330px;
            padding: 0 15px;
        }
    }

}

// end Black panel
//_______________________________

//  User zone block
//_______________________________

.user-zone {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;

    a {
        color: $grey;
        font-size: 13px;
        padding: 6px 10px 5px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -12px;

        &.popup-link {
            padding: 0 15px 0 10px;
            margin: 0;
            line-height: 38px;

            i:not(.sf-icon-login) {
                display: none;
            }
        }

        &.dropdown-toggle {
            padding: 0 20px 0 10px;
            line-height: 38px;
            margin: 0;
            position: relative;

            &:before {
                @extend %before;
                top: 18px;
                right: 8px;
                width: 6px;
                height: 6px;
                border: 1px solid $grey;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg);
            }

            svg {
                width: 18px;
                height: 18px;
            }
        }

        @media screen {
            transition: color $time;

            &.dropdown-toggle {
                &:before {
                    transition: border-color $time;
                }

                &:hover {
                    &:before {
                        border-color: $dark;
                    }
                }
            }

            &:hover {
                color: $dark;
                //svg {
                //    //fill: $red;
                //    fill: $dark;
                //}
            }

            svg {
                transition: fill $time;
            }
        }

        @media screen and (max-width: 1024px){
            &.popup-link {
                padding-left: 0;
                width: 100%;

                i {
                    display: initial !important;
                    font-style: normal;
                }

                &:hover {
                    color: #fff !important;
                }
            }
        }
    }

    .dropdown {
        &.open {
            .dropdown-toggle {
                color: $dark;

                &:before {
                    border-color: $dark;
                }

                //@media screen and (max-width: 1024px) {
                //    color: $red;
                //
                //    &:before {
                //        border-color: $red;
                //    }
                //
                //}
            }
        }
    }

    .sf-button-gift {
        //background-color: $red;
        width: 45px;
        height: 38px;

        svg {
            fill: #fff;
            width: 25px;
            height: 25px;
        }
    }

    .dropdown-menu {
        right: 0;
        left: auto;
        border-radius: 0;
        margin: 0;
        padding: 0;
        background-color: #fff;
        border: 0;

        a {
            color: $grey;
            padding: 6px 20px;
            margin: 0;

            @media screen {
                transition: color $time, background-color $time;

                &:hover {
                    color: $dark;
                    background-color: #fff;
                }
            }
        }

        .logOut {
            border-top: 1px solid #eee;
        }

    }

}

.sf-dropdown {
    display: inline-block;
    position: relative;
    //height: 45px;
    height: 40px;

    .sf-dropdown-toggle {
        position: relative;
        height: 40px;
        margin: 0;
        background-color: #fff;
        //background-color: $dark;
        padding: 0 20px 0 8px;
        color: $grey;

        &:before {
            @extend %before;
            top: 16px;
            right: 8px;
            width: 6px;
            height: 6px;
            border: 1px solid $grey;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg);
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $grey;
            vertical-align: text-bottom;
        }

        @media screen and (min-width: 1025px) {
            transition: color $time;

            &:before {
                transition: border-color $time;
            }

            &:hover {
                color: $dark;

                &:before {
                    border-color: $dark;
                }
            }
        }

    }

    .sf-dropdown-menu {
        position: absolute;
        top: 40px;
        min-width: 170px;
        right: 0;
        left: auto;
        margin: 0;
        padding: 0;
        background-color: #fff;
        border: 0;
        text-align: left;
        display: none;
        box-shadow: 0 2px 2px rgba(#000,.3);

        li {
            display: block;

            a {
                color: $grey;
                padding: 6px 20px;
                margin: 0;
                line-height: 1.4;
                display: block;

                @media screen and (min-width: 1025px) {
                    transition: color $time;

                    &:hover {
                        color: $dark;
                    }
                }
            }

            &.logOut {
                border-top: 1px solid #eee;
            }
        }
    }

    &.is-open {
        .sf-dropdown-toggle {
            color: $dark;

            &:before {
                border-color: $dark;
            }

        }
        .sf-dropdown-menu {
            display: block;
        }
    }

}

//  End user zone block
//_______________________________

html {

    &.is-nav-open {

        .sf-nav {
            transform: translate3d(0, 0, 0);
        }

        .sf-nav-close {
            position: fixed;
        }

    }
}