//////////////////////////////////////////////////
//                  Vars
//////////////////////////////////////////////////


$text: #231f20;
$dark: #1f2124;
$red: #f15732;
$turq: #198e9f;
$grey: #8c8b8d;
$green: #0dae51;
$blue: #337ab7;
$time: .3s;
$color-blue: #0b7ecb;
$color-red: #f15732;
$color-black: #1f2124;
$color-text: #231f20;
$color-grey: #8c8b8d;

//$font: 'SF';
//$fontSbold: 'SF Semibold';
//$fontBold: 'SF Bold';

$fontName: 'Some Sans';
$fontIcon: 'iconfont';
$font: 'open_sans_regular';
$fontSbold: 'open_sans_semibold';
$fontBold: 'open_sans_bold';

//JandaElegantHandwriting.woff

//@font-face{font-family:'JandaEl';font-weight:normal;
//    font-style: normal;src:url(../../font/JandaElegantHandwriting.woff) format("woff")
//};
//
//$font-title: 'JandaEl';