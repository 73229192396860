//////////////////////////////////////////////////
//                  Theme Block
//////////////////////////////////////////////////

.is-sf-hidden {
    position: absolute;
    top: -100000px;
    left: -10000px;
    visibility: hidden;
}

.slider5Out {
    position: relative;

    &:after,
    &:before {
        @extend %before;
        top: 0;
        left: 0;
        background-color: #eee;
        bottom: 0;
        width: 1px;
        z-index: 10;
    }
    &:after {
        left: auto;
        right: 0;
    }

    .iosslider {
        //padding-top: 4px;
        min-height: 410px;
        padding: 1px 0;
        background-color: #eee;
    }

    .product5 {
        float: left;
        width: calc(20% - 1px);
        margin: 0 1px 0 0;
        height: auto;
        -webkit-backface-visibility: visible !important;
        overflow: visible !important;
        z-index: 5;

        &:before {
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            background-color: #fff;
            border: 0 !important;
        }
    }

    @media screen and (max-width: 1300px) {
        .product5 {
            width: calc(25% - 1px);
        }
    }

    @media screen and (max-width: 1200px) {
        margin: 0 -15px;

        &:after,
        &:before {
            display: none;
        }

        .slideCounter {
            right: 15px;
        }
    }

    @media screen and (max-width: 950px) {
        .product5 {
            width: calc((100% / 3) - 1px);
        }
    }

    @media screen and (max-width: 700px) {
        //.iosslider {
        //    min-height: 339px;
        //}
        .product5 {
            width: calc(50% - 1px);
            //height: 338px;
        }
    }

    @media screen and (max-width: 425px) {

        .iosslider {
            height: 229px;
            min-height: 230px;
        }

        .product5-thumb {
            display: block;
            text-align: center;
            line-height: 150px;
            height: 150px;

            img {
                max-height: 150px;
                width: initial;
				height: initial;
                height: auto;
                max-width: 100%;
            }
        }

        .product5 {
            height: 228px;
        }

        .slideCounter {
            top: -35px !important;
        }
    }

}

//  Head Search
//_______________________________
.search-form {
    position: absolute;
    top: 20px;
    height: 30px;
    left: 130px;
    background-color: #fff;
    width: 460px;
    z-index: 20;
    @extend %clear;

    .sf-form-close {
        font-size: 13px;
        color: $dark;
        position: absolute;
        top: 0;
        height: 30px;
        right: 110px;
        z-index: 3;
        display: none;

        &.is-show {
            display: block;
        }
    }

    .sf-form-field {
        width: 360px;
        float: left;
        margin: 0;

        input {
            height: 30px;
            border: 2px solid #f7f7f7;
            border-right: 0;
            font-size: 13px;
            background-color: #f7f7f7;
            padding: 0 10px;
            width: 100%;
            box-sizing: border-box;
            vertical-align: top;
            border-radius: 4px 0 0 4px;

            @media screen {
                transition: border-color $time, background-color $time;
            }

            &:focus {
                background-color: #f7f7f7;
            }
        }
    }

    .send-query {
        float: left;
        width: 100px;
        text-align: center;
        height: 30px;
        border: 2px solid $color-blue;
        background-color: $color-blue;
        color: #fff;
        font-size: 13px;
        border-radius: 0 4px 4px 0;

        i {
            display: none;
        }

        @media screen {
            //transition: color $time, border-color $time, background-color $time;
            transition: box-shadow $time;

            &:hover {
                //background-color: $color-blue;
                //color: #fff;
                //border-color: $color-blue;
                box-shadow: 0 0 5px rgba(#000,.5);
            }
        }
    }

    .sf-show-search {
        position: absolute;
        top: 50%;
        right: 0;
        width: 44px;
        height: 44px;
        margin-top: -22px;
        font-size: 18px;
        padding: 0;

        @media screen and (min-width: 600px) {
            display: none;
        }
    }

    .sf-search-close {
        display: none;
    }

    @media screen and (max-width: 1195px) {
        width: 360px;

        .sf-form-field {
            width: 260px;
        }
    }

    @media screen and (max-width: 1024px) {
        top: 10px;
        left: 160px;
        width: auto;
        right: 60px;

        .sf-form-field {
            width: calc(100% - 110px);
        }

        .sf-form-close {
            right: 120px;
        }

        .sf-show-search {
            i {
                font-size: 16px;
                position: relative;
                top: 1px;
            }
        }
    }

    @media screen and (max-width: 599px) {
        width: 45px;
        left: initial;
        top: 10px;

        .sf-form-field,
        .send-query {
            opacity: 0;
            visibility: hidden;
        }

        &.is-show {
            top: 50px;
            left: 0;
            right: 0;
            margin: 0;
            padding: 10px 50px 10px 10px;
            width: auto;
            min-height: 50px;

            .sf-show-search {
                display: none;
            }

            .searchPopup {
                top: 48px;
            }

            .sf-form-field,
            .send-query {
                opacity: 1;
                visibility: visible;
            }

            .sf-form-field {
                width: calc(100% - 100px);
            }

            .sf-form-close {
                top: 10px;
                opacity: 0;
                visibility: hidden;
            }

            .sf-search-close {
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                width: 50px;
                height: 50px;
                border: 0;
                font-size: 24px;
                color: $color-black;
                padding: 0;

                i {
                    line-height: 50px;
                }
            }
        }
    }
}

.is-top-filter {
    display: none;
}

.product-category {

    @media screen and (max-width: 1024px) {
         .search-form {
             right: 90px;
         }
    }

    .is-top-filter {
        position: absolute;
        top: 0;
        right: 60px;
        display: initial;
        font-size: 16px;
        height: 50px;
        width: 30px;

        i {
            position: relative;
            top: 2px;
        }

        &.is-fxd {
            color: $color-blue;
        }

        //.sf-icon-conf {
        //
        //}
        //
        //.sf-icon-close {
        //    opacity: 0;
        //    visibility: hidden;
        //    position: absolute;
        //    top: 0;
        //    left: 0;
        //}

        @media screen and (min-width: 1025px) {
            display: none;
        }
    }
}

//  Head Basket
//_______________________________

.sf-shop-cart {
    position: absolute;
    bottom: 0;
    right: 5px;
    top: 0;
    //width: 170px;
    width: 70px;
    height: 69px;
    line-height: 69px;
    z-index: 2;

    //border-left: 2px solid #eee;

    .sf-show-cart {
        position: relative;
        cursor: pointer;
        user-select: none;

        &:before {
            @extend %before;
            top: 50%;
            margin-top: -11px;
            left: 0;
            width: 1px;
            height: 20px;
            z-index: 2;
            background-color: rgba(#000, .2);
        }

        @media screen and (min-width: 1025px) {
            &.is-empty {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background-color: transparent;
                }
            }
        }

        @media screen and (max-width: 1024px) {
            &:before {
                display: none;
            }
        }
        &.click + .basketPopup {
            display: block;
        }

        @media screen {
            .sf-button-pay {
                transition: color $time;
            }

            &:hover {
                .sf-button-pay {
                    color: $color-blue;

                    &.is-empty {
                        color: $color-grey;
                    }

                }
            }
        }
    }

    .ssc-title {
        //display: none;
        display: block;
        height: 69px;
        width: 100%;
        position: relative;
        z-index: 2;

        b {
            display: none;
        }

        .sf-button-dashed {
            //font-size: 10px;
            //color: $dark;
            //height: 20px;
            //border-bottom: 1px dashed rgba($dark, .8);
            //display: inline;
            display: none;
        }

    }

    .sf-button-pay {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        width: 60px;
        height: 68px;
        line-height: 50px;
        text-align: center;
        white-space: nowrap;
        color: $color-red;
        font-size: 20px;
        display: flex;

        i {
            //vertical-align: middle;
            //position: relative;
            //top: 8px;
            margin: auto;
        }

        span {
            //display: inline-block;
            //width: 12px;
            //height: 20px;
            //line-height: 20px;
            font-weight: 600;
            //color: $color-red;
            font-size: 13px;
            //position: relative;
            //top: 7px;
            margin: auto auto auto -5px;
        }

        &.is-empty {
            color: $color-grey;
        }

    }

    @media screen and (max-width: 1024px) {
        width: initial;
        height: 50px;
        line-height: 50px;
        padding-right: 5px;
        border: 0;
        right: 0;

        .ssc-title {
            overflow: hidden;
            padding: 0 0 0 50px;
            height: 48px;
        }

        .sf-button-pay {
            min-width: 50px;
            width: initial;
            height: 50px;
            line-height: 50px;

            i,
            span {
                top: 2px;
                position: relative;
            }

            span {
                margin-left: 0;
            }
        }

    }
}

.basketPopup {
    background: #fff;
    color: #231f20;
    font-size: 13px;
    position: fixed;
    top: 70px;
    right: -5px;
    width: 375px;
    padding: 0 15px 15px 15px;
    z-index: 105;
    display: none;
    line-height: 1;
    border: 1px solid #eee;

    .searchExProd {
        padding: 15px 0 15px 70px;
        border-bottom: 1px solid #dfdfdf;
        @extend %clear;

        figure {
            float: left;
            margin: 0 0 0 -70px;

            a {
                display: block;
                width: 60px;

                img {
                    max-width: 100%;
                }
            }
        }
    }

    .examLink {
        font-size: 14px;
        margin: 0 0 4px;
        line-height: 18px;
        color: $dark;
        font-weight: 600;

        @media screen {
            transition: color $time;

            &:hover {
                color: $red;
            }
        }
    }

    .price {
        padding: 0;
    }

    .sizeColor {
        margin: 10px 0;
        line-height: 17px;

        span {
            color: #a0a0a0;
            display: inline-block;
            font-size: 12px;
            width: 73px;
            text-transform: uppercase
        }
    }

    .basketPopup-scroll {
        max-height: calc(100vh - 70px - 184px - 15px - 15px);
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        padding: 0 15px;
    }

    @media screen and (max-width: 1024px) {
        top: 54px;
        right: 0;
    }
    @media screen and (max-width: 374px) {
        width: 320px;
    }
}

.basketBott {
    padding: 9px 0 0;

    p {
        float: left;
        line-height: 36px;
        margin: 0;

        span {
            font-weight: 700;
        }
    }

    .btn-primary {
        float: right;
        font-weight: 700
    }
}

.basketPay {
    font-size: 18px;
    padding: 18px 0 0;

    p {
        margin: 0 0 10px
    }

    .allItems {
        float: left
    }

    .fullPrice {
        float: right;
        color: #f15732;
        font-weight: 700
    }

    .btn {
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        height: 48px;
        text-transform: none;
        margin: 10px 0 15px;
        border-radius: 5px;

        &.btn-primary {
            //border: 2px solid $red;
            border: 1px solid rgba($grey, .05);
            //color: $red;
            background-color: $color-blue;
            color: #fff;
        }

        &.continue {
            margin: 0;
            border: 1px solid rgba($grey, .1);
        }

        @media screen {
            transition: background-color $time, color $time, border-color $time;

            &:hover {
                &.continue {
                    background-color: rgba($grey, .2);
                    color: $text;
                }
                &.btn-primary {
                    background-color: $text;
                    color: #fff;
                }
            }

            //&.continue {
            //    &:hover {
            //        background-color: #eee;
            //        color: $text;
            //    }
            //    &:active {
            //        background-color: darken(#eee, 7%);
            //    }
            //}
            //
            //&.btn-primary {
            //    &:hover {
            //        color: #fff;
            //        background-color: $red;
            //    }
            //    &:active {
            //        background-color: darken($red, 7%);
            //    }
            //}
        }
    }

}

html {

    &.is-filter-open,
    &.is-popup-open,
    &.is-nav-open,
    &.basket-open {

        body {
            overflow: hidden;
            -webkit-overflow-scrolling: hidden;

            &.body404 {
                overflow: initial;
                -webkit-overflow-scrolling: initial;
            }
        }

    }

    &.basket-open {
        .sf-pre-header {
			display: none;
		}
        .sf-header {
            top: 0 !important;
			position: fixed;
            //z-index: 150 !important;

            .basketPopup-scroll {
                max-height: calc(100vh - 70px - 184px - 45px);
            }

            &.is-fixed-view {
                //top: -45px !important;

                .basketPopup-scroll {
                    max-height: calc(100vh - 70px - 184px);
                }
            }
        }

        @media screen and (max-width: 1024px) {
            .sf-header {
                top: 0 !important;

                &.is-fixed-view {
                    top: 0 !important;

                    .basketPopup-scroll {
                        max-height: calc(100vh - 50px - 184px);
                    }
                }

                .basketPopup-scroll {
                    max-height: calc(100vh - 50px - 184px);
                }
            }
        }
    }

    body.modal-open {
        overflow: hidden;
        -webkit-overflow-scrolling: hidden;

        .sf-header {
            z-index: 100;
        }
    }

    &.is-filter-open {
        .sf-header {
            z-index: 0;
        }
    }

    .account-login {
        #content {
            padding-bottom: 65px;
        }
    }
}

//  Head Basket
//_______________________________
.popup-slider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    background-color: #fff;
    padding: 0;
    transform: translateX(-150%);
    overflow: hidden;
    transition: transform $time;

    &-logo {
        position: fixed;
        top: 15px;
        left: -120%;
        height: 40px;
        width: auto;
        z-index: 1000;
        transition: left $time;
    }

    &.is-open {
        transform: translateX(0);

        .popup-slider-logo {
            left: 15px;
            cursor: pointer;
        }
    }

    .popup-slider-thumb {
        height: 100%;
        border-left: 2px solid #f7f7f7;
        background-color: #fff;
        position: absolute;
        top: 0;
        right: 0;

        .prodOneTxt {
            padding: 0;
            margin-bottom: 40px;

            .code {
                display: block;
                font-weight: 400;
                margin-bottom: 15px;
                font-size: 13px;
                text-transform: uppercase;
                color: $grey;

                span {
                    display: none;
                }
            }

            .brand {
                color: $text;
                font-weight: 600;
                //text-transform: uppercase;
                font-size: 24px;
                margin-bottom: 15px;

                a {
                    color: currentColor;

                    &:hover {
                        color: $red;
                    }
                }
            }

            .heading-title {
                font-size: 18px;
                color: $color-grey;
                margin-bottom: 20px;
            }

            .new-price-block {
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
                text-align: left;

                .npb-wrap {
                    padding-left: 0;
                }
                .npb-old {
                    position: relative;
                    right: initial;
                    margin-right: 10px;
                }
                //
                //.npb-old {
                //    right: initial;
                //    left: 0;
                //
                //    & + .npb-now {
                //        display: inline-block;
                //        margin-top: 20px;
                //
                //        & + nav {
                //            top: 21px;
                //        }
                //    }
                //}

            }

            .quantity {
                padding-top: 0;
                margin-top: -10px;
                padding-bottom: 10px;

                &.is-empty-q {
                    margin-top: 0;
                    text-align: left;
                }
            }

            .p-line-sale,
            .tableLink,
            //.quantity,
            .sf-select,
            .sf-sale-link {
            //.brand {
                display: none;
            }

            .delBuy {
                padding: 0;
                margin-bottom: 15px;

                a {
                    color: $color-grey;
                    height: 40px;
                    line-height: 36px;
                    font-size: 14px;
                    font-weight: 400;
                    width: 200px;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    border: 2px solid #f7f7f7;
                    border-radius: 4px;

                    &.btnBuy {
                        background-color: $color-blue;
                        border-color: $color-blue;
                        color: #fff;
                        margin-right: 10px;

                        @media screen {
                            transition: box-shadow $time;

                            &:hover {
                                box-shadow: 0 0 10px rgba(#000, .5);
                            }
                        }
                    }

                    &.fave-link {
                        width: 40px;
                        overflow: hidden;
                        //white-space: nowrap;
                        font-size: 18px;
                        display: inline-flex;

                        i {
                            //position: relative;
                            //vertical-align: middle;
                            //top: -2px;
                            //display: inline-block;
                            //width: 36px;
                            margin: auto;
                        }

                        span {
                            display: none;
                        }

                        @media screen {
                            transition: background-color $time, color $time;

                            &:hover {
                                background-color: #f7f7f7;
                                color: $color-black;
                            }
                        }
                    }
                }

            }

            .prodDeliv {
                //width: 154px;
                top: 2px;
                position: relative;
                white-space: nowrap;
            }

            .new-action-price {
                .time-holder {
                    white-space: nowrap;
                }
            }
        }

        .prodOneParam {
            //border-top: 0;
            padding: 10px 0 15px;

            & + .prodOneParam {
                padding: 10px 0 5px;
            }

            .prodParamTxt {
                display: block;
                margin-bottom: 5px;
                color: $color-grey;
            }

            &.choose {
                padding-left: 10px;
                padding-right: 10px;
                border-top: 1px solid;
            }

        }
    }

    .popup-slider-close {
        width: 50px;
        height: 50px;
        position: absolute;
        border: 0;
        top: 1px;
        right: 1px;
        background-color: transparent;
        color: $grey;
        z-index: 1000;

        span {
            height: 1px;
            display: block;
            position: absolute;
            top: 24px;
            left: 50%;
            margin-left: -12px;
            right: 0;
            background-color: transparent;
            width: 24px;

            &:after,
            &:before {
                @extend %before;
                width: 100%;
                height: 1px;
                background-color: currentColor;
            }

            &:before {
                top: 0;
                transform: rotate(45deg);
            }

            &:after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }

        @media screen {
            transition: color $time;

            &:hover {
                color: $dark;
            }
        }

    }

    .card-line-foot {
        color: $color-grey;
    }

    @media screen and (min-width: 1201px) {
        .ps-primary {
            margin-right: 405px;
        }
        .popup-slider-thumb {
            width: 405px;
            padding: 40px 30px 50px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1200px) {

        .ps-primary {
            margin-right: 375px;
        }

        .popup-slider-thumb {
            width: 375px;
            padding: 40px 20px 50px;
        }
    }

    @media screen and (max-width: 1023px) {
        .ps-primary {
            margin-right: 0;
        }
        .popup-slider-thumb {
            display: none;
        }
    }

}

//  Instock slider
//_______________________________

.instock-list {
    //border: 5px solid #f76177;
    border: 5px solid #f7f7f7;
    background-color: #f7f7f7;
    margin-bottom: 20px;
    white-space: nowrap;
    @extend %tabl;

    @media screen {
        transition: border-color $time;

        &:hover {
            border-color: darken(#f7f7f7,5%);
        }
    }

    .il-header {
        //display: block;
        //float: left;
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        width: 25%;
        padding: 70px 20px 0;

        @media screen and (max-width: 490px) {
            padding: 20px 0 0 10px;
        }
    }

    .il-slider {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        width: calc(75% - 5px);
    }

    @media screen and (max-width: 1200px) and (min-width: 1025px) {
        .il-header {
            width: calc((100%/3) - 0px);
        }
        .il-slider {
            width: calc((200%/3) - 5px);
        }
    }

    @media screen and (max-width: 1024px) and (min-width: 951px) {
        .il-header {
            width: 25%;
        }
        .il-slider {
            width: calc(75% - 5px);
        }
    }

    @media screen and (max-width: 950px) and (min-width: 701px) {
        .il-header {
            width: calc((100%/3) - 0px);
        }
        .il-slider {
            width: calc((200%/3) - 5px);
        }
    }

    @media screen and (max-width: 700px) {
        .il-header {
            width: 50%;
        }
        .il-slider {
            width: calc(50% - 5px);
        }
    }

    @media screen and (max-width: 375px) {
        border-width: 3px;
    }
}

.il-header {
    h3 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    button {
        width: 50px;
        height: 40px;
        border: 1px solid $grey;
        float: left;
        background-color: transparent;
        font-size: 24px;
        color: $grey;

        & + button {
            margin-left: -1px;
        }

        @media screen {
            transition: color $time;

            &:hover {
                color: $dark;
            }
        }
    }

    @media screen and (max-width: 490px) {
        h3 {
            font-size: 24px;
        }
    }

    @media screen and (max-width: 400px) {
        h3 {
            font-size: 20px;
        }

        p {
            font-size: 13px;
        }
    }
}

.il-slider {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    user-select: none;
    height: 422px;

    .slider {
        width: 100%;
        height: 100%
    }

    .slide {
        width: calc((100%/3) - 0px);

        @media screen and (max-width: 1200px) and (min-width: 1025px) {
            width: 50%;
        }

        @media screen and (max-width: 1024px) and (min-width: 951px) {
            width: calc((100%/3) - 0px);
        }

        @media screen and (max-width: 950px) and (min-width: 701px) {
            width: 50%;
        }

        @media screen and (max-width: 700px) {
            width: 100%;
        }
    }

    .product5 {
        margin: 10px 7px;

        &:before {
            display: none;
        }

        @media screen and (max-width: 490px) {
            .product5-thumb {
                line-height: 150px;
                height: 150px;

                img {
                    max-height: 150px;
                    width: initial;
					height: initial;
                }
            }
        }

        @media screen and (max-width: 375px) {
            .brand {
                font-size: 12px;
                white-space: nowrap;
            }
        }
    }

    .product5Descr {
        height: 83px;

        .name,
        .brand {
            margin: 0;
        }

        .price {
            padding: 0 0 10px;
        }
    }

    @media screen and (max-width: 490px) {
        height: 275px;

        .product5 {
            margin: 10px 5px 10px 0 !important;
        }
    }
}



//  Loader
//_______________________________
.mobile-nav {

    @media screen and (min-width: 1025px) {
        display: none;
    }

    @media screen and (max-width: 1024px) {

    }

    ul {
        list-style-type: none;
        padding: 15px 15px 0 0;
        @extend %clear;
    }

    li {
        line-height: 40px;
        height: 40px;
        width: calc((100% / 2 ) - 15px);
        margin-left: 15px;
        margin-bottom: 15px;
        float: left;
        border-radius: 4px;
        border: 1px solid #d3d3d3;
        display: block;
        background-color: #fff;
        color: $color-grey;
        font-size: 14px;
        padding: 0;
        text-align: center;
        position: relative;

        &[data-index="4"] {
            width: calc(100% - 15px);
            margin-bottom: 0;
        }

        @media screen {
            transition: color $time, background-color $time, border-color $time;
            cursor: pointer;

            &:hover {
                color: $color-black;
                border-color: #f7f7f7;
                background-color: #f7f7f7;
            }
        }
    }

}



//  New nav
//_______________________________
.sfnav {
    padding: 0 75px 0 590px;
    position: relative;
    //border-bottom: 1px solid #eee;
    user-select: none;

    .nav-button-trigger {
        font-size: 24px;
        height: 44px;
        width: 44px;
        position: relative;
        top: 3px;

        i {
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            line-height: 40px;
            text-align: center;
            transition: transform $time, opacity $time;

            &.sf-icon-close {
                opacity: 0;
                transform: rotate(90deg);
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #f0f0f0;
                background-color: #fff;
            }

            &.sf-icon-menu {
                opacity: 1;
                transform: rotate(0deg);
            }
        }

        @media screen and (min-width: 1025px) {
            display: none;
        }
    }

    .nav-link {
        display: block;
        height: 68px;
        line-height: 68px;
        padding: 0 20px;
        font-size: 15px;
        color: $color-grey;
        position: relative;

        &:before {
            @extend %before;
            bottom: 0;
            left: 20px;
            right: 20px;
            height: 0;
            background-color: $color-blue;
            z-index: 1;
            display: none;
        }

        @media screen and (min-width: 1025px) {
            transition: color $time ease-in-out;

            &:before {
                display: block;
                transition: height $time ease-in-out;
            }

            &[data-index="3"] + ul>li>ul {
                min-height: 100%;
            }
        }

        @media screen and (max-width: 1240px) {
                font-size: 15px;
                padding: 0 15px;

                &:before {
                    left: 15px;
                    right: 15px;
                }
        }

        @media screen and (max-width: 1090px) {
            font-size: 14px;
            padding: 0 10px;

            &:before {
                left: 10px;
                right: 10px;
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 0 20px;
            color: #fff;
            display: block;
            font-size: 18px;

            i {
                font-family: $fontIcon;
                font-style: normal;
                font-size: 16px;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 20px;

                &:before {
                    content: "\e145";
                }
            }

            &.is-select {
                color: $color-blue;

                i:before {
                    content: "\e15b";
                }
            }

            .active > & {
                color: $color-blue;

                & + ul {
                    display: block !important;
                }
            }
        }

    }

    .fire {
        position: relative;
        display: block;
        //-webkit-backface-visibility: hidden;

        &:before {
            @extend %before;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: $color-red;
            top: 16px;
            margin-top: -2px;
            z-index: 18;
        }
    }

    .nav-sub-link {
        display: block;
        font-size: 15px;
        color: #fff;
        //color: $color-black;
        line-height: 40px;
        padding-left: 20px;
        //margin-bottom: 10px;

        //i {
        //    width: 4px;
        //    height: 4px;
        //    display: inline-block;
        //    vertical-align: middle;
        //    margin-left: 10px;
        //    border-radius: 2px;
        //    background-color: $color-blue;
        //    position: relative;
        //    top: 0;
        //}

        @media screen and (min-width: 1025px) {
            padding-left: 40px;
            max-width: 280px;
            position: relative;

            i {
                top: 16px;
                right: 0;
                width: 6px;
                height: 6px;
                border: 1px solid currentColor;
                border-bottom: 0;
                border-left: 0;
                transform: rotate(45deg);
                display: block;
                position: absolute;
                line-height: 40px;
            }
        }

        &-all {
            display: block;
            font-size: 13px;
            color: $color-blue;
            line-height: 30px;

            @media screen {
                transition: color $time;

                &:hover {
                    color: #fff;
                }
            }

            @media screen and (min-width: 1025px) {
                display: none;
            }
        }

        @media screen and (max-width: 1024px) {
            margin-bottom: 0;
            //position: relative;
            //padding-left: 20px;
            font-size: 16px;
            line-height: 36px;
            color: #fff;

            i {
                width: 4px;
                height: 4px;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                border-radius: 2px;
                background-color: $color-blue;
                //position: relative;
                //top: 0;

                position: absolute;
                left: 0;
                top: 15px;
                transition: height $time;
            }

            &.is-select {
                i {
                    height: calc(100% - 25px);
                }
            }

            &-all {
                display: none;
                padding-left: 20px;
                font-size: 14px;
                margin-bottom: 10px;
            }

        }

        @media screen and (max-width: 730px) {
            margin-bottom: 0;
        }

    }

    & > ul {
        text-align: center;
        display: table;
        width: 100%;
        font-size: 0;
        transition: transform $time;

        &:before {
            @extend %before;
            top: 0;
            left: 0;
            width: 100%;
            height: 70px;
            z-index: 5;
            opacity: 1;
            visibility: visible;
            transition: opacity $time ease-in-out .1s, visibility $time ease-in-out .1s;

            @media screen and (max-width: 1024px) {
                display: none;
            }
        }

        &:hover {
            &:before {
                opacity: 0;
                visibility: hidden;
            }
        }

        & > li {
            display: inline-block;
            vertical-align: top;

            & > ul {
                position: absolute;
                padding: 0 20px;
                top: 100%;
                left: 0;
                width: 100%;
                text-align: left;
                display: none;
                @extend %clear;

                @media screen and (min-width: 1025px) {
                    //border-top: 1px solid #eee;
                    padding: 0;
                    top: 68px;
                    background: $color-black;
                }

                //&:before {
                //    @extend %before;
                //    top: 0;
                //    left: 50%;
                //    height: 100%;
                //    width: 100vw;
                //    margin-left: -50vw;
                //    //z-index: 1;
                //    background-color: rgba($color-black, .95);
                //    //background-color: #fff;
                //    z-index: -1;
                //}

                & > li {
                    //display: inline-block;
                    vertical-align: top;
                    //width: calc((100% - 60px) / 3);
                    //margin-left: 30px;
                    padding: 0 20px 10px 0;
                    //position: relative;

                    margin: 0;
                    width: 100%;
                    display: block;

                    //&:before {
                    //    @extend %before;
                    //    top: 35px;
                    //    bottom: 30px;
                    //    width: 1px;
                    //    left: -30px;
                    //    background-color: rgba(#fff, .1);
                    //}

                    &:first-child {
                        margin-left: 0;

                        @media screen and (min-width: 1025px) {
                            padding-top: 25px;
                        }

                        //&:before {
                        //    display: none;
                        //}
                    }

                    @media screen and (max-width: 1024px) {
                        padding: 0 0 0 10px;
                        width: initial;
                        display: block;
                        margin: 0;
                        position: relative;

                        //&:before {
                        //    display: none;
                        //}
                    }
                }

                li {
                    //position: relative;
                    //z-index: 3;
                    //width: 280px;
                    width: 100%;

                    ul {
                        font-size: 13px;

                        @media screen and (max-width: 1024px) {
                            //display: none;
                            display: block;
                            padding-left: 20px;

                            &.is-open {
                                display: block;

                                //& + .nav-sub-link-all {
                                //    display: block;
                                //}
                            }
                        }
                    }
                }

                //&.is-kids-list {
                //    & > li {
                //        &:first-child {
                //            width: calc((100% - 60px) * 2 / 3);
                //        }
                //    }
                //}
                //
                &.is-brand-list {
                    @media screen and (min-width: 1025px) {
                        .nav-sub-link {
                            display: none !important;
                        }
                        ul {
                            margin-left: 20px;
                            column-count: 4;

                            &:before {
                                //transform: translateX(calc(0px - 50% - 10px));
                                transform: translateX(calc(((-1)*10px) - 50%));
                            }
                        }
                    }
                }

                @media screen and (min-width: 1025px) {

                    li {
                        ul {
                            position: absolute;
                            margin-left: 360px;
                            right: 0;
                            top: 0;
                            padding: 25px 0;
                            left: 0;
                            column-count: 3;
                            column-gap: 30px;
                            //min-height: 100%;
                            min-height: 1px;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                //left: -360px;
                                //right: 0;
                                top: 5px;
                                bottom: 0;
                                //background-color: #fff;
                                background-color: $color-black;
                                z-index: -1;
                                box-shadow: 0 1px 1px rgba(#000,.2);
                                width: 100vw;
                                left: 50%;
                                transform: translateX(calc(((-1)*180px) - 50%));
                            }
                        }

                        //&:hover {
                        //    ul {
                        //        opacity: 1;
                        //        visibility: visible;
                        //    }
                        //}
                    }

                    //&.is-brand-list {
                    //    li {
                    //        ul {
                    //            column-count: 6;
                    //        }
                    //    }
                    //}
                    //
                    //&.is-kids-list {
                    //    li {
                    //        &:first-child {
                    //            ul {
                    //                column-count: 4;
                    //            }
                    //        }
                    //    }
                    //}
                }

                @media screen and (max-width: 1024px) {
                    position: relative;
                    display: none;

                    &.is-kids-list {
                        & > li {
                            width: initial;

                            &:first-child {
                                width: initial;
                            }
                        }
                    }
                    &.is-open {
                        display: block;
                    }
                }
            }

            @media screen and (min-width: 1025px) {
                &:hover {
                    & > ul {
                        display: block;
                    }
                    & > .nav-link {
                        //color: #fff;

                            color: $color-black;

                            &:before {
                                height: 2px;
                            }
                    }

                    & > ul {
                        & > li {
                            ul {
                                opacity: 0;
                                visibility: hidden;
                            }
                            &:first-child {
                                & > .nav-sub-link {
                                    color: $color-blue;
                                }
                                ul {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                        &:hover {
                            & > li {

                                & > .nav-sub-link {
                                    //color: $color-black;
                                    color: #fff;
                                }

                                ul {
                                    opacity: 0;
                                    visibility: hidden;
                                }

                                &:hover {
                                    & > .nav-sub-link {
                                        color: $color-blue;
                                    }
                                    ul {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.li-head-nav {
                font-size: 14px;
                border-top: 1px solid #454546;
                border-bottom: 1px solid #454546;
                padding-bottom: 60px;
                margin: 0 20px 10px;
                letter-spacing: .025em;

                a {
                    color: #fff;
                    line-height: 50px;
                    display: inline-block;
                    vertical-align: top;

                    & + a {
                        margin-left: 15px;
                    }
                }
            }

            &.li-user-zone {
                margin: 0 20px;

                .user-zone {
                    position: relative;
                }

                .sf-dropdown {
                    height: initial;
                    width: 100%;

                    &-toggle {
                        height: 50px;
                        width: 100%;
                        padding: 0;
                        text-align: left;
                        background-color: transparent;
                        color: #8c8b8d;
                        font-size: 16px;
                        letter-spacing: .025em;

                        &:before {
                            border-color: #fff;
                            right: 3px;
                            top: 25px;
                        }

                        i {
                            font-size: 24px;
                            position: relative;
                            top: 3px;
                        }

                    }

                    &-menu {
                        position: relative;
                        top: 0;
                        min-width: 100%;
                        box-shadow: none;
                        background-color: transparent;

                        li {
                            a {
                                padding: 0 0 0 29px;
                                color: #8c8b8d;
                                line-height: 40px;
                                font-size: 15px;
                                display: inline-block;

                                &:hover {
                                    color: #fff;
                                }
                            }

                            &.logOut {
                                border: 0;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }

        ul ul {
            a {
                color: #a8a8a8;
                line-height: 30px;
                display: inline-block;
                width: auto;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
				margin-left: 10px;

                @media screen and (min-width: 1025px) {
                    transition: color $time;

                    &:hover {
                        color: $color-blue;
                    }
                }

                @media screen and (max-width: 1024px) {
                    font-size: 14px;
                    line-height: 36px;
                }
            }

            .active {
                a {

                }
            }
        }

        @media screen and (max-width: 1024px) {
            position: fixed;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background-color: $color-black;
            z-index: 1000;
            text-align: left;
            overflow-y: scroll;
            padding-bottom: 100px;
            border-right: 50px solid #f7f7f7;

            & > li {
                display: block;

                & > ul {
                    &:before {
                        display: none;
                    }
                }
            }
        }

    }

    @media screen and (max-width: 1195px) {
        padding-left: 490px;
    }

    @media screen and (min-width: 1025px) {
        z-index: 1;

        .active {
            & > .nav-link {
                color: $color-blue;

                &:before {
                    height: 2px;
                    display: block;
                }
            }

            & > .nav-sub-link {
                color: $color-blue;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        padding: 0;
        border-bottom: 0;

        .nav-button-trigger {
            left: 4px;
        }
        &.is-open {
            .nav-button-trigger {
                z-index: 1001;
                position: fixed;
                top: 0;
                right: 0;
                left: initial;
                width: 50px;
                height: 100vh;
                color: $color-black;

                i {
                    &.sf-icon-close {
                        opacity: 1;
                        transform: rotate(0deg);
                    }

                    &.sf-icon-menu {
                        opacity: 0;
                        transform: rotate(90deg);
                    }
                }

            }

            & > ul {
                transform: translateX(0);
            }

        }

        .active {
            & > .nav-link {
                color: $color-blue;

                & + ul {
                    display: block !important;
                }
            }

            & > .nav-sub-link {
                color: $color-blue;

                i {
                    height: calc(100% - 30px);
                }

                & + ul {
                    display: block !important;

                    & + .nav-sub-link-all {
                        display: block;
                    }
                }
            }
        }

    }

    .sf-call-us {
        position: absolute;
        //top: 50%;
        //left: 100%;
        left: 0;
        //bottom: 0;
        //width: calc(100% - 50px);
        width: 100%;
        height: 60px;
        font-size: 14px;
        color: #fff;
        padding: 0 15px;
        border: 0;
        background-color: $color-black;
        z-index: 10001;
        transform: translateY(calc(100% - 20px));
        max-width: 425px;
        //transition: transform $time;

        span {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            width: 100%;
            text-align: center;
            background-color: $color-blue;
        }

    }

    @media screen and (max-width: 1024px) {
        .is-brand-list {
                      &.is-open {
                          .nav-sub-link {
                              i {
                                  height: calc(100% - 25px) !important;
                              }
                          }
                          ul {
                              display: block !important;

                              & + .nav-sub-link-all {
                                  display: block !important;
                              }
                          }
                      }
        }
    }
}

.sf-call-us {
    @media screen and (min-width: 1025px) {
        display: none;
    }
}


//  New product page
//_______________________________
.product-full-info {
    text-align: center;

    .pfi-nav {
        background-color: rgba(#f1f1f1, .8);
        height: 40px;
        line-height: 40px;

        ul {
            display: table;
            font-size: 0;
            width: 100%;
        }

        li {
            display: inline-block;
            vertical-align: top;
            font-size: 13px;
            cursor: pointer;
            position: relative;
            color: $grey;
            min-width: 130px;

            &:before {
                @extend %before;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 2px;
                background-color: $color-blue;
                width: 0;
                transition: width $time;
            }

            &.is-active {
                color: $dark;
                //font-weight: 600;

                &:before {
                    width: 100%;
                }
            }

            @media screen {
                transition: color $time;

               &:hover {
                   color: $color-blue;
               }
            }
        }

        @media screen and (max-width: 799px) {

            .container {
                overflow-x: scroll;
                overflow-y: hidden;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    //width: 2px;
                    height: 0;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 0;
                    background-color: rgba(#000, .4);
                }
            }

            ul {
                white-space: nowrap;
            }
        }
    }

    .pfi-rt-content {
        min-height: 400px;

        @media screen and (max-width: 374px) {
            min-height: 230px;
        }
    }

    .pfi-tab {
        margin-bottom: 60px;
        position: relative;
    }

    .pfi-tab-item {
        //display: none;
        position: absolute;
        width: 100%;
        top: -1000000px;
        left: -10000000px;
        text-align: left;
        padding: 30px 15px 0;

        &.is-active {
            //display: block;
            position: relative;
            left: initial;
            top: initial;
        }

        .map {
            height: 400px;
            position: relative;
        }

        &.pfi-tab-map {
            padding: 30px 0 0;
        }

        &:not(.pfi-tab-map) {
            & > div {
                max-width: 625px;
                margin: 0 auto;
                width: 100%;
            }
        }

        .page_text {
            padding: 0 !important;
            max-height: initial !important;
        }

        table {
            font-size: 13px;
            margin-bottom: 10px;

            td {
                padding: 5px 0 0 5px;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    .pfi-relate-link {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display: none;

        .container div {
            display: table;
            table-layout: fixed;
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-right: -15px;
            font-size: 0;
            text-align: center;
        }

        a {
            display: table-cell;
            height: 80px;
            vertical-align: middle;
            font-size: 16px;
            color: $dark;

            & + a {
                border-left: 1px solid #eee;
            }

            small {
                font-size: 13px;
                display: block;
                color: $grey;
            }

            @media screen {
                transition: background-color $time;

                &:hover {
                    background-color: #f7f7f7;
                }
            }

        }
    }


    .slideCounter {
        display: none !important;
    }

    .viewedSlider,
    .hitsBlock {
        //.product9,
        .product5 {
            float: left;
            width: calc((100% / 6) - 1px);
            margin: 0 1px 1px 0 !important;
            height: auto;
            -webkit-backface-visibility: visible !important;
            overflow: visible !important;
            z-index: 5;

            &:before {
                top: 0 !important;
                left: 0 !important;
                right: 0 !important;
                bottom: 0 !important;
                background-color: #fff;
                border: 0 !important;
            }

            .brand {
                position: relative;
                z-index: 2;
                padding: 0 0 10px;
                margin: 0;
                text-transform: uppercase;
                font-weight: 600;

                white-space: nowrap;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;

                a {
                    color: $text;

                    @media screen  {
                        transition: color $time;

                        &:hover {
                            color: $red;
                        }
                    }
                }
            }

            @media screen and (max-width: 1300px) {
                    width: calc(20% - 1px);
            }

            @media screen and (max-width: 1200px) {
                    width: calc(25% - 1px);
            }

            @media screen and (max-width: 950px) {
                    width: calc((100% / 3) - 1px);
            }

            @media screen and (max-width: 700px) {
                    width: calc(50% - 1px);
            }

        }

        .iosslider {
            padding: 0;
            background-color: transparent;

            .slider {
                @extend %clear;
            }

            @media screen and (max-width: 374px) {
                height: initial;
            }
        }
    }

    //.viewedBlock,
    .hitsBlock {
        position: relative;

        .iosslider {
            min-height: 378px;
        }
         .product5 {
             .name {
                 display: none;
             }

             .product5Descr {
                 height: 30px;
             }
         }

        @media screen and (max-width: 374px) {
            .iosslider {
                //height: 229px;
                min-height: 230px;
            }

            .product5 {
                height: 230px;
            }

        }
    }

    .pfi-relate-tab {
        background-color: #f7f7f7;
        text-align: left;
        padding-bottom: 20px;

        .pfi-rt-nav {
            padding: 12px 0;
            margin-left: -35px;

            button {
                font-size: 16px;
                padding: 0 20px;
                height: 42px;
                position: relative;
                width: auto;
                color: $grey;
                border: 0;
                background-color: transparent;

                @media screen {
                    transition: color $time;

                    &:hover {
                        color: $color-blue;
                    }
                }

                &.is-active {
                    color: $text;
                    font-weight: 600;
                }

            }

        }

        .pfi-rt-tab {
            display: none;

            &.is-active {
                display: block;
            }

            @media screen and (max-width: 799px) {
                 .slideCounter {
                     display: none !important;
                 }
            }
        }

        @media screen and (max-width: 799px) {
            .pfi-rt-nav {
                button {
                    font-size: 14px;
                    padding: 0;
                    margin-left: 20px;

                    &.is-active {
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 425px) {
            .pfi-rt-nav {
                white-space: nowrap;
                button {
                    font-size: 13px;

                    & + button {
                        margin-left: 10px;
                    }
                }
            }
        }

    }

}

.viewedBlock {
    position: relative;
    padding-bottom: 23px;

    //.iosslider {
    //    min-height: 378px;
    //}
    .product5 {
        .name {
            display: none;
        }

        .product5Descr {
            height: 30px;
        }
    }



    .viewedSlider {
        .product5 {
            float: left;
            width: calc((100% / 6) - 1px);
            margin: 0 1px 0 0;
            height: auto;
            -webkit-backface-visibility: visible !important;
            overflow: visible !important;
            z-index: 5;

            &:before {
                top: 0 !important;
                left: 0 !important;
                right: 0 !important;
                bottom: 0 !important;
                background-color: #fff;
                border: 0 !important;
            }

            .brand {
                position: relative;
                z-index: 2;
                padding: 0 0 10px;
                margin: 0;
                text-transform: uppercase;
                font-weight: 600;

                white-space: nowrap;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;

                a {
                    color: $text;

                    @media screen  {
                        transition: color $time;

                        &:hover {
                            color: $red;
                        }
                    }
                }
            }

            @media screen and (max-width: 1300px) {
                width: calc(20% - 1px);
            }

            @media screen and (max-width: 1200px) {
                width: calc(25% - 1px);
            }

            @media screen and (max-width: 950px) {
                width: calc((100% / 3) - 1px);
            }

            @media screen and (max-width: 700px) {
                width: calc(50% - 1px);
            }

        }
    }

    @media screen and (max-width: 374px) {
        .iosslider {
            height: 229px;
            min-height: 230px;
        }

        .product5 {
            height: 230px;
        }

    }
}


.no-viewed {
    .product-full-info {
        .pfi-rt-nav {
            button[data-tab="1"]{
                display: none;
            }
        }
    }
}


.page_text {
    max-height: 0;
    overflow: hidden;

    .bigNewsOne & {
        max-height: initial;
        overflow: initial;
        padding-top: 0 !important;
    }
}



.p-card {

    &-list {
        display: table;
        font-size: 0;
        margin-left: -15px;
        width: calc(100% + 15px);

        &.p-card-view-list,
        &.p-card-relate-list {
            .p-card-item {
                width: calc((100% / 6) - 15px);
            }

            @media screen and (max-width: 1260px) {
                .p-card-item {
                    width: calc((100% / 5) - 15px);
                }
            }
            @media screen and (max-width: 1152px) {
                .p-card-item {
                    width: calc((100% / 4) - 15px);
                }
            }
            @media screen and (max-width: 1023px) {
                .p-card-item {
                    width: calc((100% / 3) - 15px);
                }
            }
            @media screen and (max-width: 700px) {
                .p-card-item {
                    width: calc((100% / 2) - 15px);
                }
            }
            @media screen and (max-width: 424px) {
                .p-card-item {
                    width: calc(100%  - 15px);
                }
            }

        }

        &.p-card-list-cat {
            .p-card-item {
                width: calc((100% / 3) - 15px);
            }
            .p-card-thumb {
                height: initial;
            }
            @media screen and (max-width: 860px) {
                .p-card-item {
                    width: calc((100% / 2) - 15px);
                }
            }
            @media screen and (max-width: 500px) {
                .p-card-item {
                    width: calc(100% - 15px);
                }
            }
        }

    }

    &-item {
        display: inline-block;
        vertical-align: top;
        margin-left: 15px;
        margin-bottom: 15px;
        font-size: 13px;
        border-radius: 4px;
        background-color: #fff;
        text-align: center;
        position: relative;
        box-shadow: 0 0 0 1px rgba(#000,.05);
        transition: box-shadow $time ease-in-out;

        &:hover {
            box-shadow: 0 0 10px 2px rgba(#000,0.2);

            .p-card-sale {
                opacity: 1;
                visibility: visible;
            }

            .p-card-quick {
                &:before {
                    max-height: 90px;
                }

                span {
                    opacity: 1;
                    transform: translateY(0);
                }

                ins {
                    transform: translateY(0);
                    opacity: 1;
                    color: $color-black;
                    visibility: visible;
                }
            }

            .p-card-stock {
                opacity: 1;
                visibility: visible;
            }
        }

        &.is-single {
            width: calc(100% - 15px);
        }
    }

    &-thumb {
        position: relative;
        display: block;
        //height: 220px;

        img {
            max-width: 100%;
            display: inline-block;
            height: initial;
            border-radius: 4px 4px 0 0;
            max-height: 100%;
            object-fit: cover;
        }
    }

    &-sale {
        position: absolute;
        top: 13px;
        left: 13px;
        width: 44px;
        height: 44px;
        text-align: center;
        line-height: 42px;
        display: block;
        border-radius: 25px;
        color: $color-red;
        border: 1px solid;
        z-index: 4;
        opacity: 0;
        visibility: hidden;
        background-color: rgba(#fff, .9);
        transition: opacity $time ease-in-out, visibility $time ease-in-out;

        b {
            font-weight: 600;
        }

        @media screen and (max-width: 1024px) {
            opacity: 1;
            visibility: visible;
        }
    }

    &-stock {
        display: block;
        position: absolute;
        top: 20px;
        line-height: 30px;
        right: 5px;
        padding: 0 12px;
        white-space: nowrap;
        background-color: rgba(#fff,.8);
        color: $color-blue;
        opacity: 0;
        z-index: 4;
        visibility: hidden;
        transition: opacity $time ease-in-out, visibility $time ease-in-out;

        @media screen and (max-width: 1024px) {
            opacity: 1;
            visibility: visible;
        }
    }

    &-info {
        color: $color-black;
        line-height: 20px;
        padding: 10px 0;

        p {
            margin: 0;

            &.p-card-size {
                padding: 0 10px;
                @extend %toh;
            }
        }

        a {
            color: currentColor;
        }
    }

    &-quick {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        z-index: 10;
        display: none;

        &:before {
            @extend %before;
            bottom: 5px;
            left: 0;
            width: 100%;
            height: 90px;
            background-image: linear-gradient(0deg, #fff 60%, rgba(#fff, 0));
            z-index: 2;
            max-height: 0;
            overflow: hidden;
            border-radius: 0 0 4px 4px;
            transition: max-height $time ease-in-out .1s;
        }

        span {
            position: absolute;
            display: block;
            z-index: 3;
            left: 50%;
            margin-left: -75px;
            bottom: 25px;
            transform: translateY(15px);
            border-radius: 4px;
            height: 36px;
            line-height: 36px;
            width: 150px;
            background-color: $color-blue;
            color: #fff;
            box-shadow: 0 0 5px rgba(#000, 0);
            opacity: 0;
            transition: transform $time ease-in-out .1s, opacity $time ease-in-out .1s, box-shadow $time ease-in-out .1s;

            @media screen {
                &:hover {
                    box-shadow: 0 0 5px rgba(#000, .5);
                }
            }

        }

        ins {
            display: block;
            position: absolute;
            text-decoration: none;
            font-style: normal;
            width: 100%;
            color: $color-black;
            transform: translateY(30px);
            opacity: 0;
            z-index: 3;
            padding: 0 10px;
            visibility: hidden;
            transition: transform $time ease-in-out, opacity $time ease-in-out, visibility $time ease-in-out;
            top: -5px;
            @extend %toh;
        }

        @media screen and (max-width: 880px) {
            opacity: 0;
            visibility: hidden;

            &:before {
                display: none;
            }
        }

    }

    &-price {
        font-weight: 600;

        span {
            text-decoration: line-through;
            font-size: 11px;
            color: $color-grey;
            margin-right: 15px;
            font-weight: 400;
        }
    }

}

.load-complete {
    .p-card-quick {
        display: block;
    }
}


.recent-view {
    margin-top: 20px;
    margin-left: -15px;

    &.is-hide {
        display: none;
    }

    @media screen and (min-width: 1025px) {
        &.is-fxd {
            position: fixed;
            top: 60px;
            width: calc(25% - 25px);
        }

        &.is-abs {
            position: absolute;
            bottom: 95px;
            top: initial;
            width: calc(25% - 25px);
        }
    }

    @media screen and (min-width: 1441px) {
        &.is-fxd {
            position: fixed;
            top: 60px;
            width: 300px;
        }

        &.is-abs {
            position: absolute;
            bottom: 95px;
            top: initial;
            width: 300px;
        }
    }

    &-head {
        line-height: 17px;
        font-size: 13px;
        font-weight: 600;
        color: $color-black;
        margin-bottom: 15px;
        position: relative;

        button {
            position: absolute;
            right: 0;
            top: 0;
            height: 17px;
            font-size: 11px;
            color: $color-blue;
            padding: 0;
            border: 0;
            background-color: transparent;
            border-bottom: 1px solid;

            @media screen {
                transition: color $time;

                &:hover {
                    color: $color-black;
                }
            }
        }
    }

    &-list {
        display: table;
        font-size: 0;
        margin-left: -15px;
        width: calc(100% + 15px);
    }

    &-item {
        display: inline-block;
        vertical-align: top;
        width: calc((100% / 2) - 15px);
        margin-bottom: 15px;
        margin-left: 15px;
        transition: max-height $time, margin $time;

        a {
            display: block;
            text-align: center;
            padding: 15px 5px;
            border-radius: 4px;
            box-shadow: 0 1px 2px rgba(#000, .2);
            background-color: #fff;

            img {
                display: inline-block;
                max-width: 100%;
                height: auto;
            }

            @media screen {
                transition: background-color $time, box-shadow $time;

                &:hover {
                    box-shadow: 0 1px 4px rgba(#000, .3);
                }
            }
        }

        &.is-hidden-card {
            display: none;
        }

        @media screen and (min-width: 1196px) {
            width: calc((100% / 3) - 15px);
        }
    }

    &-link {
        display: block;
        border-radius: 4px;
        border: 1px solid #ddd;
        font-size: 13px;
        text-align: center;
        height: 30px;
        line-height: 28px;
        color: $color-black;

        &.is-hide-link {
            display: none;
        }

        @media screen {
            transition: background-color $time, box-shadow $time;

            &:hover {
                background-color: #ddd;
                color: $color-black;
                box-shadow: 0 1px 2px rgba(#000,.5);
            }
        }
    }

}

.footer-cat {
    position: relative;
    text-align: center;
    padding-bottom: 10px;
    @extend %clear;

    .go-top {
        height: 30px;
        font-size: 13px;
        color: $color-grey;
        padding: 0;
        border: 0;
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 5px;

        i {
            transform: rotate(90deg);
            display: inline-block;
            font-size: 16px;
            vertical-align: middle;
            position: relative;
            top: -2px;
        }

        @media screen {
            transition: color $time;

            &:hover {
                color: $color-black;
            }
        }
    }

    .more {
        margin: 20px 0 -30px;
        text-align: left;

        a {
            height: 30px;
            font-size: 13px;
            font-weight: 600;
            color: $color-blue;
            line-height: 30px;
            background-color: transparent;
            text-transform: none;
            position: relative;
            padding: 0 0 0 24px;
            border: 0;

            &:before {
                font-family: $fontIcon;
                font-size: 16px;
                content: "\e918";
                left: 0;
                top: 0;
                position: absolute;
            }

            @media screen {
                transition: color $time;

                &:hover {
                    color: $color-black;

                    &:before {
                        animation: cssload-spin 1325ms infinite linear;
                    }
                }
            }
        }
    }

    .pagination {
        margin: 0;

        & > li {
            line-height: 30px;
            padding: 0;
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            & > span,
            & > a {
                display: block;
                min-width: 30px;
                height: 30px;
                line-height: 30px;
                border-radius: 15px !important;
                text-align: center;
                padding: 0 !important;
                border: 0 !important;
                float: none;
                margin: 0;
                background-color: transparent;
                color: $color-grey;
            }

            & > a {
                &:hover {
                    color: $color-black;
                }
            }

            &.active {
                & > span {
                    color: $color-black;
                    background-color: #e4e4e4;
                    font-weight: 400;
                }
            }

            &.next,
            &.prev {
                margin-top: 0;
                border-radius: 15px;

                a {
                    width: 30px;
                    border: 0 !important;
                    height: 30px;
                    line-height: 30px;
                    font-size: 18px;
                    background-color: $color-blue;
                    color: #fff;
                }

                @media screen {
                    transition: box-shadow $time;

                    &:hover {
                        box-shadow: 0 0 10px rgba(#000, .5);
                    }
                }
            }
        }

    }

    @media screen and (max-width: 800px) {
        .more {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 500px) {
        .pagination {
            & > li {
                margin: 0 5px;
            }
        }
    }

}

.more-section {
    text-align: center;
    margin: 0;

    a {
        display: inline-block;
        min-width: 200px;
        border-radius: 4px;
        border: 1px solid #ddd;
        font-size: 13px;
        text-align: center;
        height: 36px;
        line-height: 34px;
        color: $color-text;
        background-color: #fff;

        @media screen {
            transition: color $time, color $time, background-color $time, border-color $time;

            &:hover {
                color: #fff;
                background-color: $color-blue;
                border-color: $color-blue;
            }
        }
    }

}

.btn-form-submit {
    height: 40px;
    border-radius: 4px;
    padding: 0 20px;
    border: 0;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-color: $color-blue;

    @media screen {
        transition: box-shadow $time ease-in-out;

        &:hover {
            box-shadow: 0 0 5px rgba($color-black,.3);
        }
    }
}

#fountainG{
	position:relative;
	width:60px;
	height:12px;
	display: inline-block;
    margin: 10px 0 0 30px;
}

.fountainG{
	position:absolute;
	top:0;
	background-color:rgb(241,88,50);
	width:12px;
	height:12px;
	animation-name:bounce_fountainG;
		-o-animation-name:bounce_fountainG;
		-ms-animation-name:bounce_fountainG;
		-webkit-animation-name:bounce_fountainG;
		-moz-animation-name:bounce_fountainG;
	animation-duration:1.235s;
		-o-animation-duration:1.235s;
		-ms-animation-duration:1.235s;
		-webkit-animation-duration:1.235s;
		-moz-animation-duration:1.235s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
	transform:scale(.3);
		-o-transform:scale(.3);
		-ms-transform:scale(.3);
		-webkit-transform:scale(.3);
		-moz-transform:scale(.3);
	border-radius:8px;
		-o-border-radius:8px;
		-ms-border-radius:8px;
		-webkit-border-radius:8px;
		-moz-border-radius:8px;
}

#fountainG_1{
	left:0;
	animation-delay:0.496s;
		-o-animation-delay:0.496s;
		-ms-animation-delay:0.496s;
		-webkit-animation-delay:0.496s;
		-moz-animation-delay:0.496s;
}

#fountainG_2{
	left:12px;
	animation-delay:0.6125s;
		-o-animation-delay:0.6125s;
		-ms-animation-delay:0.6125s;
		-webkit-animation-delay:0.6125s;
		-moz-animation-delay:0.6125s;
}

#fountainG_3{
	left:24px;
	animation-delay:0.739s;
		-o-animation-delay:0.739s;
		-ms-animation-delay:0.739s;
		-webkit-animation-delay:0.739s;
		-moz-animation-delay:0.739s;
}

#fountainG_4{
	left:36px;
	animation-delay:0.8655s;
		-o-animation-delay:0.8655s;
		-ms-animation-delay:0.8655s;
		-webkit-animation-delay:0.8655s;
		-moz-animation-delay:0.8655s;
}

@keyframes bounce_fountainG{
	0%{
	transform:scale(1);
		background-color:rgb(241,88,50);
	}

	100%{
	transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-o-keyframes bounce_fountainG{
	0%{
	-o-transform:scale(1);
		background-color:rgb(241,88,50);
	}

	100%{
	-o-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-ms-keyframes bounce_fountainG{
	0%{
	-ms-transform:scale(1);
		background-color:rgb(241,88,50);
	}

	100%{
	-ms-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-webkit-keyframes bounce_fountainG{
	0%{
	-webkit-transform:scale(1);
		background-color:rgb(241,88,50);
	}

	100%{
	-webkit-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}

@-moz-keyframes bounce_fountainG{
	0%{
	-moz-transform:scale(1);
		background-color:rgb(241,88,50);
	}

	100%{
	-moz-transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}