//////////////////////////////////////////////////
//                  Form
//////////////////////////////////////////////////


.outtaHere {
    position:absolute;
    left:-3000px;
}
/*Radio buttons*/
.radioArea, .radioAreaChecked {
    float:left;
    width:15px !important;
    height:16px;
    //background: url(../../images/radio.gif) no-repeat;
    overflow: hidden;
}
.radioAreaChecked {background-position: 0 -16px;}
/*Checkboxes*/
.checkboxArea, .checkboxAreaChecked {
    float:left;
    width:16px !important;
    height:16px;
    overflow: hidden;
    background: url(../../images/checkbox.gif) no-repeat;
    cursor:pointer;
}
.checkboxAreaChecked {background-position: 0 -16px;}

/* Selects */
.selectArea {
    position: relative;
    height: 30px;
    float:left;
    border:1px solid #c6c6c6;
    background:#fff;
    border-radius: 4px;
    //behavior: url(js/PIE.htc);
    font:14px/30px 'heliosregular', sans-serif;
    color:#777;
}
.selectArea .left {
    position: absolute;
    top: 0;
    left: 0;
    width:19px;
    height:100%;
    /*background: url(../../images/select-left.gif) no-repeat;*/
    display: block;
}
.selectArea a.selectButton {
    position: absolute;
    top: 0;
    right: 0;
    width:330px;/*33px*/
    height:100%;
    background:url(../../images/select-button.gif) no-repeat 98% 50%;
    /*url(../../images/select-button.gif) no-repeat 50% 50%*/
}
.selectArea .center{
    height: 30px;
    line-height:30px;
    display:block;
    padding:0 33px 0 10px;
    font:14px/30px 'heliosregular', sans-serif;
    color:#777;
}
.selectArea .center img {
    float:left;
}
/*Selects drop-down*/
.optionsDivInvisible,
.optionsDivVisible {
    position:absolute;
    display:block;
    z-index:1010; /*1000*/
}
.drop-dif {
    background:#99CCCC;
}
.optionsDivInvisible {display: none;}
.optionsDivVisible ul {
    border-bottom: 1px solid #c6c6c6;
    border-left: 1px solid #c6c6c6;
    border-right: 1px solid #c6c6c6;
    background:#fff;
    margin:0 4px;
    padding:7px 0 8px;
    overflow:hidden;
    list-style: none;
}
.optionsDivVisible ul li {
    float:left;
    width:100%;
}
.optionsDivVisible a {
    color: #474747;
    font:14px/24px 'heliosregular', sans-serif;
    text-decoration: none;
    display: block;
    height:24px;
    padding: 0 0 0 12px;
}
.optionsDivVisible a img {
    border:none;
    float:left;
}
.optionsDivVisible a:hover {
    background: #e8e8e8;
}