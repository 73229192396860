////////////////////////////////////////
//		Extend
////////////////////////////////////////


%clear {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}


%before {
	content: '';
	display: block;
	position: absolute;
}


%toh {
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
}

%ibt {
	display: inline-block;
	vertical-align: top;
}


%ibm {
	display: inline-block;
	vertical-align: middle;
}


%tabl {
	display: table;
	width: 100%;
	font-size: 0 !important;
}


%bvh {
	-webkit-backface-visibility: hidden;
}





