@charset "utf-8";
/* CSS Document */
* {
    margin: 0;
    padding: 0
}

html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

html, body {
    height: 100%
}

body {
    background: #fff;
    min-width: 270px;
    //min-height: 100%;
    //font: 13px/22px 'Open Sans', Helvetica, Arial, sans-serif;
    font: 13px/22px $fontName, Helvetica, Arial, sans-serif;
    color: #231f20;
    -webkit-font-smoothing: antialiased;
    width: 100vw;
    overflow-x: hidden;
}

a {
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: none
    }

    @media screen {
        &:hover {
            color: #f15732;
        }

    }
}

img {
    border: none;
    display: block
}

ol, ul {
    margin: 0
}

ul li, ol li {
    list-style: none
}

input, button, select, textarea {
    //font-family: 'Open Sans', sans-serif;
    font-family: $fontName, sans-serif;
    font-weight: 400;
    height: 44px;
    border-radius: 0;
    box-shadow: none
}

input, textarea {
    border: 1px solid #c5c5c5;
    background: #fff;
    padding: 0 15px;

    @media screen and (max-width: 1024px) {
        box-shadow: none;
        -webkit-box-shadow: none;

        &:focus,
        &:active {
            box-shadow: none;
            -webkit-box-shadow: none;
        }
    }
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"] {
    @media screen and (max-width: 600px) {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none !important;
    }
}

textarea {
    resize: none;
    padding: 10px 15px
}

input[type="submit"], input[type="button"], select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

input, input:focus, button, button:focus, select, select:focus, textarea, textarea:focus {
    outline: none;
    //outline: 0 auto -webkit-focus-ring-color !important
}

.child {
    display: -moz-inline-box;
    display: inline-block;
    vertical-align: middle
}

.helper {
    display: -moz-inline-box;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0
}

h1, h2, h3, h4, h5 {
    word-wrap: break-word
}

h1 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase
}

h2, .title {
    color: #231f20;
    font-weight: 700;
    font-size: 18px;
    margin: 27px 0 17px;
    text-align: center;
    text-transform: uppercase
}

label {
    color: #949494;
    font-weight: normal;
    display: block;
    margin: 0 0 15px
}

iframe[name="google_conversion_frame"] {
    position: absolute;
}

.nopadd {
    margin: 0 -15px
}

.btn {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    //color: #484848;
    font-size: 11px;
    line-height: 36px;
    padding: 0 35px;
    text-transform: uppercase;

    &.btn-primary {
        //background: $red;
        //background-color: $dark;
        //border: 1px solid $dark;
        //background-color: $grey;
        //border: 1px solid $grey;
        border: 1px solid rgba($grey, .05);
        background-color: rgba($grey, .2);
        color: $grey;
        //color: $dark;
        font-size: 16px;
        height: 36px;
        text-transform: none;
    }

    @media screen {
        transition: border-color $time, background-color $time, color $time;
        &:hover {
            border-color: $red;
            background-color: $red;
            color: #fff;
            &.btn-primary {
                background-color: $dark;
                border-color: $dark;
                color: #fff;
            }
        }
    }
}

.open > .dropdown-toggle {
    &.btn-primary {
        background-color: #000;
    }
    &.btn-default {
        background: #fff;
        box-shadow: none;
    }
}

.green {
    color: #0f978c
}

.orange {
    color: #f15732
}

.modal {

    .close {
        border: 0;
        background: rgba(#000, 0);
        width: 40px;
        height: 40px;
        position: absolute;
        top: 33px;
        margin-top: -20px;
        right: 15px;
        text-indent: -200px;
        overflow: hidden;

        &:before,
        &:after {
            @extend %before;
            width: 20px;
            height: 1px;
            top: 50%;
            left: 50%;
            margin-left: -10px;
            background-color: $text;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        @media screen {
            //transition: color $time;

            &:before,
            &:after {
                transition: background-color $time;
            }

            &:hover {
                //color: rgba($red, .5);
                border: 0;

                &:before,
                &:after {
                    background-color: $red;
                }
            }
        }

    }

    @media screen and (max-width: 605px) {
        .close {
            top: 27px;
            right: 5px;
        }
    }

}

.product-product {
    #inner_look_now {
            z-index: 10400000 !important;
        }
}

.popup {
    border: 1px solid #000;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    .modal-header {
        background-color: #eee;
        margin: 0 0 30px;
        padding: 20px 30px;

        p {
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            text-transform: none;
            text-align: left;
        }
    }

    .modal-body {
        margin: 0 30px;
        padding: 0;
        @extend %clear;
    }

    .modal-footer {
        text-align: center;

        .btn-primary {
            background-color: $color-blue;
            border-color: $color-blue;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            height: 40px;
            padding: 0 20px;
            border-radius: 4px;
            letter-spacing: .025em;

            @media screen {
                transition: box-shadow $time;

                &:hover {
                    background-color: $color-blue;
                    border-color: $color-blue;
                    box-shadow: 0 0 5px rgba(#000,.5);
                }
            }
        }
    }

    @media screen and (max-width: 605px) {
        .modal-header {
            padding: 15px;
            margin-bottom: 15px;

            p {
                font-size: 20px;
            }
        }
        .modal-body {
            margin: 0 15px;
        }
    }

    &.is-login-block {
        width: 600px;
        height: 310px;

        @media screen and (max-width: 605px) {
            width: auto;
            max-width: 98%;
            height: 270px;
        }
    }

    &.is-block-reg {
        width: 600px;
        height: 360px;

        @media screen and (max-width: 605px) {
            width: auto;
            max-width: 98%;
            height: 328px;

            label {
                display: none;
            }
        }

        @media screen and (max-height: 420px) {
            height: 303px;
            label {
                display: none;
            }
        }
    }

    &.regPopup {

        .modal-header {
            a {
                border-bottom: 1px dashed rgba($text, .8);
                color: $text;
                font-weight: 600;
                margin: 0 0 0 10px;

                @media screen {
                    transition: border-color $time;

                    &:hover {
                        border-color: rgba($text, 0);
                    }
                }
            }
        }

        .topic {
            font-size: 24px;
            font-weight: 600;
            margin: 0 10px 0 0;
            //text-transform: uppercase;

            @media screen and (max-width: 605px) {
                font-size: 20px;
            }
        }

        label {
            margin: 0 0 5px
        }

        .sf-tel-field {
            margin-right: -1px;

            .sft-trigger {
                border-right: 0;
            }
        }

        input[type="tel"] {
            width: calc(100% - 57px);
            margin-bottom: 15px;
            border-left: 0;
        }

        input[type="text"],
        input[type="password"],
        .emailInp {
            margin: 0 0 15px;
            width: 100%
        }

        .footer {
            padding: 0 30px 30px;
            text-align: center;

            .btn-primary {
                background-color: $color-blue;
                border-color: $color-blue;
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                line-height: 40px;
                height: 40px;
                padding: 0 20px;
                border-radius: 4px;
                letter-spacing: .025em;

                @media screen {
                    transition: box-shadow $time;

                    &:hover {
                        background-color: $color-blue;
                        border-color: $color-blue;
                        box-shadow: 0 0 5px rgba(#000,.5);
                    }
                }
            }

            @media screen and (max-width: 605px) {
                padding: 0 15px 15px;
            }
        }

        .loginForm {
            .footer {
                text-align: left;
            }
        }

        .alert.alert-danger {
            position: absolute;
            left: -30px;
            right: -30px;
            top: -150px;
            border-radius: 0;
        }

        @media screen and (max-width: 605px) {
            .alert.alert-danger {
                display: none !important;

                //& ~ input[type="tel"] {
                //    border-color: $red;
                //}
            }
        }

    }

    &.reportModal {
        width: 600px;
        height: 375px;

        input[type="text"],
        input[type="email"] {
            width: 100%;
            margin-bottom: 15px;
        }

        .sf-tel-field {
            margin-right: -1px;

            .sft-trigger {
                border-right: 0;
            }
        }

        input[type="tel"] {
            width: calc(100% - 57px);
            margin-bottom: 15px;
            border-left: 0;
        }

        .modal-footer {
            padding: 10px 30px 20px;
        }

        @media screen and (max-width: 605px) {
            width: 98%;
            height: 330px;

            .modal-footer {
                padding: 0 15px 0;
            }
        }

        @media screen and (max-width: 480px) {
            .modal-header {
                p {
                    line-height: 22px;
                    font-size: 15px;
                }
            }
        }

        @media screen and (max-height: 420px) {
            height: 295px;
            label {
                display: none;
            }
        }

    }

    &.wrntModal {
        width: 500px;
        height: 500px;

        img {
            display: block;
            max-width: 100%;
        }

        .modal-body {
            padding: 0;
            margin: 0;
        }

        .close {
            z-index: 1;
            top: 0;
            right: 0;
            margin: 0;
        }

        @media screen and (max-width: 600px) {
            width: 310px;
            height: 310px;

            .close {
                margin: -5px -5px 0 0;
            }
        }

        @media screen and (max-height: 420px) {
            width: 310px;
            height: 310px;
        }
    }

    &.contactModal {
        width: 800px;
        height: 480px;

        .name {
            text-transform: uppercase;
            font-weight: 600;
            color: $red;
        }

        .contactInfo {
            width: 250px;
            padding: 0 20px 0 0;

            a {
                font-size: 14px;
            }
        }

        //.skype {
        //    margin: 0 0 2px;
        //}

        .mapRight {
            float: left;
            width: calc(100% - 250px);
            height: 355px
        }

        @media screen and (max-width: 801px) {
            max-width: 96%;
        }

        @media screen and (max-width: 650px) {
            width: 312px;
            height: 362px;
            max-width: 100%;

            .modal-body,
            .modal-header {
                margin: 0;
            }

            .mapRight {
                width: 310px;
                height: 310px;
                float: none;
            }

            .contactInfo {
                display: none;
            }
        }

        @media screen and (max-height: 420px) {
            height: 302px;

            .mapRight {
                height: 250px;
            }
        }

    }

    &.favModal {
        width: 600px;
        height: auto;
        right: auto;
        bottom: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 15px;

        .favIcon {
            display: none;
        }

        @media screen and (max-width: 605px) {
            width: 98%;
        }
    }

    &.is-success,
    &.pp-error {
        width: 600px;
        height: auto;
        right: auto;
        bottom: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 20px;

        @media screen and (max-width: 605px) {
            width: 98%;
        }
    }

    &.giftModal {
        background: #e0b9ac url(../../images/bg_gift.jpg) 100% 0 no-repeat;
        background-size: cover;
        border: none;
        color: #111018;
        width: 615px;
        height: 405px;

        .modal-header {
            padding: 50px;
            margin: 0;
            background: transparent;

            .close {
                top: 0;
                right: 0;
                margin: 0;
            }

            p {
                font-size: 32px;
                line-height: 46px;
                text-align: center;
                font-weight: 400;
            }
        }

        .modal-body {
            margin: 0 60px;

            p {
                font-size: 24px;
                margin: 0 0 15px
            }

            .emailInp {
                border: none;
                margin: 0 0 15px;
                width: 100%;

                &.errorInp {
                    & + span {
                        display: block;
                    }
                }
            }

            span {
                display: none;
                position: absolute;
                top: 47px;
                right: 10px;
                color: $red;
                font-size: 14px;
            }

            .btn {
                background: #000;
                border: 0;
                color: #fff;
                font-size: 12px;
                font-weight: 700;
                width: calc(50% - 5px);
                height: 44px;
                display: initial;
                float: left;
                margin: 0;
                padding: 0;
                text-align: center;

                @media screen {
                    &:hover {
                        background: #f15732;
                        color: #000
                    }
                }

                & + .btn {
                    margin-left: 10px;
                }
            }
        }

        &.giftThanks {
            height: 260px;

            .modal-body {
                margin: 0;

                p {
                    background: rgba(217, 92, 103, .64);
                    color: #fff;
                    height: 68px;
                    line-height: 68px;
                    margin: 0;
                }
            }
        }

        @media screen and (max-width: 620px) {
            width: 98%;
            padding-bottom: 30px;
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            height: auto;
            transform: translate(-50%, -50%);

            .modal-header {
                padding: 30px 50px;

                p {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            .modal-body {
                margin: 0 30px;

                p {
                    font-size: 20px;
                }
            }

            &.giftThanks {
                height: 200px;
            }
        }

        @media screen and (max-width: 480px) {
            padding-bottom: 20px;

            .modal-header {
                padding: 25px 10px 10px;

                p {
                    font-size: 20px;
                }
            }

            .modal-body {
                margin: 0 10px;

                p {
                    font-size: 18px;
                }

                .emailInp,
                .btn {
                    height: 40px;
                }

            }

            &.giftThanks {
                height: 160px;
            }
        }
    }

    &.callModal {
        width: 600px;
        height: auto;
        //right: auto;
        bottom: auto;
        //left: 50%;
        top: 50px;
        //transform: translate(-50%, -50%);

        input[type="text"],
        input[type="email"] {
            width: 100%;
            margin-bottom: 15px;
        }

        .sf-tel-field {
            margin-right: -1px;

            .sft-trigger {
                border-right: 0;
            }
        }

        input[type="tel"] {
            width: calc(100% - 57px);
            margin-bottom: 15px;
            border-left: 0;
        }

        .modal-footer {
            padding: 10px 30px 20px;
        }

        @media screen and (max-width: 605px) {
            width: 98%;
            padding-bottom: 15px;

            label {
                display: none;
            }

            .modal-footer {
                padding: 0 15px 0;
            }

        }

    }

}

.bootstrap-select > .btn {
    border: 1px solid #c5c5c5;;
    color: #949494;
    padding: 0 0 0 12px;
    font-size: 14px
}

.bootstrap-select .btn:focus {
    outline: 0 auto -webkit-focus-ring-color !important
}

.bootstrap-select > .btn:hover,
.bootstrap-select > .btn:active,
.bootstrap-select > .btn:focus,
.open > .dropdown-toggle.btn-default {
    background: #fff;
    box-shadow: none
}

.bootstrap-select .dropdown-menu {
    border: 1px solid #c5c5c5;
    border-radius: 0;
    padding: 0
}

.bootstrap-select .dropdown-menu > li > a {
    border: none;;
    color: #949494;
    height: 44px;
    line-height: 44px;
    padding: 0 20px
}

.bootstrap-select .dropdown-menu > li > a:hover,
.bootstrap-select .dropdown-menu > li > a:focus {
    background: #f1f1f1
}

.bootstrap-select .btn .caret {
    //background: url(../../images/icons_sprite.png) -391px -675px no-repeat;
    border: none;
    width: 9px;
    height: 9px;
    font-family: $fontIcon;

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: "\e919";
        transform: translate(-50%, -50%) rotate(-90deg);
        font-size: 10px;
        margin-top: -2px;
    }
}

.panel {
    box-shadow: none
}

.panel-default {
    border: none;
    border-bottom: 1px solid #ebebeb;
}

.panel-group {

    .panel {
        border-radius: 0;

        & + .panel {
            margin: 0
        }
    }

    &#payAccord {
        counter-reset: pnum;

        .panel {
            margin-left: 30px;
            position: relative;

            &:before {
                counter-increment: pnum;
                content: counter(pnum) ".";
                //top: 0;
                //left: 0;
                font-size: 16px;
                font-weight: 600;
                color: $color-blue;
                position: absolute;
                top: 16px;
                left: -30px;
            }
        }
    }
}

.iosslider {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    user-select: none;

    .slider {
        width: 100%;
        height: 100%
    }
}

.regPopup {
    .emailInp {
        &:-ms-input-placeholder {
            color: #8e8e8e
        }
        &:-moz-placeholder {
            color: #8e8e8e
        }
        &::-moz-placeholder {
            color: #8e8e8e
        }
        &::-webkit-input-placeholder {
            color: #8e8e8e
        }
    }
}

.emailInp {
    &:-ms-input-placeholder {
        color: #231f20
    }
    &:-moz-placeholder {
        color: #231f20
    }
    &::-moz-placeholder {
        color: #231f20
    }
    &::-webkit-input-placeholder {
        color: #231f20
    }
}

.footerFormSign .emailInp {
    &:-ms-input-placeholder {
        color: #8e8e8e
    }
    &:-moz-placeholder {
        color: #8e8e8e
    }
    &::-moz-placeholder {
        color: #8e8e8e
    }
    &::-webkit-input-placeholder {
        color: #8e8e8e
    }
}

.continue a:hover {
    border-bottom: none
}

.logoSearch {
    padding: 27px 0 22px
}

//.place {
//    background: url(../../images/icons_sprite.png) 0 -322px no-repeat;
//    color: #231f20;
//    display: block;
//    font-weight: 700;
//    font-size: 10px;
//    line-height: 12px;
//    margin: 9px 36px 0 0;
//    padding: 0 0 0 23px;
//    width: 125px
//}

.call {
    border-bottom: 1px dashed #f15732;
    color: #f15732;
    display: inline-block;
    font-size: 11px;
    line-height: 13px;
    margin: 4px 0 0;
    text-decoration: none;

    &:hover {
        border-bottom: none
    }
}

//.callModal {
//    width: 518px;
//    height: 491px
//}
//
//.callModal input[type="text"] {
//    margin: 0 0 27px;
//    width: 388px
//}
//
//.callModal input[type="tel"] {
//    margin: 0 0 27px;
//    width: 299px
//}

.timePicker {
    border-top: 2px solid #000;
    padding: 19px 0 0;

    & > p {
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 35px
    }

    .hour {
        float: left;
        margin: 0 7px 0 0
    }

    .separ {
        float: left;
        display: block;
        width: 46px;
        height: 75px;
        line-height: 75px
    }

    .from span,
    .timeTill span {
        background: #fff;
        border: 1px solid #c5c5c5;
        display: block;
        float: left;
        font-weight: 700;
        font-size: 16px;
        width: 39px;
        height: 44px;
        line-height: 43px;

        &:first-child {
            margin: 0 4px 0 0
        }
    }
}

.clockArrow {
    display: block;
    height: 15px
}

//.up {
//    background: url(../../images/icons_sprite.png) -354px -890px no-repeat
//}
//
//.down {
//    background: url(../../images/icons_sprite.png) -354px -665px no-repeat
//}

//.callModal .modal-footer {
//    border-top: none;
//    margin: 0;
//    padding: 30px 62px;
//    text-align: center
//}

//.ks {
//    background: url(../../images/icons_sprite.png) -261px -742px no-repeat
//}
//
//.life {
//    background: url(../../images/icons_sprite.png) 0 -708px no-repeat
//}
//
//.mts {
//    background: url(../../images/icons_sprite.png) 0 -743px no-repeat
//}

.dropdown-menu .phone {
    background-position: 0 14px
}

.dropdown-menu .ks {
    background-position: -261px -736px
}

.dropdown-menu .life {
    background-position: 0 -701px
}

.dropdown-menu .mts {
    background-position: 0 -734px
}

.payModal {
    width: 760px;
    height: 535px
}

.payAsk {
    font-size: 15px;
    font-weight: 700;
    margin: 25px 0 10px
}

.payModal,
.deliveryModal {
    .modal-body {
        div {
            margin: 0 0 20px;

            p {
                &:first-child {
                    font-size: 15px;
                    font-weight: 600
                }
            }
        }
    }
    a {
        color: #f15732;
        border-bottom: 1px solid rgba(241, 87, 50, .2)
    }
}

.deliveryModal {
    width: 760px;
    height: 710px
}

.searchPopup {
    position: absolute;
    //border: 1px solid rgba(#000, .1);
    border: 2px solid #f7f7f7;
    border-radius: 4px;
    background: #fff;
    padding: 5px 0 60px;
    top: 40px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 105;
    display: none;

    .btn-search {
        //border: 1px solid rgba($grey, .1);
        font-size: 13px;
        font-weight: 600;
        width: initial;
        height: 30px;
        color: #fff;
        padding: 0 30px;
        //background-color: rgba($grey,.2);
        background-color: $color-blue;
        border: 0;
        border-radius: 4px;

        @media screen {
            transition: box-shadow $time;

            &:hover {
                box-shadow: 0 0 5px rgba(#000, .5);
            }
        }
    }

    p {
        color: $dark;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .text-center {
        position: absolute;
        padding: 17px 0 13px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        background-color: #fff;
    }

    .search-scroll {
        position: relative;
        padding: 10px 15px 0;
    }

    @media screen and (max-width: 1024px) {
        top: 50px;
    }

    @media screen and (max-width: 1400px) and (min-width: 1025px) {
        left: auto;
        min-width: 500px;
    }

}

.searchPage .searchPopular p {
    color: $dark;
    margin-bottom: 5px;
}

.searchPopular {
    margin: 0 0 20px;

    a {
        color: $text;
        border-bottom: 1px dashed rgba($text, .4);
        display: inline;
        line-height: 16px;
        margin: 0 8px 0 0;

        b {
            font-weight: 600;
        }

        @media screen {
            &:hover {
                color: $color-blue;
                border-bottom-color: rgba($color-blue, .4);
            }
        }

    }
}

.searchBrand {
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 15px;

    a {
        color: $text;
        margin: 0 27px 0 0;

        span {
            color: $grey;
            display: inline-block
        }

        &:hover {
            color: $color-blue;
        }
    }
}

.brandAlphabet li a:hover,
.brandsRow li a:hover,
.privateMenu li a:hover,
.privateFav .describe a:hover {
    color: #f15732
}

.searchExample {
    @extend %tabl;
    width: calc(100% + 15px);
    margin-left: -15px;
    position: relative;

    li {
        float: left;
        width: 50%
    }

    &:before {
        @extend %before;
        top: 0;
        left: 15px;
        right: 0;
        height: 1px;
        background-color: #eee;
    }

    & + p {
        margin-top: 20px !important;
        margin-bottom: 5px;
    }
}

.search-ex-prod {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 15px);
    margin-left: 15px;
    border-bottom: 1px solid #f7f7f7;

    a {
        display: block;
        line-height: 98px;
        min-height: 98px;
        padding-left: 65px;
        position: relative;

        figure {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -34px;

            img {
                display: block;
                max-width: 55px;
            }
        }

        .search-ex-name {
            display: inline-block;
            vertical-align: middle;
            line-height: 1.2;
            font-size: 14px;
            color: $text;
        }

        @media screen {
            .search-ex-name {
                transition: color $time;
            }
            &:hover {
                .search-ex-name {
                    color: $color-blue;
                }
            }
        }
    }
}

.examLink {
    color: $dark;
    display: block;
    font-size: 16px;
    line-height: 18px;

    span {
        font-weight: 700
    }
}

//  View All productList
.sf-view-all {
    margin-top: 20px;
    width: 100%;
    border: 1px solid $red;
    color: $red;
    font-weight: 600;
    background-color: #fff;

    @media screen {
        transition: background-color $time, color $time;

        &:hover {
            color: #fff;
            background-color: $red;
        }
    }
}

.percent {
    font-size: 26px;
    font-weight: 700;
    position: absolute;
    top: 8px;
    right: 50px;
    color: #f15732
}

.brands li a:hover {
    border-bottom-color: rgba(241, 87, 50, .2)
}

.popular {
    padding: 0 0 0 24px;

    .btn {
        border: 2px solid #f15732;
        padding: 0 30px;

        @media screen {
            &:hover {
                color: #fff
            }
        }
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin: 0 0 20px;
        text-align: left
    }
}

#content {
    position: relative;
    padding-top: 111px;
    left: 0;
    transition: left $time;
    min-height: calc(100vh - 218px);

    @media screen and (max-width: 1024px) {
        padding-top: 51px;
    }
}

.main-slider-container {
    position: relative;
    height: 0;

    @media screen and (min-width: 376px) {
        padding: 0 0 28.819% 0;
    }

    @media screen and (max-width: 375px) {
        padding: 0 0 53.3333% 0;
    }

    @media screen and (max-width: 1023px) {
        display: none;
    }

    .mainSlider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .iosslider {
        height: 100%;
    }

    .mainPrev,
    .mainNext {
        //background: url(../../images/icons_sprite.png) -364px -268px no-repeat;
        width: 36px;
        height: 36px;
        position: absolute;
        top: 50%;
        margin: -18px 0 0;
        left: 21px;
        opacity: .5;
        overflow: hidden;
        z-index: 50;
        border-radius: 50%;
        border: 1px solid #fff;

        &:before {
            font-family: $fontIcon;
            font-size: 16px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            content: "\e919";
            transform: translate(-50%, -50%);
        }

        @media screen {
            transition: opacity .5s ease-out 0s;

            &:hover {
                opacity: 1
            }
        }
    }

    .mainNext {
        //background-position: -364px -303px;
        transform: translate(-50%, -50%) rotate(180deg);
        left: auto;
        right: 21px
    }

    .slide {
        width: 100%;
        top: 0;

        img {
            display: block;
            width: 100%;
            height: auto
        }
    }

}

.newsSign {
    padding: 25px 0;
    border-bottom: 1px solid #eee;

    ins {
        text-decoration: none;
        font-style: normal;
    }

    .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        margin: 10px 12px 0 0;

        span {
            color: $red;
        }
    }

    .formSign {
        overflow: hidden;
        @extend %clear;

        .emailInp {
            width: calc(100% - 200px);
            border: 2px solid #f7f7f7;
            background-color: #f7f7f7;
            border-radius: 4px 0 0 4px;

            &:focus,
            &:active {
                border: 2px solid #f7f7f7;
                background-color: #f7f7f7;
            }
        }

        input[type="submit"] {
            width: 200px;
            background-color: $color-black;
            border: 2px solid $color-black;
            border-radius: 0 4px 4px 0;

            @media screen {
                &:hover {
                    background-color: $color-blue;
                    border: 2px solid $color-blue;
                }
            }
        }
    }

    //@media screen and (min-width: 1025px) {
    //.text {
    //width: 533px;
    //white-space: nowrap;
    //}
    //}
    //
    //@media screen and (max-width: 1024px) {
    //    .text {
    //        margin: 0 15px 0 0;
    //        width: 275px;
    //
    //        span {
    //            display: block;
    //        }
    //    }
    //
    //    .formSign {
    //        width: calc(100% - 290px);
    //    }
    //}
    //
    //@media screen and (max-width: 780px) {
    //    .emailInp {
    //        width: calc(100% - 140px);
    //    }
    //    input[type="submit"] {
    //        width: 140px;
    //    }
    //}

    @media screen and (max-width: 1023px) {
        display: none;
    }
}

.formSign {
    input {
        height: 40px;
        float: left;
        border: 2px solid;

        &[type="submit"] {
            background: $red;
            border-color: #f15732;
            color: #fff;
            width: 48px;
            font-size: 16px;
            text-align: center;
            padding: 0;
        }

        @media screen {
            transition: background-color $time, border-color $time, color $time;

            &[type="submit"] {
                &:hover {
                    background-color: #000;
                    border-color: #000;
                }
            }
        }

    }
    .emailInp {
        border: 2px solid #e2e2e2;
        border-right: none;
        padding: 0 14px;
        width: 352px;

        &:focus,
        &:active {
            border-color: $red;
        }

    }
}

.iosprev, .iosnext {
    //background: url(../../images/icons_sprite.png) -81px -1651px no-repeat;
    background: transparent;
    display: block;
    width: 15px;
    height: 22px;
    position: absolute;
    top: 0;
    left: auto;
    right: 74px;
    opacity: .5;
    z-index: 10;

    &:before {
        font-family: $fontIcon;
        content: "\e908";
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 20px;
        transform: translate(-50%, -50%);
    }

    &:hover {
        opacity: 1
    }
}

.iosnext {
    //background-position: -122px -1651px;
    right: 0;

    &:before {
        content: "\e90c";
    }
}

.blockOffer {
    border-top: 1px solid rgba(#000, .05);
    padding: 0 0 23px;

    & + .hitsBlock,
    & + .favBrands,
    & + .newsBlock {
        border-top: 0;
    }

    @media screen and (max-width: 415px) {
        .slideCounter {
            color: transparent;

            .weekNext,
            .weekPrev {
                color: $grey;
            }
        }
    }
}

.hitsBlock,
.favBrands,
.newsBlock,
.viewedBlock,
.blockOffer {
    .title {

    }

    .slideCounter {

    }

    @media screen and (max-width: 1025px) {
        .title {
            text-align: left;
            margin: 15px 0 10px !important;
            font-size: 14px !important;
        }
        .slideCounter {
            right: 0;
            margin: 0 10px;
            top: -33px;
        }
    }

}

.catalog + .newsOne,
.main-slider-container,
.favBrands,
.newsBlock,
.newsBlock + .col-lg-12, {
    @media screen and (max-width: 425px) {
        display: none;
    }
}

.blockOffer {
    border-top-color: transparent;
}

.hitsBlock {
    border-top: 1px solid rgba(#000, .05);
    padding: 0 0 23px;

    & + .favBrands,
    & + .newsBlock {
        border-top: 0;
    }
}

.favBrands {
    & + .newsBlock {
        border-top: 0;
    }
}

.favBrands .title,
.newsBlock .title {
    font-size: 16px;
    margin: 35px 0 30px
}

.blockOffer .title,
.hitsBlock .title {
    font-size: 16px;
    margin: 35px 0 20px
}

.viewedBlock,
.blockOffer,
.hitsBlock {
    .title {
        font-size: 16px;
        margin: 35px 0 20px;
        text-align: left;
    }
    .slideCounter {
        width: auto;
        padding-right: 90px;
        top: -43px;
        line-height: 27px;
        height: 27px;

        .iosprev,
        .iosnext {
            height: 27px;
            width: 43px;
            border: 1px solid #ccc;
            //position: relative;
        }
        .iosprev {
            //background-position: -65px -1650px;
            right: 42px;
        }
    }
}

.quickRight .brand a {
    color: #8e8e8e;

    &:hover {
        color: $red;
    }
}

.product5 {
    background: #fff;
    position: relative;

    &:before {
        @extend %before;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border: 1px solid #f0eeee;
    }

    .name {
        height: 20px;
        overflow: hidden;
        color: #484848;
        font-size: 12px;
        line-height: 14px;
        margin: 0 0 6px;
        padding-top: 6px;
        text-transform: uppercase;

        & + .brand {
            padding-top: 0;
        }

        a {
            color: #484848;

            @media screen {
                transition: color $time;
            }
        }

        @media screen and (max-width: 490px) {
            display: none !important;
        }
    }

    .brand {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        padding-top: 5px;
        height: 20px;
        line-height: 14px;

        a {
            color: #8e8e8e;
        }
    }

    figure {
        position: relative;
        padding-top: 10px;
        width: 100%;
        margin-bottom: 10px;
    }

    .hover-card {
        //position: absolute;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: 100%;
        //z-index: 50;
        //background-color: rgba(#8b8b8d, .8);

        p {
            font-size: 14px;
            font-weight: 600;
            line-height: 44px;
            margin: 0;
            text-align: center;
            white-space: nowrap;
        }

        a {
            color: #fff;
            display: inline-block;
            vertical-align: top;
            padding: 0 8px;
            background-color: rgba(#000, .4);
            border-radius: 3px;

            svg {
                fill: #fff;
                width: 20px;
                height: 20px;
                vertical-align: middle;
                position: relative;
                top: -2px;
                margin-right: 5px;
            }
        }

        @media screen {
            .hover-card-center {
                transition: opacity $time, visibility $time;
            }

            svg {
                transition: fill $time;
            }

            a {
                transition: background-color $time, color $time;

                &:hover {
                    background-color: rgba(#000, .7);
                }
            }
        }

        &-center {
            display: inline-block;
            vertical-align: top;
            position: absolute;
            //top: 50%;
            left: 50%;
            //bottom: 30%;
            bottom: 0;
            width: auto;
            z-index: 3;
            opacity: 0;
            visibility: hidden;
            transform: translateX(-50%);
        }
    }

    figure,
    .price,
    .product5Descr {
        position: relative;
        z-index: 2;
    }

    .product5Descr {
        @media screen and (max-width: 490px) {
            height: 26px;
        }
    }

    @media screen {
        .brand a {
            transition: color $time;
        }
        &:hover {
            z-index: 7;

            //.product5-thumb {
            //    &:before {
            //        background-color: rgba($grey,.1);
            //    }
            //}

            .sticker-price-down {
                opacity: 1;
            }

            .hover-card-center {
                opacity: 1;
                visibility: visible;
            }

            //.quickLink {
            //    opacity: 1;
            //    visibility: visible;
            //}

            //.instock,
            //.icon {
            //    opacity: 1;
            //}
        }
    }

    @media screen and (max-width: 450px) {
        .price {

            &.salePrice {
                white-space: nowrap;

                span {
                    font-weight: 400;
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
        }
    }

    .sticker-price-down {
        display: block;
        padding: 3px 15px 3px 10px;
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 13px;
        z-index: 1;
        white-space: nowrap;
        background-color: #fff;
        border: 1px solid #f04040;
        line-height: 1.4;
        color: $text;
        //color: $red;
        opacity: 0;
        transition: opacity $time;

        &:before,
        &:after {
            @extend %before;
            width: 0;
            height: 0;
            border: 5px solid transparent;

        }

        &:before {
            border-left-color: #f04040;
            border-top-color: #f04040;
            bottom: -10px;
            left: -1px;
        }

        &:after {
            border-left-color: #fff;
            border-top-color: #fff;
            bottom: -8px;
            left: 0;
        }
    }

}

.icon {
    color: #fff;
    font-size: 10px;
    //display: block;
    display: none;
    position: absolute;
    top: 3px;
    right: 3px;
    text-transform: uppercase;
    width: 32px;
    height: 15px;
    line-height: 17px;
    padding: 0;
    text-align: center;
    //opacity: 0;
    //opacity: .4;

    &.new {
        background: #198e9f
    }

    &.sale {
        background: $red;
    }

    @media screen {
        transition: opacity $time;
    }
}

.instock {
    background: #24aea3;
    color: #fff;
    //display: block;
    display: none;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    position: absolute;
    bottom: 45px;
    left: 3px;
    padding: 0 5px;
    opacity: 0;
    //opacity: .4;

    @media screen {
        transition: opacity $time;
    }
}

.product5Descr {
    height: 56px;
    padding: 0 10px;
    margin: 0;

    @media screen {
        a {
            &:hover {
                color: $red;
            }
        }
    }
}

.price {
    font-size: 13px;
    color: $text;
    font-weight: 700;
    padding: 0 10px 10px;
    margin: 0;

    &.salePrice {

        span {
            color: #8e8e8e;
            text-decoration: line-through;
            margin-right: 15px;
        }

    }
}

.product5-thumb {
    display: block;
    text-align: center;
    line-height: 300px;
    height: 300px;
    position: relative;

    img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        max-height: 300px;
        width: initial;
        height: initial;
    }

    //&:before {
    //    @extend %before;
    //    top: -10px;
    //    bottom: 0;
    //    left: 0;
    //    width: 100%;
    //    //height: 100%;
    //    background-color: rgba($grey, 0);
    //    transition: background-color $time;
    //    z-index: 2;
    //}

}

//.quickLink {
//background: rgba(0, 0, 0, 0.3);
//color: #fff;
//display: block;
//position: absolute;
//bottom: -38px;
//left: 3px;
//right: 3px;
//width: 100%;
//height: 38px;
//margin: 0;
//text-align: center;
//z-index: 50;

//bottom: 0;
//opacity: 0;
//visibility: hidden;

//a {
//background: url(../../images/icons_sprite.png) 0 -609px no-repeat;
//color: #fff;
//font-size: 10px;
//display: inline-block;
//line-height: 38px;
//padding: 0 0 0 32px;
//}

//@media screen {
//    transition: opacity $time, visibility $time, background-color $time;
//
//    &:hover {
//        background: rgba(0, 0, 0, 0.6)
//    }
//}
//@media screen and (max-width: 1024px) {
//    display: none;
//}
//}

.slideCounter {
    font-weight: 700;
    height: 25px;
    width: 89px;
    padding: 0 15px;
    position: absolute;
    top: -37px;
    right: 0;
    text-align: center
}

.favBrands {
    //border-top: 1px solid rgba(#000, .05);
    font-size: 16px;
    padding: 10px 0 25px;

    ul {
        margin: 0 0 20px;
        text-align: center;

        li {
            display: inline-block;
            height: 102px;
            width: 18%;
            text-align: center;
        }
    }

    img {
        opacity: .3;
        filter: grayscale(100%);

        @media screen {
            transition: opacity $time ease-out, filter $time ease-out;

            &:hover {
                opacity: 1;
                filter: grayscale(0)
            }
        }

    }

    @media screen and (max-width: 1024px) {
        ul {
            text-align: left;

            li {
                text-align: left;
                width: 32%;
            }
        }
    }

    @media screen and (max-width: 600px) {
        ul {
            margin-bottom: -10px;

            li {
                width: 48%;
                padding: 0 10px;
                margin-bottom: 10px;

                &:last-child {
                    display: none;
                }

                img {
                    max-width: 100%;
                    height: initial;
                }

                .helper {
                    display: none;
                }
            }
        }
    }

}

.allBrands a {
    border-bottom: 1px solid #dbdbdb;
    color: #484848;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    padding-top: 10px;

    @media screen {
        &:hover {
            color: $red;
            border-bottom-color: rgba($red, .2);
        }
    }
}

.newsBlock {
    border-top: 1px solid rgba(#000, .05);
    font-size: 14px;
    padding: 5px 0 46px;

    .slider-line-head {
        margin-bottom: 15px;
    }
}

.newsList,
.lastNews {
    @extend %tabl;
    width: calc(100% + 20px);
    margin-left: -20px;
    margin-bottom: 30px;

    .col15-lg-3 {
        float: none;
        display: inline-block;
        vertical-align: top;
        padding: 0;
        margin: 0 0 15px 20px;
        width: calc(20% - 20px);
    }
}

.newsList {

    @media screen and (max-width: 1200px) {
        .col15-lg-3 {
            width: calc(25% - 20px);
        }
    }

    @media screen and (max-width: 1000px) {
        .col15-lg-3 {
            width: calc((100% / 3) - 20px);
        }
    }

    @media screen and (max-width: 600px) {
        .col15-lg-3 {
            width: calc(50% - 20px);

            .newsSm p {
                font-size: 14px;
                margin: 10px 0 20px;
            }
        }
    }

}

.newsSm {
    max-width: initial;
    height: initial;
    margin: 0;
    font-size: 14px;

    figure {
        a {
            overflow: hidden;
            display: block;
            height: 140px;
        }
    }

    p {
        color: $text;;
        font-size: 17px;
        font-weight: 700;
        margin: 10px 0 20px;

        a {
            color: $text;

            @media screen {
                transition: color $time;

                &:hover {
                    color: $color-blue;
                }
            }
        }
    }
}

.lastNews {
    .col15-lg-3 {
        margin-bottom: 0;
    }

    @media screen and (max-width: 1260px) and (min-width: 300px) {
        overflow: hidden;
        white-space: nowrap;

        .col15-lg-3 {
            white-space: normal;
        }
    }

    @media screen and (max-width: 1260px) {
        .col15-lg-3 {
            width: calc(25% - 20px);
        }
    }

    @media screen and (max-width: 800px) {
        .col15-lg-3 {
            width: calc((100% / 3) - 20px);
        }
    }

    @media screen and (max-width: 600px) {
        .col15-lg-3 {
            width: calc(50% - 20px);

            &:last-child {
                display: none;
            }
        }

        white-space: normal;

    }

    @media screen and (min-width: 401px) {
        .newsSm {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 400px) {
        .col15-lg-3 {
            width: calc(100% - 20px);
        }
        .newsSm {

            figure {
                display: none;
            }
            p {
                font-size: 14px;
                font-weight: 600;
                margin: 5px 0 10px;
            }
        }
    }
}

.newsBlock .newsBig {
    margin-right: -15px
}

.newsBig {
    line-height: 20px;
    max-width: 470px;
    position: relative
}

.newsBig figure, .newsBig img, .newsSm figure, .newsSm img, .subOne figure, .subOne img {
    max-width: 100%;
    height: initial;
}

//.tvSm figure {
//    height: 218px;
//    overflow: hidden
//}

.bgOverlay {
    background: rgba(2, 29, 46, 0.6);
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.newsBig {
    .child {
        color: #fff;
        padding: 0 0 0 20px
    }
    p {
        font-size: 26px;
        font-weight: 700;
        line-height: 30px;
        margin: 0 0 15px;
        text-transform: uppercase
    }

    .bgOverlay a {
        display: block;
        width: 100%;
        height: 100%;

        .child {
            width: 250px
        }

    }
}

//.tvSm {
//    height: 330px
//}

.like {
    height: 40px
}

.like li {
    float: left;
    margin: 0 10px 0 0
}

.like .fb {
    margin: -2px 10px 0 0
}

.holder .back-more {
    color: #f15732;
    display: inline-block;
    margin: 0 0 20px
}

//.footerSignScroll,
//.footerSign .row {
//    position: relative;
//
//    @media screen and (min-width: 1025px) {
//        height: 60px;
//    }
//
//}
//
//.footerSignScroll {
//    border-top: 1px solid #bbb;
//}

/* .footerSign {
    //background: #2b2d30;
    background-color: rgba(#fff, .8);
    color: #fff;
    font-size: 14px;
    width: 100%;
    position: relative;

    .col15-lg-12 {
        padding-top: 5px;
        .nopadd {
            @extend %clear;
        }
    }

    .col15-lg-3 {
        padding-top: 13px;
        text-align: right;
        white-space: nowrap;

        a {
            line-height: 34px;
            display: inline-block;
            vertical-align: top;
            height: 34px;

            &.footerBasket {
                margin: 0 10px 0 20px;
                float: none !important;
            }
        }
    }

    &:before {
        @extend %before;
        top: -30px;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(#fff, 0), rgba(#fff, .8));
    }

    &.fixed {
        position: fixed;
        z-index: 90;
        left: 0;
        //bottom: 0;
        bottom: -60px;

        .go-top {
            position: relative;
            &.is-active {
                bottom: 60px;
            }
        }
    }

    .text {
        font-weight: 400;
        float: left;
        line-height: 50px;
        padding: 0 22px 0 0;
        color: $dark;
    }

    @media screen and (min-width: 1025px) {
        height: 60px;
    }

    @media screen and (max-width: 1024px) {

        height: auto;

        //  Hide footer cart
        //.col15-lg-3 {
        //    display: none;
        //}
        .footerTurn {
            display: none !important;
        }

        .col15-lg-3 {
            position: absolute;
            bottom: 15px;
            right: 0;
        }
        .col15-lg-12 {
            margin-right: 210px;
            padding: 5px 0 15px 10px;

            .text {
                float: none;
                display: block;
                line-height: 30px;
                padding-right: 0;

            }
        }

        &.fixed {
            position: relative;
            bottom: initial;

            .go-top {
                //position: relative;
                &.is-active {
                    //bottom: 60px;
                    bottom: initial;
                }
            }
        }

    }

    @media screen and (max-width: 700px) {
        margin-left: 0;

        .footerFormSign {
            width: 100%;
            padding-left: 5px;
            //margin-bottom: 20px;
        }

        .text {
            display: block;
            line-height: 1.5;
            padding: 20px 0 5px 5px;
            float: none;
            margin-left: 0;
            width: 100%;
            white-space: nowrap;
        }
    }

    @media screen and (max-width: 500px) {
        .col15-lg-12 {
            margin: 0 15px 0 0;

            .text {
                white-space: normal;
                line-height: 20px;
            }
        }
        .col15-lg-3 {
            display: block;
            float: none;
            position: relative;
            text-align: left;
            padding: 15px 0 0;

            a {
                margin: 0 15px 0 0;

                &:first-child {
                    margin-left: 15px;
                }

                &.go-top {
                    margin: 0;
                    position: absolute;
                    right: 0;
                }
            }
        }
        .text {
            span {
                display: block;
            }
        }

        .go-top {
            float: right;
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 350px) {
        .col15-lg-12 {
            .text {
                white-space: normal;
                line-height: 20px;
            }
        }
    }

} */


//.go-top {
//    //display: none;
//    //position: absolute;
//    border: 0;
//    background-color: rgba(#fff, 0);
//    height: 34px;
//    width: 34px;
//    font-size: 16px;
//    color: $grey;
//
//    //svg {
//    //    width: 16px;
//    //    height: 16px;
//    //    vertical-align: middle;
//    //    fill: $grey;
//    //    transform: translateY(-3px);
//    //}
//
//    @media screen {
//
//        //svg {
//        //    transition: fill $time;
//        //}
//        &:hover {
//            color: $dark;
//            //svg {
//            //    fill: $text;
//            //}
//        }
//    }
//
//    //@media screen and (max-width: 1024px) {
//    //    left: -10px;
//    //    display: block;
//    //}
//    //@media screen and (max-width: 700px) {
//    //    display: none;
//    //}
//}

.sf-footer {
    position: relative;
    left: 0;
    //transition: left $time;
}

.footerFormSign {
    text-align: center;
    display: table;
    font-size: 0;
    width: 100%;
    padding: 30px 0;
    background: url(../../img/about-us.jpg) center no-repeat fixed;
    background-size: cover;
    position: relative;

    &:before {
        @extend %before;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .6);
        z-index: 1;
    }

    h4, p, input, button {
        position: relative;
        z-index: 3;
    }


    h4 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.025em;
        margin-bottom: 15px;
        color: #fff;
    }

    p {
        font-size: 13px;
        color: #fff;
        margin-bottom: 15px;

        b {
            font-weight: 600;
            //color: $color-blue;
        }
    }

    input {
        display: inline-block;
        width: 340px;
        vertical-align: top;
        margin: 0;
        height: 30px;
        border: 2px solid #f7f7f7;
        border-right: 0;
        font-size: 13px;
        background-color: #f7f7f7;
        padding: 0 10px;
        border-radius: 4px 0 0 4px;

        @media screen {
            transition: border-color $time, background-color $time;
        }

        &:focus {
            background-color: #f7f7f7;
        }
    }

    button {
        width: 120px;
        margin: 0;
        height: 30px;
        border: 2px solid $color-blue;
        background-color: $color-blue;
        color: #fff;
        font-size: 13px;
        border-radius: 0 4px 4px 0;

        @media screen {
            transition: box-shadow $time;

            &:hover {
                box-shadow: 0 0 5px rgba(#000,.5);
            }
        }
    }

    @extend %clear;

    &.is-footer-formsign {
        margin-top: -20px;
    }

    @media screen and (max-width: 1023px) {
        padding: 15px 15px 20px;
        margin: 15px 0 0;
        width: 100%;
        //border-radius: 10px;
        //background-color: #fff;
        //border: 1px solid #eeeded;
    }

    @media screen and (max-width: 500px) {

        input {
            width: calc(100% - 120px);
        }
    }

}

//.footerBasket {
//    //background: url(../../images/icons_sprite.png) 0 -28px no-repeat;
//    color: $grey;
//    cursor: pointer;
//    float: left;
//    //line-height: 50px;
//    line-height: 34px;
//    height: 34px;
//    //height: 50px;
//    //margin: 0 0 0 13px;
//    padding: 0 10px 0 34px;
//    position: relative;
//
//    i {
//        position: absolute;
//        top: 50%;
//        left: 0;
//        margin-top: -11px;
//        font-size: 20px;
//    }
//    //svg {
//    //    position: absolute;
//    //    top: 50%;
//    //    left: 0;
//    //    margin-top: -12px;
//    //    width: 25px;
//    //    height: 25px;
//    //    fill: $grey;
//    //}
//
//    span {
//        display: inline-block;
//        height: 20px;
//        line-height: 20px;
//        position: absolute;
//        top: 50%;
//        right: 0;
//        margin-top: -15px;
//        color: $text;
//        font-size: 10px;
//        font-weight: 700;
//        text-align: center;
//    }
//
//    @media screen {
//        transition: color $time;
//
//        svg {
//            transition: fill $time;
//        }
//
//        &:hover {
//            color: $text;
//            svg {
//                fill: $text;
//            }
//        }
//    }
//}

//.footerTurn {
//    //background: #36383b url(../../images/icons_sprite.png) 12px -767px no-repeat;
//    width: 30px;
//    height: 30px;
//    position: absolute;
//    top: 10px;
//    right: 0;
//    transition: none;
//    display: none
//}

//.footerSign {
//    &.turn {
//        background: none;
//
//        &:before {
//            display: none;
//        }
//
//        .col-lg-12 * {
//            display: none
//        }
//
//        .footerTurn {
//            //display: block;
//            display: none;
//            background-position: 3px -514px
//        }
//    }
//    &.fixed .footerTurn {
//        //display: block
//        display: none;
//    }
//}

.footerMenu {
    background-color: #f7f7f7;
    color: #8b8c8d;
    padding: 30px 0 0;

    a {
        color: #8b8c8d;
        border-bottom: 1px solid rgba(#8b8c8d, 0);

        @media screen {
            transition: color $time, border-color $time;

            &:hover {
                //color: $text;
                //border-bottom-color: rgba($text, .2)
                color: $color-blue;
                border-color: rgba($color-blue, .2);
            }
        }
    }

    .mail a {
        color: $grey !important;
        border-bottom: 1px solid rgba($grey, 0) !important;

        @media screen {
            transition: color $time, border-color $time;

            &:hover {
                color: $color-blue !important;
                border-color: rgba($color-blue, .2) !important;
            }
        }

    }

    h4 {
        color: $text;
        font-weight: 400;
        font-size: 16px;
        margin: 0 0 15px;
    }

    ul {
        margin: 0 0 24px
    }

    li {
        margin: 0 0 4px
    }

    .col15-lg-3 {
        .socnet {
            ul {
                display: table;
                font-size: 0;
                width: 100%;
                margin-bottom: 0;
            }

            li {
                height: 32px;
                width: 32px;
                display: inline-block;
                vertical-align: top;
                margin-right: 5px;
                margin-bottom: 5px;
                padding: 0;

                a {
                    display: block;
                    height: 32px;
                    width: 32px;
                    text-align: center;
                    border: 1px solid #ccc;
                    line-height: 1;
                    font-size: 16px;
                    border-radius: 4px;

                    i {
                        position: relative;
                        top: 6px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        overflow: hidden;
        background-color: #fff;
        margin-top: 20px;
        border-top: 1px solid #f0f0f0;

        .col15-lg-3 {
            display: none;

            &:last-child,
            &:first-child {
                display: block;
                width: 100%;
            }

            &:first-child {
                br {
                    display: none;
                }
            }

            .socnet {
                margin: 0 -15px;
                padding: 0 0 15px;
                position: absolute;
                top: -50px;
                left: 15px;

                h4 {
                    display: none;
                }

            }

        }
    }

}

.footLogo {
    padding: 0 75px 50px 0;
    line-height: 20px;

    p {
        margin-bottom: 0;
    }

    a {
        display: block;
        margin: 0 0 15px;
        border-bottom: 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 0 0 50px;

        .footer-logo {
            display: inline-block;
            vertical-align: top;
        }

        p {
            display: inline-block;
            margin-left: 20px;
            max-width: 600px;
            padding-top: 10px;
        }
    }

    @media screen and (max-width: 800px) {
        p {
            max-width: 400px;
            padding-top: 0;
            margin-top: -4px;
        }
    }

    @media screen and (max-width: 670px) {
        p {
            max-width: 300px;
        }
    }

    @media screen and (max-width: 570px) {
        p {
            display: block;
            margin-bottom: 10px;
            margin-left: 0;
            max-width: 100%;
        }
    }

}

.footCol {
    padding: 0 0 50px 5px
}

.footContacts {
    padding: 0 0 50px 10px;
    line-height: 20px
}

.footWeb {
    margin: 20px 0 0
}


.contactBlock .mail a:hover,
.privateForget a:hover,
.privateOrders .order a:hover,
.print:hover span,
.thanksPayDel p a:hover {
    border-bottom: none
}

.mail {
    margin: 0;

        i {
            position: relative;
            top: 1px;
        }
}

.mail a, .privateForget a {
    color: $color-blue;
    border-bottom: 1px solid rgba($color-blue, .3)
}

.contactModal .mail a:hover {
    border-bottom: none
}

.socnet {
    padding: 0 0 50px 13px;

    li {
        padding: 0;
        font-size: 16px;
    }
}

.footerBottom {
    display: none;
    background: #f3f3f3;
    height: 38px;
    font-size: 10px;
    line-height: 38px;
    text-transform: uppercase;

    @media screen and (max-width: 770px) {
        display: none;
    }
}

.partners {
    span {
        float: left;
        margin: 0 20px 0 5px
    }
    ul {
        margin: 9px 0 0
    }
    li {
        float: left;
        margin: 0 16px 0 0
    }
}

//.cards {
//    span {
//        background: url(../../images/icons_sprite.png) 0 10px no-repeat;
//        color: #6d6e70;
//        float: right;
//        margin: 0 5px 0 0;
//        padding: 0 0 0 26px
//    }
//    ul {
//        margin: 8px 0 0
//    }
//    li {
//        float: left;
//        margin: 0 0 0 11px
//    }
//}

.siteOverlay {
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    opacity: 0;
    z-index: 100;
    visibility: hidden;

    &.show {
        opacity: .5;
        visibility: visible
    }

    @media screen {
        transition: all .3s ease-out 0s;
    }
}

.page404 .siteOverlay {
    display: none !important;
}

.quickBlockOverlay {
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    opacity: 0;
    z-index: 100;
    visibility: hidden;

    &.show {
        opacity: .5;
        visibility: visible
    }

    @media screen {
        transition: all .5s ease-out 0s;
    }
}

.show {
    display: block
}

.breadcrumb {
    background: none;
    font-size: 11px;
    line-height: 13px;
    margin: 15px 0 25px;
    padding: 0;

    .breadcrumb-foot & {
        ul {
            display: none;
        }
        @media screen and (max-width: 375px) {
            margin-top: 0;
            display: block;
        }
    }

    li {
        position: relative;
        padding-bottom: 5px;

        a {
            color: $color-black;
        }

        @media screen {
            a {
                transition: color $time;

                &:hover {
                    color: $color-blue;
                }
            }
        }

        & + li:before {
            display: none
        }

        &:hover {
            & > ul {
                max-height: 1000px;
            }
            & > a {
                color: $color-blue;
            }
        }
    }

    .divider {
        margin: 0 3px
    }

    .active {
        color: $color-blue;
    }

    ul {
        position: absolute;
        top: 100%;
        left: -10px;
        margin-top: 0 !important;
        min-width: 180px;
        padding: 0 12px;
        max-height: 0;
        transition: max-height $time;
        background-color: #fff;
        z-index: 10;
        overflow: hidden;

        li {
            display: block;
            width: 100%;
            color: $color-black;
            padding: 10px 0;

            &:first-child {
                padding-top: 15px;
            }
            &:last-child {
                padding-bottom: 15px;
            }
        }
    }
}

.back-box {
    padding: 10px 0;

    .catalog-back {
        //background: #333;
        height: 40px;
        border-radius: 4px;
        //border: 1px solid #d3d3d3;
        border: 0;
        background-color: $color-blue;
        //color: $color-grey;
        color: #fff;
        margin: 0;
        min-width: 290px;
        width: 100%;
        font-size: 14px;

        span {
            text-transform: lowercase;
        }
    }

    @media screen and (min-width: 601px) {
        display: none;
    }

    @media screen and (max-width: 425px) {
        .catalog-back {
            min-width: 100%;
        }
    }
}

.catalog-header {
    background-color: #f7f7f7;
    margin-bottom: 20px;
    position: relative;

    &.catalog-header-product {
        //border-top: 1px solid #eee;
        ul {
            margin: 0;
            padding: 10px 0;
            font-size: 13px;

            li {
                line-height: 20px;
                padding-bottom: 0;
                margin-bottom: 0;

                &:last-child {
                    a {
                        color: #8b8c8d;
                    }
                }
            }
        }

        h1 {
            display: none;
        }

        @media screen and (max-width: 1024px) {
            margin-bottom: 0;
        }

        //@media screen and (max-width: 600px) {
        //    margin-bottom: 0;
        //}
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    ul {
        margin-bottom: 0;
        margin-top: 20px;

        & + h1 {
            margin: 10px 0 20px;
        }
    }

    h1 {
        font-size: 24px;
        //font-weight: 600;
        font-weight: 300;
        text-transform: none;
        margin: 15px 0 15px;

        &:first-letter {
            text-transform: uppercase;
        }

        &.sale-inside-head {
            text-indent: 40px;
            position: relative;

            &:before {
                content: 'Sale';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9;
                width: 30px;
                height: 30px;
                line-height: 30px;
                background: $red;
                color: #fff;
                padding-top: 7px;
                text-indent: 0;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
            }
        }
    }


    @media screen and (max-width: 600px) {
        .breadcrumb {
            display: none;
        }

        h1 {
            font-size: 16px !important;
            font-weight: 400;
        }

    }
}

.product-category {
    #content {
        background-color: #f7f7f7;
    }

    .recent-view.is-hide {
        display: block;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    .catalog-header {
        margin-bottom: 0;

        @media screen and (max-width: 600px) {
            h1 {
                margin: 0;
                padding: 20px 0 10px;
                text-transform: none;

                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }

    .catalogTop,
    .catBanner {
        display: none !important;
    }
}

.catalog {
    padding: 0 0 20px;

    @media screen and (min-width: 1025px) {

        .col15-lg-3 {
            width: 25%;
            padding-bottom: 20px;
        }

        .col15-lg-12 {
            width: 75%;
        }

        min-height: 100vh;
    }
}

.catalogLeft {
    //width: 200px;
//    padding: 0 25px 0 0;
//    padding: 0;

    margin-right: 0;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba($color-grey, .25);
}

.sf_filter_correct {
    .filterBlock:last-child {
        border-bottom-color: transparent !important;
    }
}

.catAdvice {
    color: #198e9f;
    display: block;
    font-weight: 700;
    height: 66px;
    line-height: 18px;
    width: 200px;
    padding: 0 0 0 72px
}

.catAdvPic {
    border: 3px solid #dfdfdf;
    border-radius: 34px;
    display: block;
    width: 66px;
    height: 66px;
    float: left;
    margin: 0 0 0 -72px;
    overflow: hidden
}

.catAdvTxt {
    display: block;
    float: left;
    width: 128px;
    height: 66px;

    .child {
        text-decoration: underline;
        width: 110px
    }
}

.similarLinks {
    margin: 5px 0 0 0;
    border-top: 2px solid #f7f7f7;
    padding-top: 10px;

    li {
        font-size: 12px;
        padding: 2px 0;

        &.active {
            & > span {
                color: $color-blue;
                display: inline-block;
                line-height: 16px;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border-bottom: 1px solid rgba($color-blue, .2);
            }
            .brand {
                border: 0;
                color: $color-blue;
            }
        }
    }

    a {
        display: inline-block;
        line-height: 16px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $text;
        border-bottom: 1px solid rgba($text, 0);

        span {
            color: #ababab;

            &:first-child {
                color: $text;
            }
        }

        &:hover {
            color: $color-blue;
            border-bottom: 1px solid rgba($color-blue, .2);

            span {
                color: $color-blue;
            }
        }
    }

}

.filtersSort {
    margin: 0 0 15px;
    position: relative;
    //padding-left: 105px;
    clear: both;
    display: none;

    a {
        background: #e6e6e6;
        color: $color-text;
        font-size: 12px;
        cursor: pointer;
        display: inline-block;
        line-height: 24px;
        margin: 0 5px 5px 0;
        padding: 0 20px 0 5px;
        position: relative;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgba(#000, .1);

        &.is-current {
            background-color: $color-blue;
            color: #fff;
        }

        &:after {
            font-family: $fontIcon;
            content: "\e5cd";
            display: block;
            margin: -12px 0 0;
            position: absolute;
            top: 50%;
            right: 10px;
            width: 7px;
            height: 7px;
            font-size: 12px;
        }

        @media screen {
            transition: background-color $time, color $time;

            &:hover {
                background-color: $color-blue;
                color: #fff;
            }
        }

    }

    .clear {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid rgba($color-grey, .2);
        color: $color-grey;
        display: inline-block;
        line-height: 17px;
        margin: 0 0 0 10px;
        padding: 0;
        background: none;
        box-shadow: none;

        small {
            font-size: inherit;
            display: none;
        }

        &:after {
            display: none;
        }

        @media screen {
            transition: background-color $time, color $time, border-color $time;

            &:hover {
                background-color: transparent;
                border-color: rgba($color-blue, .3);
                box-shadow: none;
                color: $color-blue;
            }
        }

    }

    span {
        display: none;
        //position: absolute;
        //top: 0;
        //left: 0;
        //line-height: 28px;
        //font-size: 14px;
        //color: #444;
        //font-weight: 600;
        //animation: colorBlick 3s infinite linear;
    }

    //@media screen and (max-width: 500px) {
        //padding-left: 0;
        //
        //span {
        //    display: block;
        //    position: static;
        //    margin-bottom: 5px;
        //}
    //}
}

.filtersSortBottom {
    padding: 20px 0;
    margin-left: -5px;
    clear: both;

    a {
        margin: 0 0 5px 5px;
        font-size: 14px;
        color: $grey;
        display: inline-block;
        white-space: nowrap;

        &:first-letter {
            text-transform: uppercase;
        }

        &:after {
            content: ', ';
            color: $grey !important;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &.btn-view-all {
            text-align: center;
            background-color: $color-blue;
            border-radius: 4px;
            border: 1px solid $color-blue;
            line-height: 30px;
            margin: 0 auto 20px;
            width: 300px;
            display: block;
            color: #fff;
            transform: translateX(-7px);

            &:after {
                display: none;
            }
        }

        @media screen {
            transition: color $time, box-shadow $time;

            &:hover {
                color: $blue;

                &.btn-view-all {
                    color: #fff;
                    box-shadow: 0 0 10px rgba(#000, .5);
                }
            }
        }
    }

    span {
        display: none;
    }

}

.filter-sort-wrap {
    @media screen and (min-width: 1025px) {

        &.is-fixed {

            .filtersSort {
                padding-top: 8px;
                padding-bottom: 5px;
                padding-left: 113px;
                position: fixed;
                top: 0;
                z-index: 100;
                background-color: rgba(#f7f7f7, .9);
                width: calc(80% - 110px);
                transition: top $time;

                span {
                    top: 8px;
                    left: 10px;
                }
            }

        }

    }

    @media screen and (max-width: 600px) {
        display: none;

        //padding-top: 10px;
        //
        ////.filtersSort a,
        ////span {
        //    //display: none;
        ////}
        //
        //.filtersSort .clear {
        //    display: inline-block;
        //
        //    small {
        //        display: initial;
        //    }
        //}
    }

    &.is-active {
        & + .catBanner {
            display: none;
        }
    }
}

@media screen and (max-width: 1440px) and (min-width: 1201px) {
    .no-viewed {
        .filter-sort-wrap {
            &.is-fixed {
                .filtersSort {
                    width: calc(80% - 24px);
                }
            }
        }
    }
}

@keyframes colorBlick {
    50% {
        color: $red;
        animation-timing-function: ease-in;
    }
}

.artThemes a {
    background: #ececec;
    color: #231f20;
    cursor: pointer;
    display: inline-block;
    line-height: 29px;
    margin: 0 5px 0 0;
    padding: 0 29px 0 8px;
    position: relative;

    &:hover {
        background: #000;
        color: #fff
    }
}

.catalogRight {

    .filter-open-box {
        position: absolute;
        height: 60px;
        padding: 10px 15px;
        top: 0;
        //left: 0;
        //right: 0;
        //z-index: 1000;
        z-index: 1;
        left: -15px;
        right: -15px;
        background-color: #f7f7f7;

        button {
            min-width: 320px;
            height: 40px;
            //border: 1px solid #d3d3d3;
            background-color: $color-blue;
            border: 1px solid $color-blue;
            color: #fff;
            border-radius: 4px;
            font-size: 14px;

            @media screen {
                transition: box-shadow $time;

                &:hover {
                    //background-color: $color-blue;
                    //border-color: $color-blue;
                    box-shadow: 0 0 5px rgba(#000, .5);
                    //color: #fff;
                }
            }
        }

        @media screen and (min-width: 1025px) {
            display: none;
        }

        @media screen and (max-width: 1024px) {
            &.is-fxd {
                position: fixed;
                //z-index: 10000;
                box-shadow: 0 1px 2px rgba(0,0,0,.2);
                //padding: 10px 15px;
                left: 0;
                right: 0;
            }
        }

        @media screen and (max-width: 600px) {
            button {
               min-width: 100%;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        position: relative;
        padding-top: 70px;
    }

}

.is-menu-open {
    overflow: hidden;
    -webkit-overflow-scrolling: hidden;

    .catalogRight .filter-open-box {
        z-index: 10;
    }
}

.catalogTop {
    border-bottom: 2px solid #eee;
    font-size: 11px;
    height: 49px;
    line-height: 31px;
    padding: 0 0 15px;
    margin: 0 0 20px;
    @extend %clear;

    &.empty .searchRes {
        border: 0
    }

    .filter-button {
        display: none;
        height: 40px;
        font-size: 13px;
        font-weight: 600;
        padding: 0 15px;
        border: 1px solid #bbb;
        background: transparent;
        margin-right: 10px;
        float: left;

        ins {
            text-decoration: none;
            font-style: normal;
        }

        span {
            margin-left: 3px;
        }
    }

    @media screen and (max-width: 1024px) {
        //position: relative;
        //height: 60px;
        height: 80px;
        line-height: 40px;
        position: absolute;
        top: -20px;
        padding-top: 20px;
        left: 0;
        width: 100%;

        &.is-fixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 5px 15px 0;
            height: 51px;
            width: auto;
            z-index: 1001;
            background-color: #fff;

            .sortCommerc {
                top: 5px;
                right: 120px !important;
            }
        }

        .filter-button {
            display: initial;

            & + .text-uppercase {
                display: none;
            }
        }
        .pagerTop {
            display: none;
        }
        .sortBlock {
            float: right !important;
            padding: 0;
            position: relative;

            .sortTitle {
                padding: 0 10px;
                border: 1px solid #bbb;
                line-height: 38px;
                font-size: 13px;
                font-weight: 600;
                cursor: pointer;
                margin: 0;
                height: 40px;
                display: inline-block;
                vertical-align: top;

                ins {
                    display: none;
                }
            }

            .bootstrap-select {
                display: none !important;
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: initial !important;

                font-size: 13px;
                font-weight: 600;
                background: transparent;
                border: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }

    @media screen and (max-width: 600px) {
        //.sortTitle,
        .filter-button {
            width: 130px;
            text-align: center;
            padding: 0;
        }
    }

    @media screen and (max-width: 400px) {
        //.sortTitle {
        //    width: 110px;
        //}

        .filter-button {
            padding: 0 7px;
            white-space: nowrap;
            width: auto;
        }

        .sortCommerc {
            display: none;
        }
    }
    //@media screen and (max-width: 374px) {
        //.sortTitle {
        //    width: 100px;
        //    padding: 0 !important;
        //}
        //.filter-button {
        //    padding: 0 7px;
        //    white-space: nowrap;
        //    width: auto;
        //}
        //.sortCommerc {
        //    left: auto;
        //    margin-left: 0;
        //    right: 105px;
        //    display: none;
        //}
    //}

}

.sortBlock {
    .sortTitle {
        display: none;
        //line-height: 31px;
        //display: inline-block;
        //font-weight: 600;
        //color: $text !important;
        //
        //& + a {
        //    margin-left: 0;
        //}
    }

    a {
        //display: inline-block;
        //line-height: 31px;
        //color: $blue;
        //font-size: 14px;
        //font-weight: 400;
        //margin-left: 15px;

        display: block;
        -webkit-backface-visibility: hidden;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        color: $color-grey;
        line-height: 16px;
        padding: 0 0 0 25px;
        margin: 0 0 10px;

        &:before {
            @extend %before;
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
            border-radius: 7px;
            z-index: 1;
            border: 1px solid rgba($color-grey,0.2);
            background-color: rgba($color-blue,0);
        }

        &:after {
            @extend %before;
            top: 7px;
            left: 7px;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: #fff;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            box-shadow: 0 1px 1px rgba(#000,1);
        }

        &.current {
            color: $color-text;

            &:before {
                background-color: $color-blue;
            }

            &:after {
                opacity: 1;
                visibility: visible;
            }

        }

        @media screen {
            transition: color $time;

            &:before {
                transition: background-color $time;
            }

            &:after {
                transition: opacity $time, visibility $time;
            }

            &:hover {
                color: $color-text;
            }
        }
    }

    //@media screen and (max-width: 1100px) {
    //    font-size: 13px;
    //}
    //@media screen and (max-width: 1024px) {
    //    font-size: 13px;
    //
    //    a {
    //        display: none;
    //    }
    //
    //    &.is-open {
    //        position: absolute;
    //        top: 20px;
    //        right: 0;
    //        background-color: #fff;
    //        z-index: 10;
    //        text-align: right;
    //        box-shadow: 0 0 0 1px #eee;
    //
    //        a {
    //            display: block;
    //            padding-right: 10px;
    //        }
    //        .sortTitle {
    //            border-color: transparent !important;
    //            white-space: nowrap;
    //
    //            ins {
    //                display: initial !important;
    //            }
    //        }
    //    }
    //}

}

.catalogTop.is-fixed {
    @media screen and (max-width: 1024px) {
        .sortBlock {
            &.is-open {
                top: 5px;
                right: 15px;
            }
        }
    }
}

.sortTitle {
    margin: 0 13px 0 0;
    font-size: 14px;
    text-transform: none;
    color: $grey;

    ins {
        text-decoration: none;
        font-style: normal;
    }
}

.sortBy {
    background: #fff;
    border: 1px solid #dfdfdf;
    vertical-align: top;
    margin: 0 30px 0 0;
    width: 220px !important;

    & > .btn {
        border: none;
        color: $text;
        padding: 0 10px;
        font-size: 14px;
        text-transform: none;
        line-height: 27px;
        height: 28px;

        @media screen {
            transition: background-color $time;
        }
    }

    &.btn-group {
        .btn .caret {
            right: 8px
        }
    }

    div.dropdown-menu {
        border: 1px solid #dfdfdf;
        width: 220px;
        margin: 0 0 0 -1px;
        padding: 0;
        min-height: 25px !important;
        box-shadow: none;

        ul.dropdown-menu {
            min-height: 100% !important
        }
    }

    .dropdown-menu {
        & > li {
            padding: 0;
            border-top: 1px solid #dfdfdf;

            & > a {
                font-size: 12px;
                line-height: 30px;
                padding: 0 10px;
                text-transform: none;
                height: initial;
                background-color: #fff;

                @media screen {
                    transition: background-color $time, color $time;

                    &:hover {
                        background-color: rgba(#eee, .3);
                        color: $text;
                    }
                }
            }

            &.selected {
                a {
                    background-color: rgba(#eee, .3);
                    color: $text;
                }
            }

            &:first-child {
                border-top: none;
            }
        }
    }

}

.sortBy > .btn:hover, .sortBy > .btn:active,
.sortBy.open > .dropdown-toggle.btn-default {
    background: rgba(#eee, .5);
}

.sortCommerc {
    font-size: 12px;
    padding: 0 13px 0 30px;
    position: relative;
    display: none;

    svg {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        margin-top: -13px;
        left: 0;
    }

    a {
        color: $text;
        border-bottom: 1px solid rgba($text, 0);

        span {
            font-weight: 700;
            color: $red;
        }

        &:hover {
            border-bottom: 1px solid rgba($text, .3);
        }
    }

    //&:after {
    //    background: url(../../images/icons_sprite.png) 0 -1211px no-repeat;
    //    content: '';
    //    display: block;
    //    position: absolute;
    //    top: 11px;
    //    right: 0;
    //    width: 5px;
    //    height: 9px
    //}

    @media screen and (max-width: 1024px) {
        line-height: 40px;
        height: 40px;
        padding: 0;
        width: 40px;
        overflow: hidden;
        display: block;
        position: absolute;
        white-space: nowrap;
        top: 20px;
        left: 50%;
        margin-left: -20px;
        margin-bottom: 0;

        &:after {
            display: none;
        }

        a {
            display: block;
            padding-left: 40px;
        }

        svg {
            left: 10px;
            margin-top: -10px;
        }

    }

}

.pagerTop {
    font-size: 13px;
    vertical-align: top;

    & > div {
        display: inline-block;
        overflow: hidden;
        vertical-align: top;

        span {
            font-weight: 700
        }
    }

    a {
        //background: #fff url(../../images/icons_sprite.png) no-repeat;
        border: 1px solid #dfdfdf;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        width: 45px;
        height: 30px;
        position: relative;
        color: $grey;

        &:before {
            font-family: $fontIcon;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 16px;
            transform: translate(-50%, -50%);
        }

        &:hover {
            opacity: 1
        }

        &.disabled {
            display: none;
        }

        &.next {
            margin: 0 0 0 10px;
            &:before {
                content: "\e90c";
            }
        }

        &.prev {
            margin: 0 10px 0 0;

            &:before {
                content: "\e908";
            }
        }

        @media screen {
            transition: border-color $time, border-color $time;

            &:hover {
                border-color: $dark;
                color: $dark;
            }
        }
    }
}

.landingBlock {
    border-bottom: 1px solid rgba($text, .1);
    font-size: 11px;
    line-height: 24px;
    padding: 0 0 15px;
    margin: -5px 0 20px;

    li {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;

        a {
            color: $text;
            border-bottom: 1px solid rgba($text, .2);

            @media screen {
                transition: color $time, border-color $time;

                &:hover {
                    color: $red;
                    border-bottom: 1px solid rgba($red, .2);
                }
            }
        }
    }

}

.sf-button-gift {
    float: right;
    margin: 0 0 0 -100%;
    transform: translateX(-110px) translateY(-3px);
    width: initial;
    background-color: rgba(#000, .0);
    height: 30px;
    line-height: 30px;

    svg {
        width: 30px;
        height: 30px;
        vertical-align: top;
        margin-right: 7px;
        fill: $red;
    }

    span {
        font-size: 12px;
        color: $red;
        line-height: 3;
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 1px solid rgba($red, .2);
        transform: translateY(2px);
    }

    @media screen {

        svg {
            transition: fill $time;
        }
        span {
            transition: color $time, border-color $time;
        }
        &:hover {
            svg {
                fill: $text;
            }
            span {
                color: $text;
                border-color: rgba($text, .2);
            }
        }

    }
}

.catBanner {
    margin: 0 0 15px;
    clear: both;

    @media screen and (max-width: 900px) {
        .catBannerPic {
            height: auto;
            padding: 0 15px 15px;
        }

        .catBanForm {
            clear: both;
            float: none !important;
            margin: 0;
        }
    }

    @media screen and (max-width: 600px) {
        display: none !important;
    }
}

.catBannerPic {
    background: url(../../img/winters.png) 0 0 no-repeat;
    background-size: cover;
    height: 100px;
    position: relative;
    padding: 0 20px;

    &.womens,
    &.womans-clothing {
        background-image: url(../../img/womans-clothing.png);
    }
    &.womans-shoes {
        background-image: url(../../img/womans-shoes.png);
    }
    &.womens-accessories {
        background-image: url(../../img/womans-accessories.png);
    }
    &.mens,
    &.mens-clothing {
        background-image: url(../../img/mens-clothing.png);
    }
    &.mens-shoes {
        background-image: url(../../img/mens-shoes.png);
    }
    &.mens-accessories {
        background-image: url(../../img/mens-accessories.png);
    }
    &.childrens,
    &.childrens-clothing {
        background-image: url(../../img/childrens-clothing.png);
    }
    &.childrens-shoes {
        background-image: url(../../img/childrens-shoes.png);
    }
}

.catBanTxt {
    color: #fff;
    height: 100px;
    //font-weight: 300;
    font-weight: 400;
    font-size: 36px;

    span {
        display: block;
        font-weight: 700;
        font-size: 16px;
        margin: 11px 0 0
    }
}

.catBanForm {
    margin: 15px 0 0 20px;

    p {
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 8px;
        text-transform: uppercase
    }
    .emailInp {
        background: rgba(255, 255, 255, .7);
        border: none;
        color: #231f20;
        float: left;
        padding: 0 15px;
        width: 294px;
        height: 40px;

        &:focus,
        &:active {
            background: #fff
        }

    }

    input[type="submit"] {
        width: 48px;
        height: 40px;
        padding: 0
    }
}

#searchPhoto {
    background: url(../../images/bg_search.jpg) 50% 0 no-repeat;
    background-size: cover;
    min-height: 100%;
    padding: 109px 0 0;

    @media screen and (max-width: 1024px) {
        padding-top: 51px;
    }
}

.searchPhotoText {
    color: #fff;
    padding: 38px 0 35px
}

.searchPhotoForm {
    border: 2px solid #000;
    background: #fff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .25);
    max-width: 600px;
    margin: 0 auto 35px
}

.sPhotoTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    height: 32px;
    margin: 0 auto 20px;
    max-width: 600px;
    position: relative;

    span {
        background: url(../../images/icons_sprite.png) -69px -512px no-repeat;
        display: inline-block;
        padding: 2px 0 0 63px
    }
}

.sClose {
    background: url(../../images/icons_sprite.png) -164px -887px no-repeat;
    height: 12px;
    width: 12px;
    position: absolute;
    top: 11px;
    right: 0;
    display: none
}

.sPhotoInfo {
    p {
        &:first-child {
            font-size: 24px
        }

        & + p {
            font-size: 16px;
            max-width: 600px;
            margin: 0 auto
        }
    }
}

.sInfographic {
    margin: 42px auto 0;
    max-width: 495px;

    div {
        padding: 88px 0 0;
        float: left
    }

    span {
        background: url(../../images/icons_sprite.png) -69px -886px no-repeat;
        display: block;
        float: left;
        margin: 20px 10px 0;
        width: 54px;
        height: 13px
    }
}

.sUpload {
    background: url(../../images/icons_sprite.png) 41px -939px no-repeat;
    width: 125px
}

.sColor {
    background: url(../../images/icons_sprite.png) -145px -939px no-repeat;
    width: 97px
}

.sAnswer {
    background: url(../../images/icons_sprite.png) -309px -939px no-repeat;
    width: 122px
}

.sFormUpload {
    padding: 30px 50px 28px;

    p {
        &:first-child {
            font-size: 16px;
            font-weight: 700
        }
    }
}

.sLoadblock {
    margin: 0 0 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 44px;

    input[type="file"] {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;
        font-size: 600px;
        z-index: 2;
        cursor: pointer;
        width: auto
    }

    input[type=text] {
        z-index: 1;
        width: calc(100% - 165px);
        padding-right: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis
    }

    .btn {
        border-color: #f15732;
        color: #231f20;
        padding: 0 22px;
        position: absolute;
        right: 0
    }

    @media screen {
        &:hover {
            .btn {
                background: #f15732;
                color: #fff
            }
        }
    }
}

.sFormUpload .urlInp {
    width: 100%;
    padding: 0 15px
}

.sParams {
    background: #efefef;
    padding: 20px 50px;

    label {
        margin: 0 10px 0 33px;
        line-height: 44px;
        float: left;

        &:first-child {
            margin: 0 10px 0 0
        }
    }

    .bootstrap-select {
        width: 58px !important;
        float: left !important;
        margin: 0;

        &.selSex {
            width: 70px !important;
        }
    }

    .selColor {
        .btn,
        .dropdown-menu > li > a {
            padding: 11px 0 0 11px
        }
        .color {
            display: block;
            width: 20px;
            height: 20px
        }
    }

    .selSex .btn,
    .selSex .dropdown-menu > li > a,
    .selSize .dropdown-menu > li > a {
        padding-left: 11px;
        text-transform: none
    }

    @media screen and (max-width: 530px) {
        label {
            width: 110px;
            margin: 0 10px 0 0;
            text-align: right;
        }

        .bootstrap-select {
            width: calc(100% - 120px) !important;
            margin-bottom: 10px;

            &.selSex {
                width: calc(100% - 120px) !important;
            }

            &.selSize {
                margin-bottom: 0;
            }
        }
    }

}

.sFormInfo {
    padding: 33px 54px 0;

    .name {
        width: 100%;
        margin: 0 0 19px
    }

    .emailInp {
        width: 100%;
        margin: 19px 0 0
    }

}

.formPhone {
    label {
        width: 75px;
        line-height: 44px;
    }
    input[type="tel"] {
        width: calc(100% - 70px - 75px);
    }

    .sf-tel-field .sft-list {
        z-index: 10;
    }
    @media screen and (max-width: 480px) {
        label {
            display: block;
            width: 100%;
            margin: 0;
            float: none !important;
        }
        input[type="tel"] {
            width: calc(100% - 70px);
        }
    }
}

.sFormFooter {
    margin: 0;
    padding: 23px 0 25px;

    .btn-primary {
        font-size: 18px;
        height: 44px;
        padding: 0 30px
    }
}

.back {
    color: #fff;
    border-bottom: 1px dotted #fff;
    font-weight: 700;

    &:hover {
        border-color: #f15732
    }
}

.aboutBg {
    background: url(../../images/bg_about.jpg) 50% 0 no-repeat;
    background-size: cover;
    padding: 45px 0 0;
    height: 215px
}

.about-us-page {
    padding-top: 109px;
    position: relative;

    @media screen and (max-width: 1024px) {
        padding-top: 51px;

        .col-lg-6 {
            float: none !important;
            margin: 0;
            padding: 0;
            width: 100%;
        }
        .columnText {
            margin: 0 0 40px;
            float: none !important;
        }
    }

    @media screen and (max-width: 800px) {
        .sf-login-heading {
            text-align: left;
        }
    }
}

.columnText {
    width: 100%;
    margin: 0 -15px 75px;
    font-size: 15px;
    line-height: 1.5;

    .colTitle {
        font-size: 20px;
        font-weight: 600;
        margin: 35px 0 20px;
        text-transform: uppercase;

        &:first-child {
            margin: 0 0 20px;
        }
    }

    li {
        background: url(../../images/icons_sprite.png) -198px 8px no-repeat;
        padding: 0 0 0 20px;
    }
}

.brandList {
    padding: 0 0 60px
}

.brandAlphabet {
    border-bottom: 1px solid #231f20;
    padding: 25px 0;
    margin: -73px 0 0;
    display: none;

    li {
        display: inline-block;
        margin: 0 14px 0 15px
    }
}

.brandAlphabet .active,
.brandAlphabet li:hover a {
    //border-bottom: 3px solid #f15732;
    color: $color-blue
}

.brandAlphabet li a, .brandsRow li a, .privateFav .describe p a, .basketTable .selSize .btn {
    color: #231f20
}

.brandAlphabet .active a,
.privateFav .newPrice,
.privateMess .dataTxt a,
.brandAlphabet li:hover a,
.basketTable .describe p a:hover,
.privateFav .describe .brand:hover,
.basketTable .describe .brand:hover {
    color: $color-blue
}

.brandAlphabet.fixed, .brandAlphabet.fixscroll {
    background: #fff;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50;

    @media screen {
        transition: top .3s ease-out 0s;
    }
}

.brandAlphabet.fixscroll {
    top: 45px
}

.brandsRow {
    border-bottom: 1px solid #bdbbbc;
    padding: 30px 0 40px;

    ul {
        margin: 0 55px 0 0
    }
}

.brandLetter {
    font-size: 60px;
    font-weight: 700;
    line-height: 47px
}

.profileBtn {
    width: 100%;
    display: none;
    text-align: left;
    padding: 0;
    border: 0;
    font-weight: 600;
    font-size: 14px;

    span {
        display: inline-block;
        vertical-align: text-bottom;
        width: 20px;
        height: 20px;
        overflow: hidden;
        margin-right: 5px;
        position: relative;

        ins {
            display: block;
            text-decoration: none;
            position: absolute;
            top: 10px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: rgba($dark, .6);

            &:after,
            &:before {
                @extend %before;
                width: 100%;
                height: 1px;
                background-color: rgba($dark, .6);
                transition-duration: 0.1s, 0.1s;
                transition-delay: 0.1s, 0s;
            }

            &:before {
                top: -6px;
                transition-property: top, transform;
            }

            &:after {
                bottom: -6px;
                transition-property: bottom, transform;
            }

        }
    }

    &:active,
    &:hover {
        background: transparent;
        color: $text;
        box-shadow: none;
    }

    @media screen and (max-width: 1024px) {
        display: block;
    }
}

.privateOffice {
    padding: 0 0 60px;
}

.sf-profile-page {
    position: relative;

    &:before {
        @extend %before;
        top: 109px;
        left: 0;
        width: 100%;
        background-color: #f7f7f7;
        height: 90px;
        z-index: 1;
    }

    @media screen and (max-width: 1024px) {
        &:before {
            top: 50px;
        }

        .col15-lg-12 {
            padding: 0;
        }
    }
}

.privateTitle {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 20px;
    padding: 38px 0 30px;
    position: relative;
    z-index: 2;
}

.privateMenu {
    font-size: 11px;
    font-weight: 600;

    li {
        border-bottom: 1px solid #dfdfdf;
        line-height: 48px;

        a {
            color: $text;
            display: block;

            @media screen {
                transition: color $time;
            }
        }

        &.active {
            a {
                color: $red;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.privateContent {
    margin: 0 0 0 30px;
    max-width: 100%;

    table {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        margin: 10px 0 0;
    }
}

.dataTxt {
    border-bottom: 1px solid #ebebeb;
    font-size: 16px;
    line-height: 48px;
    padding: 0 0 15px;
    margin: 0 0 25px;
    font-weight: 600;

    a {
        color: $red;
        border-bottom: 1px solid rgba($red, .3);

        &:hover {
            color: $text;
            border-bottom-color: rgba($red, 0);
        }
    }

    @media screen and (max-width: 1024px) {
        line-height: 30px;
    }
}

.privateParag {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding-top: 10px;

    & + form {
        padding-top: 20px;
    }
}

.privateForm {

    .pull-right,
    .pull-left {
        width: 48%;
    }

    input {
        width: 100%;
        margin: 0 0 25px;

        &[type="tel"] {
            width: calc(100% - 70px);
        }
    }

    label {
        margin-bottom: 5px;
    }

    .error-text {
        color: $red;
        display: block;
        font-size: 12px;
        margin: -15px 0 10px;
        text-align: right;
    }

    .btn {
        font-size: 18px;
        height: 40px;
        padding: 0 18px
    }

    @media screen and (max-width: 600px) {
        .pull-right,
        .pull-left {
            width: 100%;
            float: none !important;
        }
        input {
            margin-bottom: 15px;
        }
        .filter-option.pull-left {
            float: left !important;
        }
    }
}

.privateForget {
    color: #949494;
    width: 385px
}

.privateOrders {

    th {
        border-bottom: 1px solid #ebebeb;
        font-size: 12px;
        padding: 0 0 17px 2px;
        text-transform: uppercase;
        font-weight: 600;
    }

    td {
        border-bottom: 1px solid #ebebeb;
        padding: 25px 0 25px 2px
    }

    .order a {
        border-bottom: 1px solid rgba(241, 87, 50, .3);
        color: #f15732;
        font-weight: 700;
        font-size: 16px
    }

    .status {
        font-weight: 600
    }

    @media screen and (max-width: 800px) {
        tr {
            &:first-child {
                display: none;
            }

            td {
                &:nth-child(2),
                &:nth-child(3) {
                    display: none;
                }

            }
        }

    }

}

.allOrders {
    th {
        &:nth-child(2) {
            width: 150px
        }
        &:nth-child(3) {
            width: 105px;
        }
        &:nth-child(4),
        &:nth-child(5) {
            width: 80px;
        }
        &:nth-child(6) {
            width: 40px;
        }
    }

    @media screen and (max-width: 800px) {
        margin-bottom: 10px;

        tr {
            td {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    display: none;
                }
            }
        }
    }

}

.privateFav {

    td {
        border-bottom: 1px solid #ebebeb;
        font-size: 15px;
        padding: 20px 17px 10px 0;

        &:first-child {
            padding-right: 0;
            width: 70px;
        }
    }

    .describe {
        //width: 370px;
        vertical-align: top;
        position: relative;

        .brand {
            color: #8e8e8e;
            font-size: 13px;
            display: inline-block;
        }

        p {
            max-width: 90%;
            line-height: 19px;
        }
    }

    .price {
        width: 140px;
        text-align: center;

        span {
            font-size: 20px;
            font-weight: 600;
        }
    }

    .salePrice {
        width: 100px;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        margin: 0;
        padding-right: 0
    }

    .privat-price {
        width: 130px;
        text-align: right;
        padding: 17px 17px 10px 0;

        span {
            &.pp-original-price {
                color: $grey;
                font-size: 13px;
                display: block;
                text-decoration: line-through;
            }
            &.pp-sale-price {
                font-size: 20px;
                color: $dark;
                display: block;
            }
        }
    }

    .btn-primary {
        display: block;
        line-height: 40px;
        height: 40px;
        padding: 0 24px;
    }



    .butDel,
    .butt {
        a {
            display: block;
            width: 100%;
            line-height: 40px;
            height: 40px;
            font-size: 14px;
            color: #fff;
            background-color: $color-blue;
            text-align: center;
            border-radius: 4px;

            @media screen {
                transition: box-shadow $time ease-in-out;

                &:hover {
                    box-shadow: 0 0 5px rgba($color-black,.3);
                }
            }

        }
    }

    .butt {
        padding-right: 6px;
        width: 200px;
    }

    .butDel {
        width: 40px;
        padding-right: 0;

        a {
            font-size: 20px;
            color: $color-black;
            background-color: #f7f7f7;

            i {
                line-height: 40px;
            }
        }
    }

}

.basketTable .describe .brand {
    color: #8e8e8e;
    font-size: 13px;
    //font-style: italic;
    position: absolute;
    bottom: 4px;
    left: 0
}

.privateMess {
    max-width: 720px;

    .dataTxt {
        border-bottom: none;
        padding: 0
    }
}

.privateMessBlock,
.newsOne + p {
    margin: 0 0 40px
}

.privateMessBlock .product5 {
    float: left
}

.orderDetails {
    padding-top: 9px;

    table {
        margin: 0 0 60px;
        width: 100%
    }

    td {
        vertical-align: top;
        padding: 10px 0;

        &.orderInfo {
            padding-right: 10px;

            & + td {
                padding-right: 10px;
            }
        }
    }

    a {
        color: $red;
        border-bottom: 1px solid rgba($red, .3);

        &:hover {
            border-bottom-color: rgba($red, 0);
        }
    }

    .butDel {
        text-align: right;

        a {
            background: #fff url(../../images/icons_sprite.png) 10px -880px no-repeat;
            display: block;
            width: 30px;
            height: 30px;
            overflow: hidden;
            text-indent: 1000px;
            position: relative;
            border: 1px solid #8e8e8e;

            @media screen {
                transition: border-color $time;

                &:hover {
                    border: 1px solid $red;
                }
            }
        }
    }

    .totalTable {
        float: right;
        width: 320px;
        margin: -50px 0 60px;
    }

    @media screen and (max-width: 800px) {
        .totalTable {
            margin: 0;
            float: none;
            width: 100%;
        }
    }

}

.totalTable {

    th,
    td {
        border: none;
        padding: 7px 0;
        width: 120px
    }
    th {
        font-size: 13px;
        width: 200px
    }
}

.orderInfo span {
    font-weight: 700
}

.orderInfo ul {
    font-size: 11px
}

.orderComm {
    margin: 0 0 60px;

    .caption {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase
    }
}

.telCountry {
    width: 58px !important;
    height: 44px;
    float: left !important;
    margin: 0 12px 0 0;

    .btn {
        padding-top: 17px;
        height: 44px
    }

    .dropdown-menu > li > a {
        padding: 17px 0 0 12px
    }
}

.pagination > li {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    line-height: 25px;
    vertical-align: top;

    & > span,
    & > a {
        border: none;
        color: $text;
        font-weight: 400;
        display: inline-block;
        padding: 0 3px;
        border-radius: 0 !important;
        border-bottom: 2px solid rgba($red, 0);

        @media screen {
            transition: border-color $time, color $time;

            &:hover {
                background-color: transparent;
                border-color: $red;
                color: $red;
            }
        }
    }

    &.active {
        & > a,
        & > span {
            font-weight: 600;
            color: $red;
            background-color: transparent;
            border-bottom: 2px solid $red;
            border-radius: 0;

            &:hover,
            &:active {
                background-color: transparent;
                border-bottom: 2px solid $red;
                color: $red;
            }
        }
    }

    &.prev,
    &.next {
        margin-top: -3px;

        a {
            border: 1px solid #dfdfdf;
            width: 45px;
            height: 25px;
            line-height: 25px;
            text-indent: -1000px;
            overflow: hidden;
            border-radius: 0;
            color: $grey;
            //background: #fff url(../../images/icons_sprite.png) no-repeat;

            &:before {
                font-family: $fontIcon;
                position: absolute;
                top: 50%;
                left: 50%;
                text-indent: 0;
                transform: translate(-50%, -50%);
            }

            &:hover {
                border-color: $red;
                color: $text;
            }
        }
    }

    &.prev a {
        //background-position: -377px -576px;
        &:before {
            content: "\e908";
        }
    }

    &.next a {
        //background-position: -376px -784px;
        &:before {
            content: "\e90c";
        }
    }

    &.disabled {
        display: none;
    }

    @media screen and (max-width: 500px) {
        padding: 0 4px;
    }
}

.more {
    margin: 50px 0 10px
}

//.catalog .more .btn,
.newsPage .more .btn {
    border: 0;
    height: 30px;
    font-size: 13px;
    font-weight: 600;
    color: $color-blue;
    line-height: 30px;
    padding: 0 20px;
    border-radius: 4px;
    background-color: transparent;
    text-transform: none;

    @media screen {
        transition: box-shadow $time;

        &:hover {
            //color: #fff;
            background-color: #eee;
            box-shadow: 0 0 8px rgba(#000, .2);
        }
    }
}

.newsBlock .more {
    margin: 10px auto;

    .btn {
        color: $grey;

        @media screen {
            &:active,
            &:hover {
                border-color: $red;
                color: $red;
                background-color: #fff;
                outline: none;
            }
        }
    }
}

.newsPage {
    border-top: none;
    padding: 0 0 10px
}

.newsOne {
    cursor: pointer;
    font-size: 16px;
    margin: 0 0 20px;
    position: relative;

    @media screen and(max-width: 1024px) {
        .col15-lg-12 {
            padding: 0;
        }

        .col15-lg-3 {
            padding: 0;

            figure {
                display: none;
            }

            .newsSm {
                margin: 0 0 10px 15px;

                & + .allNews {
                    top: 0;
                }
            }
        }
    }
}

.newsOne h2, .newsOne h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 25px;
    text-transform: none;
    text-align: left
}

.catalog + .newsOne {
    border-top: 1px dashed #eee;
    height: 200px;
    padding: 25px 0 0;
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: 20%;

    @media screen and (max-width: 1024px) {
        margin-left: 0;
    }
}

#target {
    position: absolute;
    height: 0;
    left: 0;
    top: -45px;
    width: 100px
}

.bigNewsOne {
    cursor: default;
    font-size: 15px;
    line-height: 28px;
    padding: 0 35px 0 0;

    figure {
        margin: 0 30px 30px 0
    }

    h2 {
        font-size: 22px;
        font-weight: 600;
        margin: 0 0 10px
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 10px
    }

    img {
        float: none;
        margin: 20px 0;
        max-width: 100%;
        height: auto !important;
    }

    p {
        line-height: 28px;
        margin: 0 0 20px
    }

    ul {
        margin: 0 0 20px 40px;

        li {
            list-style: disc;
            line-height: 28px;
        }
    }

    a {
        color: $red;
        border-bottom: 1px solid rgba($red, .2);

        &:hover {
            border-bottom: rgba($red, 0);
        }
    }

    @media screen and(max-width: 1024px) {
        padding: 0 15px;
    }
}

.similarNews {
    .title {
        font-size: 15px;
        margin: 0 0 20px 15px;
        text-align: left
    }
    .newsSm {
        margin: 0 0 35px 15px;
        height: auto;

        p {
            font-size: 16px;
            margin: 15px 0 0;
            line-height: 20px;

            a {
                font-weight: 400
            }
        }
    }
}

.newsOne.page .similarNews {
    padding: 10px 0 0
}

.artThemes {
    margin: 30px 0 0;
    padding: 0 0 0 80px;

    .tellFr {
        margin: 0 0 0 -80px
    }

    a {
        border: none;
        color: #231f20;
        font-size: 13px;
        padding: 0 8px
    }
}

.allNews {
    color: $red;
    font-weight: 700;
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0 0 15px;
    line-height: 13px;
    position: relative;
    top: -15px;
    border-bottom: 1px solid rgba($red, 0);

    &:hover {
        border-bottom-color: rgba($red, .3);
    }
}

.backTo {
    border-bottom: 1px solid #231f20;
    margin: 25px 0 0;

    a {
        background: $text;
        border: none;
        color: $red;
        padding: 0 15px 0 25px;

        span {
            background: url(../../images/icons_sprite.png) 100% -1212px no-repeat;
            padding: 0 25px 0 0
        }
    }
}

.suitBlock {
    border-top: 1px solid #eee;
    margin: 40px 0;

    .title {
        margin: 40px 0 25px;
    }
}

.suitBlock .slideCounter {
    display: none
}

.tellFr {
    display: block;
    float: left;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    width: 80px
}

.social-likes__widget {
    float: left;
    margin: 0 15px 0 0
}

.social-likes__button, .social-likes__counter, .quickTell a {
    border-radius: 15px;
    display: block;
    float: left;
    margin: 0 7px 0 0;
    width: 31px;
    height: 31px;
    line-height: 28px;
    text-align: center
}

.social-likes__button, .quickTell a {
    cursor: pointer;
    text-indent: -1000px;
    overflow: hidden
}

.social-likes__counter {
    border: 2px solid #dadada;
    color: #8e8e8e;
    font-size: 10px;
    font-weight: 700
}

.social-likes__button_facebook, .quickTell .fb {
    background: #235899 url(../../images/icons_sprite.png) -366px -101px no-repeat
}

.social-likes__button_twitter, .quickTell .tw {
    background: #00c1e6 url(../../images/icons_sprite.png) -366px -139px no-repeat
}

.social-likes__button_vkontakte, .quickTell .vk {
    background: #4d769e url(../../images/icons_sprite.png) -366px -179px no-repeat
}

.social-likes__button_plusone, .quickTell .gp {
    background: #cd3238 url(../../images/icons_sprite.png) -366px -218px no-repeat
}

//.searchPage .catalogLeft {
//    padding: 54px 0 0
//}

//.searchPage .catalogLeft .filterBlock:nth-child(7) {
//    border-top: 1px solid #dfdfdf
//}

//.searchPage h1 {
//    text-transform: none
//}
//
//.searchPage h1 span {
//    font-weight: 400
//}

.searchPage .searchPopular {
    margin: 0 0 30px
}

.searchPage .searchPopular a {
    margin-bottom: 10px
}

.searchRes {
    border-right: 1px solid #231f20;
    font-weight: 700;
    padding: 0 13px 0 0;
    margin: 0 13px 0 0
}

.searchRes.empty {
    border-right: 0
}

.productOne {
    padding: 0;
    //user-select: none;

    & + .hitsBlock {
        border-top: 0;
    }

    h1 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 25px;
        text-transform: none
    }

    .sortBy {
        width: 80px !important;
        margin: 0;
        display: inline-block;
        vertical-align: baseline;
        float: none !important;

        div.dropdown-menu {
            width: 100px
        }
    }

    .salePrice ~ .sf-select {
        margin: 29px 0 0;
    }

    @media screen and (max-width: 1024px) {
        background-color: #fff;
        padding-bottom: 0;
    }
}

.sf-select {
    display: inline-block;
    position: relative;

    &:after {
        @extend %before;
        display: none;
        top: 50%;
        right: 10px;
        margin-top: -3px;
        background: url(../../images/icons_sprite.png) no-repeat -391px -675px;
        width: 9px;
        height: 5px;
    }

    @media screen and (max-width: 1024px) {
        &:after {
            display: block;
        }
        select {
            display: initial !important;
            appearance: none;
            height: 30px;
            padding: 0 8px;
            margin: 0 !important;
            background-color: #fff;
            border-color: #c5c5c5;

            & + div {
                display: none !important;
            }
        }
    }
}

.block {
    .sf-select {
        width: 100%;
    }

    .sf-tel-field {
        width: 54px;
        margin-right: -1px;

        .sft-trigger {
            width: 54px;
            height: 35px;
            border-right: 0;
        }

        .sft-list {
            top: 34px;
        }

        & + input[type="tel"] + .simplecheckout-error-text {
            padding-top: 0;
            margin: -10px 0 10px 175px;

            @media screen and (max-width: 800px) {
                margin: 0 0 10px 0;
            }
        }
    }
}


.ps-secondary,
.productBig {

    .delBuy {

        div {
            display: none;

            button {
                background-color: #fff;
                border: 1px solid #eee;
                padding: 0 25px;
                height: 48px;
                color: $dark;

                &:first-child {
                    background-color: $dark;
                    border-color: $dark;
                    color: #fff;
                    margin-right: 10px;
                }

            }

        }

        &.is-payed {
            .btnBuy,
            .fave-link {
                display: none !important;
            }

            div {
                display: block;
            }
        }

        //&.no-param {
        //    a {
        //        &.btnBuy {
        //            color: #fff !important;
        //            background-color: $text !important;
        //        }
        //    }
        //}
    }

}

.prodBigLeft {
    float: left;
    width: 390px;
    margin: 0 0 0 -390px;
    position: relative;

    @media screen and (max-width: 770px) {
        float: none;
        width: 100%;
        margin: 0 0 20px;

        .minGal {
            display: none;
        }
    }
}

.prodBigRight {
    //width: 100%;

    //@media screen and (max-width: 1100px) {
    //    margin-left: -15px;
    //    width: calc(100% + 15px);
    //}
    //
    //@media screen and (max-width: 770px) {
    //    width: 100%;
    //    margin: 0;
    //}
}

.sf-tel-field {
    display: block;
    float: left;
    width: 58px;
    margin-right: 12px;
    position: relative;

    .sft-trigger {
        position: relative;
        height: 44px;
        width: 58px;
        border: 1px solid #c5c5c5;
        background-color: #fff;
        padding: 0 20px 0 8px;

        &:after {
            font-family: $fontIcon;
            content: "\e919";
            right: 10px;
            margin-top: -11px;
            transform: rotate(-90deg);
            font-size: 10px;
            position: absolute;
            top: 50%;
        }
    }

    .sft-list {
        position: absolute;
        left: 0;
        top: 43px;
        width: 100%;
        display: none;
        padding: 10px 0;
        background: #fff;
        border: 1px solid #c5c5c5;
        border-top: 0;

        li {
            display: block;
            padding: 10px 8px;

            &.sft-selected {
                background-color: #f0f0f0;
            }

            @media screen {
                transition: background-color $time;

                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }
    }

    &.is-open {
        .sft-list {
            display: block;
        }
    }
}

.prodSlider {
    position: relative;
    height: 424px;
    margin: 0 0 18px 60px;

    .btn-svg-zoom {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0;
        border: 0;
        margin: 0;
        font-size: 60px;
    }

    @media screen and (min-width: 1025px) {
        .btn-svg-zoom {
            opacity: 0;
            visibility: hidden;
            background-color: rgba(#fff, 0);
            line-height: 100px;
            transition: opacity $time, visibility $time, background-color $time;
        }

        &:hover {
            .btn-svg-zoom {
                opacity: 1;
                visibility: visible;
                background-color: rgba(#fff, .5);
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .btn-svg-zoom {
            opacity: 1;
            visibility: visible;
            background-color: rgba(#fff, 0);

            i {
                position: absolute;
                bottom: 15px;
                right: 5px;
                font-size: 30px;
            }
        }
    }

    .slider {
        width: 100%;
        height: 100%;
    }

    .item {
        position: relative;
        //width: 362px;
        width: 100%;
        text-align: center;
        height: 424px;

        img {
            margin: 0 auto;
        }
    }

    figure {
        position: relative;
        //max-width: 362px
        text-align: center;
    }

    .easyzoom-flyout img {
        max-width: none
    }

    .resize {
        background: rgba(0, 0, 0, .15);
        color: #fff;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 38px;

        span {
            background: url(../../images/icons_sprite.png) 0 -1641px no-repeat;
            color: #fff;
            display: inline-block;
            font-size: 10px;
            line-height: 38px;
            padding: 0 0 0 28px
        }
    }

    @media screen and (max-width: 770px) {
        width: 100%;
        margin: 0;

        figure {
            max-width: 100%;
            text-align: center;

            a {
                display: inline-block;
            }
        }

        .item {
            width: 100%;
            text-align: center;
        }
    }

    @media screen and (max-width: 450px) {
        height: 300px;

        .item {
            height: 300px;
            text-align: center;

            img {
                max-height: 300px;
                width: initial;
                display: inline-block;
            }
        }
    }

}

.productBig {
    @media screen and (min-width: 800px) {
        .prodPrev {
            display: none !important;
        }
    }
}

.prodPrevP,
.prodNextP {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 100;
    border: 1px solid #fff;
    background-color: #fff;

    i {
        font-size: 30px;
        vertical-align: middle;
        position: relative;
    }
    //svg {
    //    width: 30px;
    //    height: 30px;
    //    vertical-align: middle;
    //}

    @media screen and (min-width: 1001px) {
        display: none;
    }
}

.prodNextP {
    right: 0;
}
.prodPrevP {
    left: 0;
}

//.slide-buy {
//    position: absolute;
//    bottom: 20px;
//    left: 50%;
//    transform: translateX(-50%);
//    min-width: 200px;
//    border: 0;
//    background-color: #f04040;
//    color: #fff;
//    z-index: 100;
//    font-size: 14px;
//    font-weight: 600;
//
//    @media screen and (min-width: 1001px) {
//        display: none;
//    }
//}

.prodPrev,
.prodNext {
    background-color: #fff;
    width: 36px;
    height: 36px;
    line-height: 36px;
    opacity: .7;
    right: 0;
    position: absolute;
    text-align: center;
    top: 188px;
    z-index: 50;

    i {
        font-size: 24px;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }

    svg {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        fill: $text;
        position: relative;
        top: -1px;
    }

    @media screen {
        transition: opacity $time;

        &:hover {
            opacity: 1;
        }
    }

    @media screen and (max-width: 770px) {
        background-color: #f7f7f7;

        svg {
            fill: $dark;
        }
    }

    @media screen and (max-width: 450px) {
        top: 132px;
    }
}

.prodPrev {
    //background-position: -69px -1642px;
    left: 0;
    right: auto;
}

.minGal {
    float: left;
    margin-top: 40px;
    //width: 362px;
    //height: 105px;
    width: 43px;
    height: 462px;
    //position: absolute;
    //top: 0;
    //left: 0;

    .slider {
        width: 100%;
        height: 100%
    }

    .button {
        float: left;
        width: 43px;
        height: 52px !important;
        margin: 5px 0;
        border: 2px solid transparent;
        //margin: 0 16px 0 0;
        //width: 78px;
        //height: 105px;
        //opacity: .5;
        transition: border-color $time;

        img {
            max-width: 100%;
            height: auto;
            max-height: 48px;
        }
    }

    .selected {
        //opacity: 1;
        border-color: $red;
    }

    @media screen and (max-width: 799px) {
        display: none;
    }
}

.phone-block {
    width: 100%;
    margin: 30px 0 15px;
    position: relative;
    @extend %clear;

    .sf-tel-field {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;

        .sft-trigger {
            height: 30px;
            border: 0 !important;
            background-color: transparent;
            width: 50px;
        }

        .sft-list {
            top: 25px;
            padding: 5px 0 0 0;
            background-color: #f7f7f7;
            border: 0 !important;

            .sft-selected {
                display: none;
            }
        }

    }

    input {
        float: left;

        &[type="button"]{
            width: 150px;
            padding: 0;
            height: 30px;
            border: 0;
            font-weight: 400;
            font-size: 13px;
            color: #fff;
            background-color: $dark;
            border-radius: 0 4px 4px 0;

            @media screen {
                transition: background-color $time, box-shadow $time;

                &:hover {
                    background-color: $color-blue;
                    box-shadow: 0 0 5px rgba(#000, .5);
                }
            }
        }

        &[type="tel"] {
            width: calc(100% - 150px);
            height: 30px;
            border: 2px solid #f7f7f7;
            border-right: 0;
            font-size: 13px;
            background-color: #f7f7f7;
            padding: 0 10px 0 50px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            box-shadow: none !important;
            border-radius: 4px 0 0 4px;

            @media screen {
                transition: border-color $time, background-color $time;
            }

            &:focus {
                background-color: #f7f7f7;
            }

            &.is-error {
                border-color: $red;
            }
        }

    }

}

.prodOneDescr {
    //padding: 0 240px 30px 0;
    padding: 20px 0 30px;
    text-align: center;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;

    .card-line-foot {
        @extend %clear;
        padding-top: 15px;
        border-top: 1px solid #f7f7f7;
    }

    .phone-block {
        width: 100%;
        margin: 30px 0 15px;

        @media screen and (max-width: 374px) {
            input {
                &[type="tel"] {
                    width: calc(100% - 115px);
                    padding-left: 44px;
                }
                &[type="button"]{
                    width: 115px;
                }
            }
        }
    }

    .brand a {
        font-size: 24px;
        font-weight: 400;
        border-bottom: 1px solid rgba($text, 0);
        color: $dark;

        @media screen {
            transition: color $time, border-color $time;

            &:hover {
                color: $color-blue;
                border-bottom-color: rgba($color-blue, 0);
            }
        }
    }

    .code {
        text-transform: uppercase;
        color: $grey;
        font-weight: 400;
        clear: both;
        font-size: 11px;
        letter-spacing: -.025em;

        span {
            display: none;
        }
    }

    .heading-title {
        font-size: 15px;
        color: $color-grey;
    }

    .prodOneParam {
        //border-top: 0;
        //padding-left: 0;
        //border-bottom: 0;
        padding: 15px 0;
        text-align: left;
        margin: 0;

        .prodParamTxt {
            //display: inline-block;
            //vertical-align: top;
            //margin: 0 3px 6px;
            margin: 0 0 5px;
            font-size: 12px;
            text-transform: none;
            color: $grey;
            display: block;
        }

        .filterSize {
            display: inline-block;
            margin-left: -5px;
            margin-bottom: -5px;

            .prodParamTxt {
                margin-left: 5px;
            }

            label {
                display: inline-block;
                float: none;
                vertical-align: top;
                margin: 0 0 5px 5px;
                border-radius: 4px;

                @media screen {
                    transition: background-color $time, color $time, border-color $time;

                    &:hover {
                        background-color: $color-blue;
                        border-color: $color-blue;
                        color: #fff;
                    }
                }
            }

            input:checked + label {
                background-color: $color-blue;
                border-color: $color-blue;
                color: #fff;

                &:before {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.cw-label {
                label {
                    width: auto;
                    padding: 0 7px;
                }
                input:checked + label {
                    padding-left: 20px;
                }
            }
        }

        .filterColor {
            float: none !important;

            label {
                display: inline-block;
                width: 40px;
                float: none;
                vertical-align: top;
                margin: 0 0 5px 5px;
                border-radius: 4px;

                @media screen {
                    transition: box-shadow $time;
                }
            }
        }
    }

    .delBuy {
        padding: 20px 0 0;
        border-top: 1px solid #f7f7f7;
        @extend %clear;

        a {
            display: block;
            float: left;
            width: calc(50% - 5px);
            margin: 0;
            height: 40px;
            line-height: 36px;
            border-radius: 4px;
            font-size: 15px;
            color: $grey;
            border: 2px solid #f7f7f7;
            text-align: center;

            &.btnBuy {
                margin-right: 10px;
                background-color: $color-blue;
                border-color: $color-blue;
                color: #fff;
                font-weight: 400;

                @media screen {
                    transition: box-shadow $time;

                    &:hover {
                        box-shadow: 0 0 10px rgba(#000, .5);
                    }
                }
            }

            &.fave-link {
                i {
                    font-size: 20px;
                    margin-right: 3px;
                    vertical-align: middle;
                    position: relative;
                    top: -2px;
                }
                span {
                    display: none;
                }
                @media screen {
                    transition: background-color $time, color $time;

                    &:hover {
                        background-color: #f7f7f7;
                        color: $color-blue;
                    }
                }
            }

            @media screen and (max-width: 424px) {
                    float: none;
                    width: 100%;

                    &.btnBuy {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }

            }

        }
    }

    .p-line {

        &-tablelink {
            position: absolute;
            top: 15px;
            right: 0;

            a {
                color: $dark;
                border-bottom: 1px solid rgba($dark, .3);

                @media screen {
                    transition: border-color $time, color $time;

                    &:hover {
                        border-color: rgba($color-blue, .3);
                        color: $color-blue;
                    }
                }
            }
        }

        &-delivery {
            padding-top: 0;
            color: $green;
            font-size: 13px;
            //float: left;
            margin: 0;
            line-height: 23px;

            @media screen and (max-width: 424px) {
                float: none;
                margin-bottom: 5px;
            }
        }

        &-sale {
            //float: right;
            margin: 10px 0;

            //@media screen and (max-width: 424px) {
            //    float: none;
            //}
        }

    }

    .hot {
        color: $color-red;
        width: 300px;
        margin: 0 auto;
        padding-top: 10px;
    }

}

.prodOnePrice {
    margin: 20px 0 10px;
    line-height: 1;

    .price {
        font-size: 30px;
        line-height: 1;
        float: left;
        margin: 0 25px 0 0;
        padding: 0;

        span {
            display: inline-block;
            vertical-align: baseline;
            font-size: 13px;
            margin: 0 0 0 8px;
            font-weight: 600;
        }
    }

    .salePrice {
        padding-top: 20px;
        position: relative;
        margin-right: 25px;
        font-size: 40px;
        font-weight: 600;

        span {
            position: absolute;
            right: 10px;
            bottom: 35px;
        }

        svg {
            position: absolute;
            right: -42px;
            width: 70px;
            height: 70px;
            bottom: -11px;
        }
    }
}

.bonus {
    background: url(../../../images/icons_sprite.png) -108px -375px no-repeat;
    color: #a0a0a0;
    float: left;
    display: block;
    //font-weight: 300;
    font-weight: 400;
    padding: 4px 0 0 24px
}

.quantity {
    margin: 0;
    padding: 10px 0 0;
    display: none;

    &.is-empty-q {
        text-align: center;
        padding: 0 0 10px;
    }

    &.is-show {
        display: block;
    }

    .tool-tip {
        position: relative;
        //display: inline-block;
        display: none;
        top: 2px;
        padding: 0;
        cursor: pointer;
        color: $color-blue;

        b {
            font-weight: 400;
            color: currentColor;
        }


        ins {
            display: inline-block;
            vertical-align: top;
            text-decoration: none;
            font-size: 12px;
            //font-weight: 600;
            padding: 0 5px;
            line-height: 18px;
            position: relative;

            &:before {
                @extend %before;
                top: 100%;
                left: 50%;
                width: 4px;
                margin-left: -2px;
                height: 0;
                border-bottom: 1px dotted currentColor;
            }
        }

        &-view {
            position: absolute;
            min-width: 320px;
            background-color: rgba($dark,.8);
            z-index: 10;
            color: #fff;
            font-weight: 400;
            line-height: 1.2;
            padding: 8px 10px;
            left: 50%;
            top: 100%;
            display: block;
            box-shadow: 0 0 5px rgba(#000,.5);
            transform: translateX(-50%);

            &:before {
                @extend %before;
                border: 5px solid;
                border-color: transparent transparent rgba($dark,.8) transparent;
                top: -10px;
                left: 50%;
                margin-left: -5px;
            }
        }

        &.tool-tip-7 {
            color: $green;
        }

        @media screen {
            display: inline-block;

            ins:before {
                transition: opacity $time ease-in-out .1s;
            }

            &-view {
                opacity: 0;
                visibility: hidden;
                transition: opacity $time ease-in-out .1s, visibility $time ease-in-out .1s;
            }

            b {
                &:hover {
                    ins:before {
                        opacity: 0;
                    }

                    & + .tool-tip-view {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

        }
    }
}

.prodOneParam {
    //border-top: 1px solid #ebebeb;
    //margin: 0 0 7px;
    position: relative;
    //padding: 12px 0 0 100px;

    &:not(.hidden) {
        & + .prodOneParam {
            border-top: 1px solid #ebebeb;
        }
    }

    &.choose {
        background: #fff;
        border: 1px solid #000;
        //box-shadow: 0 3px 0 rgba(0, 0, 0, .25);
        margin: -1px 0 8px -20px;
        padding: 12px 0 0 120px;
        z-index: 101;

        .tableLink {
            visibility: hidden;
        }
    }

    &:last-child {
        border-bottom: 1px solid #ebebeb;
        margin: 0 0 17px;
    }

    @media screen and (max-width: 770px) {
        &.choose {
            margin: -1px 0 8px -1px;
            padding: 12px 0 3px 115px;
        }
    }
}

.chooseSize {
    min-height: 47px
}

.prodOneParam .filterSize,
.prodOneParam .filterColor {
    margin: 0
}

.chooseError {
    position: absolute;
    left: 0;
    top: -71px;
    background: #fff;
    border: 1px solid #f15732;
    //box-shadow: 0 3px 0 rgba(0, 0, 0, .25);
    font-weight: 700;
    font-size: 16px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    display: none;

    &:after {
        @extend %before;
        border: 10px solid transparent;
        border-top-color: #fff;
        border-bottom: none;
        width: 0;
        height: 0;
        bottom: -9px;
        left: 50%;
        margin: 0 0 0 -6px;
    }
    &:before {
        @extend %before;
        border: 10px solid transparent;
        border-top-color: $red;
        border-bottom: none;
        width: 0;
        height: 0;
        bottom: -11px;
        left: 50%;
        margin: 0 0 0 -6px
    }
}

.tableLink {
    border-bottom: 1px solid rgba($text, .3);
    color: $text;
    font-size: 10px;
    text-transform: none;
    position: relative;

    @media screen {
        transition: color $time, border-color $time;

        &:hover {
            color: $red;
            border-color: rgba($red, .3);
        }
    }
}

.sizeTabBlock {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 784px;
    height: 318px;
    font-size: 15px;
    z-index: 1005;
    display: none;

    .nav-tabs {
        border-bottom: none;
        position: relative;
        z-index: 50;

        li {
            height: 52px;
            margin: 0 1px -1px 0;
            line-height: 50px;
            border-radius: 4px 4px 0 0;

            a {
                background: $color-grey;
                display: block;
                color: #fff;
                border: none;
                line-height: 51px;
                margin: 0;
                padding: 0 35px;
                border-radius: 4px 4px 0 0;

                &:hover {
                    background: $color-blue;
                }
            }

            &.active {
                border: 1px solid $color-blue;

                a,
                a:hover {
                    background: $color-blue;
                    color: #fff;
                }
            }
        }

    }

    .tab-pane {
        padding: 25px 35px;
    }

    @media screen and (max-width: 1024px) {
        top: 50px;
        display: block;
        bottom: auto;
        height: auto;
        left: 20px;
        right: 20px;
        width: calc(100% - 40px);
        margin: 0;

        .sizeTab {
            max-height: calc(100vh - 150px);
            overflow-y: scroll;
        }
    }

    @media screen and (max-width: 600px) {
        left: 10px;
        right: 10px;
        width: calc(100% - 20px);

        .nav-tabs {
            li {
                line-height: 18px;
                width: 150px;

                a {
                    line-height: 20px;
                    font-size: 14px;
                    padding: 7px 15px 4px;
                }
            }
        }

        .tab-pane {
            padding: 25px 15px;
        }
    }

    @media screen and (max-width: 420px) {
        .nav-tabs {
            li {
                line-height: 18px;
                width: 130px;

                a {
                    line-height: 20px;
                    font-size: 13px;
                    padding: 7px 12px 4px;
                }

                &:first-child {
                    width: 90px;
                }
            }
        }
    }
}

.closeTabs {
    background-color: #000;
    display: block;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 0;
    right: 0;
    text-indent: -1000px;
    overflow: hidden;
    z-index: 55;

    &:before,
    &:after {
        @extend %before;
        width: 20px;
        height: 1px;
        background-color: #fff;
        top: 50%;
        left: 50%;
        margin-left: -10px;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    @media screen {
        transition: background-color $time;

        &:hover {
            background-color: $color-blue;
        }
    }

}

//#sizeTab,
#payTab, #deliveryTab {
    border-bottom: none;
    position: relative;
    z-index: 50;

    li {
        height: 52px;
        margin: 0 1px -1px 0;
        line-height: 50px;
        border-radius: 4px 4px 0 0;

        &.active {
            //border: 1px solid #000;

            a {
                background-color: $color-blue;
                color: #fff;
            }
        }

        a {
            //background: $color-grey;
            background-color: #f7f7f7;
            display: block;
            color: $color-grey;
            border: none;
            line-height: 51px;
            margin: 0;
            padding: 0 35px;
            border-radius: 4px 4px 0 0;

            @media screen {
                transition: background-color $time, color $time;
            }
            &:hover {
                background-color: $color-blue;
                color: #fff;
            }
        }


    }

    @media screen and (max-width: 850px) {
        li a {
            padding: 0 12px;
            font-size: 12px
        }
    }

    @media screen and (max-width: 850px) {
        li a {
            padding: 0 5px;
        }
    }

}

.sizeTab, .payTab, .deliveryTab {
    border: 1px solid #000;
    background: #fff;
    position: relative;
    z-index: 40
}

.tabTitle {
    font-size: 18px;
    font-weight: 700
}

.tableSize {
    margin: 30px -35px;

    table {
        width: 100%
    }

    tr {
        &:nth-child(odd) {
            background: #ededed
        }
    }

    td {
        width: 60px;
        height: 60px;

        &:first-child {
            width: 240px;
            padding: 0 0 0 35px
        }
    }

    @media screen and (max-width: 600px) {
        margin: 30px -15px;

        td {
            font-size: 11px;

            &:first-child {
                width: 200px;
                font-size: 12px;
                padding: 0 0 0 5px;
            }
        }
    }
}

.howSize {
    font-size: 14px;
    line-height: 26px;
    padding: 0 220px 0 0;

    li {
        padding: 30px 0 0;

        &:first-child {
            padding: 0;
        }
    }

    @media screen and (max-width: 600px) {
        padding: 0;
        font-size: 13px;
    }
}

.howSizePic {
    float: right;
    width: 220px;
    margin: 0 -220px 0 0;
    padding: 45px 0 0 65px;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.hideTab {
    margin: 30px 0 -10px;

    a {
        background: url(../../images/icons_sprite.png) 0 -887px no-repeat;
        color: $text;
        font-size: 11px;
        padding: 0 0 0 17px;

        &:hover {
            color: $red
        }
    }
}

.sizeParams {
    width: 325px;
    padding: 17px 74px 0 0;

    p {
        margin: 0 0 16px
    }

    label {
        display: inline-block;
        width: 150px
    }

    input[type="text"] {
        width: 97px
    }
}

.sizeQuest {
    width: 385px;
    padding: 17px 0 0;

    input[type="text"],
    .emailInp,
    textarea {
        width: 385px;
        margin: 0 0 16px;
    }

    textarea {
        height: 162px
    }

    .btn-primary {
        height: 44px
    }
}

//.delBuy {
//    padding: 0 0 0 140px;
//    position: relative;
//
//    @media screen and (max-width: 1300px) {
//        padding-left: 0;
//    }
//}

//.prodDeliv {
//    display: block;
//    font-size: 12px;
//    line-height: 18px;
//    width: 140px;
//    height: 36px;
//    padding: 2px 0 0;
//    position: absolute;
//    top: 0;
//    left: 0;
//
//    span {
//        color: $red;
//        font-weight: 700
//    }
//
//    @media screen and (max-width: 1300px) {
//        position: relative;
//        height: initial;
//        padding: 0;
//        margin-bottom: 10px;
//        width: 100%;
//    }
//}

//.btnBuy {
//    width: calc(55% - 20px);
//    font-size: 20px;
//    font-weight: 700;
//    margin: 0 20px 0 0;
//    padding: 0;

    //@media screen and (max-width: 1100px) {
    //    width: calc(58% - 15px);
    //    font-size: 15px;
    //    margin-right: 15px;
    //}
    //
    //@media screen and (max-width: 1024px) and (min-width: 771px) {
    //    width: 300px;
    //    margin-bottom: 10px;
    //}
    //
    //@media screen and (max-width: 770px) {
    //    width: calc(58% - 15px);
    //    font-size: 15px;
    //    margin-right: 15px;
    //}

    //@media screen and (max-width: 450px) {
    //    //width: 100%;
    //    width: auto;
    //    margin-right: 0;
    //    position: fixed;
    //    //bottom: 0;
    //    //left: 0;
    //    left: 15px;
    //    bottom: 15px;
    //    right: 15px;
    //    z-index: 100;
    //    height: 50px !important;
    //    line-height: 50px;
    //    box-shadow: 0 0 5px rgba(#000, .5);
    //
    //    &.no-fixed {
    //        opacity: 0;
    //        visibility: hidden;
    //    }
    //}

//}

.oneClick {
    width: 45%;
    line-height: 34px;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
    padding: 0;

    &.choose {
        border-color: #000;
        position: relative;
        z-index: 101
    }

    @media screen and (max-width: 1100px) {
        width: 42%;
    }

    @media screen and (max-width: 1024px) and (min-width: 771px) {
        width: 300px;
    }

    @media screen and (max-width: 770px) {
        width: 42%;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
        margin-top: 15px;
    }
}

//.clickOrder {
//    border: 1px solid #000;
//    background: #fff;
//    //box-shadow: 0 3px 0 rgba(0, 0, 0, .25);
//    position: absolute;
//    bottom: -150px;
//    right: 0;
//    padding: 20px 20px 10px;
//    width: 320px;
//    z-index: 101;
//    display: none;
//
//    .btn-primary {
//        margin: 15px 0 0
//    }
//
//    &:before,
//    &:after {
//        @extend %before;
//        border: 10px solid transparent;
//        border-bottom-color: #000;
//        border-top: none;
//        width: 0;
//        height: 0;
//        top: -11px;
//        right: 50px;
//        margin: 0 0 0 -6px
//    }
//
//    &:after {
//        border-bottom-color: #fff;
//        top: -10px
//    }
//
//    input[type="tel"] {
//        width: calc(100% - 70px);
//    }
//
//    @media screen and (max-width: 450px) {
//        bottom: -130px;
//        width: 100%;
//        padding: 12px;
//
//        input[type="tel"] {
//            width: calc(100% - 70px);
//        }
//
//        .text-center {
//            margin: 0;
//        }
//
//        .btn-primary {
//            margin: 12px 0 0;
//            width: 100%;
//        }
//    }
//
//}

//.safari .clickOrder {
//    width: 333px
//}

.prodOneRight {
    width: 240px;
    margin: 0 -240px 0 0;

    @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 20px 0 0;
        margin: 0;
        float: none !important;
        clear: both;
    }
}

.prodOneAsk {
    background: #f4f4f4;
    padding: 15px 14px
}

.askLink {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;

    li {
        padding: 0 0 0 24px;
        position: relative;

        a {
            border-bottom: 1px solid rgba($text, .2);
            color: $text;

            @media screen {
                transition: color $time, border-color $time;

                &:hover {
                    border-bottom: 1px solid rgba($red, .3);
                    color: $red
                }
            }
        }
    }
}

//.fave-link {
//    svg {
//        width: 20px;
//        height: 20px;
//        fill: $text;
//        position: absolute;
//        left: 0;
//
//        &:first-child {
//            display: none;
//        }
//    }
//
//    span {
//        display: inline-block;
//        position: absolute;
//        margin-left: 4px;
//    }
//
//    &.is-active {
//        color: $red;
//        border-color: rgba($red, .3);
//
//        svg {
//            display: inline-block;
//            fill: $red;
//
//            & + svg {
//                display: none;
//            }
//        }
//    }
//
//    @media screen {
//        svg {
//            transition: fill $time;
//        }
//        &:hover {
//            svg {
//                fill: $red;
//            }
//        }
//    }
//
//}

.sf-sale-link {
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    color: $dark;
    border-bottom: 1px dashed rgba($text, .2);
    transition: border-color $time, color $time;

    span {
        font-size: 20px;
        vertical-align: bottom;
    }

    @media screen {
        &:hover {
            color: $color-blue;
            border-color: rgba($color-blue, .2);
        }
    }
}

.priceLow {
    background: url(../../images/icons_sprite.png) 0 -1251px no-repeat
}

.favour {
    background: url(../../images/icons_sprite.png) 0 -1278px no-repeat
}

.avail {
    background: url(../../images/icons_sprite.png) 0 -1304px no-repeat
}

.ask {
    background: url(../../images/icons_sprite.png) 0 -1330px no-repeat
}

.availModal {
    height: 565px
}

.colorSizeRow {
    margin: 0 0 27px
}

.askModal textarea {
    width: 100%;
    height: 80px;
    margin: 27px 0 0
}

.prodContact {
    border-top: 1px solid #dedede;
    line-height: 16px;
    padding: 10px 0 0 6px;
    margin: 13px 0 0;

    a {
        display: block;
        color: $text;
        font-size: 16px;
        font-weight: 700;
        padding: 10px 0 0;
        margin: 0;
        border: 0;

        @media screen {
            transition: color $time;

            &:hover {
                color: $red;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }

}

.prodInfo {
    border: 1px dashed #e5e5e5;
    border-top-color: #e6e6e6;
    color: #949494;
    font-size: 12px;
    line-height: 16px;
    padding: 15px 10px;

    p {
        background: url(../../images/icons_sprite.png) -250px -1412px no-repeat;
        padding: 0 0 0 48px;
        margin: 0 0 20px;
        position: relative;

        span {
            border-bottom: 1px dashed #bbb;
        }

        &:hover {
            span {
                color: $red;
                border-bottom-color: rgba($red, .3);
            }
        }

        & + p {
            background-position: -250px -1256px;

            & + p {
                background-position: -250px -1344px;
                margin: 0;
            }
        }

        &:first-child {
            padding: 13px 0 27px 48px;
            margin: 0 0 15px;
        }

        a {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 1024px) {
        p {
            max-width: 260px;
        }
    }
}

.prodAccLink {
    color: $color-blue;
    font-weight: 300;
    font-size: 16px;
    text-transform: none;
    display: block;
    padding: 16px 20px 14px 0;
    position: relative;

    &:before,
    &:after {
        @extend %before;
        top: 50%;
        background-color: $color-blue;
    }

    &:before {
        width: 2px;
        height: 10px;
        margin-top: -5px;
        right: 9px;
        opacity: 0;
        visibility: hidden;
    }

    &:after {
        width: 10px;
        height: 2px;
        right: 5px;
        margin-top: -1px;
    }

    &.collapsed {
        color: $text;

        &:before,
        &:after {
            background-color: #1d1d1d;
        }

        &:before {
            opacity: 1;
            visibility: visible;
        }
    }

    @media screen {
        transition: color $time;

        &:before,
        &:after {
            transition: background-color $time, opacity $time, visibility $time;
        }

        &:hover {
            color: $color-blue;

            &:after,
            &:before {
                background-color: $color-blue;
            }
        }
    }
}

#prodAccord,
#payAccord {
    .panel-collapse {
        color: $color-grey;

        & > div {
            padding: 10px 0 20px;
        }
    }
    .panel {
        &:first-child {
            border-top: 1px solid #ebebeb;
        }
    }
}

.collapse-subhead {
    color: $text;
    font-weight: 700;
    font-size: 18px;
    margin: 27px 0 17px;
    text-align: center;
    text-transform: uppercase;
}

.comments {
    border-top: 1px solid #fff;
    margin: -1px 0 0
}

.commentOne {
    border-top: 1px solid #ebebeb;
    padding: 28px 0 0 0;
    margin: 20px 0 0;
    position: relative
}

.voteBlock {
    position: absolute;
    top: 30px;
    right: 0;
    color: #949494;
    height: 20px;
    line-height: 16px
}

.voteDots {
    margin: 1px 10px 0 0;

    a {
        border: 1px solid #b9b9b9;
        border-radius: 5px;
        display: inline-block;
        width: 11px;
        height: 11px;
        margin: 0 1px;
        line-height: 11px;
        cursor: pointer;

        &:hover {
            border-color: #f15732;
            background: #f15732
        }
    }

    .sel {
        border-color: #f15732;
        background: #f15732
    }

}

.comments .commentOne:first-child {
    border-top: none;
    margin: 0;
    padding-top: 10px
}

.comments .commentOne:first-child .voteBlock {
    top: 13px
}

.commUser {
    float: left;
    width: 74px;
    margin: 0 0 0 -74px;
    display: none;

    a {
        display: block;
        width: 47px;
        height: 47px;
        border-radius: 23px;
        overflow: hidden
    }

    img {
        width: 47px
    }
}

.commBlock {
    float: left;
    color: #3e3b3c;

    .name {
        color: #231f20;
        font-size: 12px;
        font-weight: 700
    }
}

.commInfo {
    font-size: 12px;

    .date {
        color: #949494;
        float: left;
        margin: 0 11px 0 0
    }

    .vote {
        color: #f15732;
        font-weight: 700;
        float: left;
        margin: 0 17px 0 0;
        padding: 0 24px;
        text-align: center;
        position: relative;
        min-width: 73px
    }

    .plus,
    .minus {
        background: url(../../images/icons_sprite.png) -188px -1651px no-repeat;
        border: none;
        width: 19px;
        height: 19px;
        position: absolute;
        left: 0;
        top: 0;
        text-indent: -1000px;
        overflow: hidden
    }

    .minus {
        background-position: -234px -1651px;
        left: auto;
        right: 0
    }

    .answer {
        color: #231f20;
        font-weight: 700;

        &:hover {
            color: #f15732
        }
    }

    .rate {
        background: url(../../images/icons_sprite.png) -265px -1653px no-repeat;
        color: #f15732;
        font-weight: 700;
        padding: 0 0 0 15px
    }

}

.addCommentForm {
    border-top: 1px solid #ebebeb;
    padding: 20px 0;

    p {
        font-weight: 700;
        margin: 0 0 20px
    }

    textarea {
        background: #f0f0f0;
        border: none;
        height: 89px;
        width: 100%;
        margin: 0 0 10px
    }

    .btn {
        border-color: #f15732
    }

}

.addComm {
    margin: 0 0 21px;

    input[type="text"] {
        background: #f0f0f0;
        border: none;
        height: 37px;
        width: 330px;
        margin: 0 25px 0 0
    }

    span {
        line-height: 37px;
        margin: 0 10px 0 0;
        float: left
    }

    .voteDots {
        margin: 9px 0 0
    }
}

.addCommentForm input[type="text"]::-webkit-input-placeholder, .addCommentForm textarea::-webkit-input-placeholder {
    color: #231f20
}

.addCommentForm input[type="text"]::-moz-placeholder, .addCommentForm textarea::-moz-placeholder {
    color: #231f20
}

.addCommentForm input[type="text"]:-moz-placeholder, .addCommentForm textarea:-moz-placeholder {
    color: #231f20
}

.addCommentForm input[type="text"]:-ms-input-placeholder, .addCommentForm textarea:-ms-input-placeholder {
    color: #231f20
}

.orderPage {

    .viewedSlider {
        position: relative;
        z-index: 5 !important;
    }

    .viewedBlock {
        display: none !important;
        border-top: 1px solid #eee;
        position: relative;

        &:before {
            @extend %before;
            top: 76px;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            bottom: 0;
            background-color: #f7f7f7;
            z-index: 2;
        }

        .title {
            text-transform: none;
            font-weight: 400;
            font-size: 18px !important;
            margin: 35px 0 20px !important;
        }

        .slideCounter {
            padding-right: 100px !important;
            top: 33px !important;
        }

        @media screen and (max-width: 400px) {
            .slideCounter {
                display: none;
            }
        }

        @media screen and (max-width: 800px){
            margin-left: -15px;
            margin-right: -15px;

            .title {
                padding-left: 15px;
            }
        }
    }
}

//.viewedBlock {
//    //border-top: 1px solid #231f20;
//    border-top: 0;
//    position: relative;
//    margin: 0 0 30px;
//
//    .slideCounter {
//        top: 0;
//    }
//
//    .iosslider {
//        //padding-top: 4px;
//        //height: 216px;
//        min-height: 384px;
//        padding: 1px 0;
//        background-color: #eee;
//    }
//
//    .product5 {
//        float: left;
//        width: calc(20% - 1px);
//        margin: 0 1px 0 0;
//        height: auto;
//        -webkit-backface-visibility: visible !important;
//        overflow: visible !important;
//        z-index: 5;
//
//        &:before {
//            top: 0 !important;
//            left: 0 !important;
//            right: 0 !important;
//            bottom: 0 !important;
//            background-color: #fff;
//            border: 0 !important;
//        }
//    }
//
//    @media screen and (max-width: 1300px) {
//        .product5 {
//            width: calc(25% - 1px);
//        }
//    }
//
//    @media screen and (max-width: 1200px) {
//        margin: 0 -15px;
//
//        &:after,
//        &:before {
//            display: none;
//        }
//
//        .slideCounter {
//            right: 15px;
//        }
//    }
//
//    @media screen and (max-width: 950px) {
//        .product5 {
//            width: calc((100% / 3) - 1px);
//        }
//    }
//
//    @media screen and (max-width: 700px) {
//        .iosslider {
//            min-height: 339px;
//        }
//        .product5 {
//            width: calc(50% - 1px);
//            height: 338px;
//        }
//    }
//
//    @media screen and (max-width: 374px) {
//
//        .iosslider {
//            height: 229px;
//            min-height: 230px;
//        }
//
//        .product5-thumb {
//            display: block;
//            text-align: center;
//            line-height: 150px;
//            height: 150px;
//
//            img {
//                max-height: 150px;
//                width: initial;
//                height: auto;
//                max-width: 100%;
//            }
//        }
//
//        .product5 {
//            height: 230px;
//        }
//    }
//
//}

.product9 {
    float: left;
    width: calc((100% / 9) - 0px);
    margin: 0 1px 0 0;
    height: auto;
    z-index: 5;
    position: relative;

    &:before {
        @extend %before;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: #fff;
    }

    .product9-thumb {
        height: 150px;
        line-height: 150px;
        text-align: center;
        display: block;

        img {
            display: inline-block;
            vertical-align: middle;
            max-height: 100%;
            max-width: 100%;
        }
    }

    figure {
        margin: 0 0 10px;
    }

    .brand {
        color: #8e8e8e;
        font-weight: 600;
        font-size: 11px;
        margin: 0;
        position: absolute;
        top: 129px;
        left: 0;
        background-color: #fff;
        z-index: 3;

        a {
            color: #8e8e8e;
            padding: 2px 5px;
            border: 1px solid #eee;
            border-left: 0;

            &:hover {
                color: $red;
            }
        }
    }

    .price {
        text-align: center;
        padding: 16px 0;

        &.salePrice {
            padding: 0 0 10px;
        }

        span {
            display: block;
            margin: 0;
        }
    }

    figure,
    .price {
        position: relative;
        z-index: 2;
    }

    @media screen and (max-width: 1300px) {
        width: calc((100% / 8) - 0px);
    }
    @media screen and (max-width: 1200px) {
        width: calc((100% / 7) - 0px);
    }
    @media screen and (max-width: 1024px) {
        width: calc((100% / 6) - 0px);
    }
    @media screen and (max-width: 900px) {
        width: 20%;
    }
    @media screen and (max-width: 700px) {
        width: 25%;
    }
    @media screen and (max-width: 600px) {
        width: calc((100% / 3) - 0px);
    }
    @media screen and (max-width: 450px) {
        width: 50%;
    }
}

.about-store {
    //text-align: center;
    //margin-left: -15px;
    //margin-right: -15px;
    //background: url(../../img/about-us.jpg) center no-repeat fixed;
    //background-size: cover;
    //padding: 70px 0;
    //position: relative;
    display: none;

    &:before {
        @extend %before;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($dark, .5);
        z-index: 1;
    }

    img {
        max-width: 100%;
        width: 100%;
        min-height: 50px;
    }

    p {
        color: #fff;
        max-width: 500px;
        padding: 0 15px;
        margin: 0 auto;
        font-size: 15px;
        position: relative;
        z-index: 5;

        & + p {
            margin-top: 20px;
        }
    }
}

.mapBigBlock {
    //margin: 0 0 25px;
    @extend %tabl;
    width: calc(100% + 30px);
    margin: 0 0 0 -30px;

    img {
        width: 100%;
        height: 350px;
        object-fit: cover;
    }

    //img {
    //    max-width: 100%;
    //    width: 100%;
    //    min-height: 50px;
    //}
    //
    //figure {
    //    img {
    //        visibility: hidden;
    //        max-height: 100%;
    //        width: initial;
    //    }
    //}

    .col-lg-6 {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 30px);
        float: none;
        margin-left: 30px;
        //height: 100%;
        padding: 0;
        overflow: hidden;
        min-height: 350px;
        max-height: 350px;
    }

    @media screen and (max-width: 1024px) {
        //margin: 0 -15px -20px;

        .col-lg-6 {
            display: none;
            //margin: 0;
            //width: 100%;
            //min-height: 300px;
            //max-height: 300px;
            //
            //& + .col-lg-6 {
            //    display: none;
            //}
        }

        //&.map-on-product {
        //    .col-lg-6 {
        //        margin: 0;
        //        width: 100%;
        //        min-height: 300px;
        //        max-height: 300px;
        //        display: none;
        //
        //        & + .col-lg-6 {
        //            display: block;
        //        }
        //    }
        //}
    }
}

.advTxt {
    //width: 589px;
    position: relative;
    background: url(../../images/pic_about.jpg) 0 0 no-repeat;
    background-size: cover;

    @media screen and (min-width: 581px) {
        height: 350px;
    }

    .bgOverlay {
        background: rgba(0, 0, 0, .56);
        color: #fff;
        font-size: 16px;
        text-align: center
    }

    .child {
        width: 77%
    }

    .site {
        font-size: 26px;
        font-weight: 700;
        margin: 0 0 25px
    }
}

.viewedFix {
    display: none !important;
}

.product-product {
    .advTxt {
        background: url(../../images/pic_about.jpg) center no-repeat fixed;
        background-size: cover;

        .child {
            max-width: 550px;
            padding: 0 15px;
            width: 100%;
        }
    }

    .mapBigBlock {
        margin: 0;
        width: 100%;
        display: block;
        @extend %clear;
    }

    .viewedBlock {
        .title {
            display: none;
        }
    }

    .hitsBlock {
        .title {
            display: none;
        }
    }

    @media screen and (max-width: 580px) {
        .advTxt {
            background-attachment: scroll;
        }

        .bgOverlay {
            position: relative;
            top: auto;
            left: auto;
            height: 100%;
            padding: 40px 0 10px;

            img {
                min-height: 50px;
            }
        }
    }
}

.contactPage {
    h2 {
        font-size: 22px;
        font-weight: 300;
        text-align: left;
        text-transform: none;
        margin: 0 0 15px;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .whenCall {
        display: none;
    }
}

.contactBlock {
    margin: 32px 0 22px;

    @media screen and (max-width: 1024px) and (min-width: 871px) {
        .col-lg-6 {
            width: 350px;
            float: left;
            margin: 0 0 40px;

            & + .col-lg-6 {
                width: calc(100% - 370px);
                margin-left: 20px;
                margin-top: 0;
            }

            .col-lg-6 {
                width: 100%;
                float: none;
                margin: 0;

                & + .col-lg-6 {
                    width: 100%;
                    margin: 0;
                }
            }

            .callRequest {
                margin-bottom: 30px;
                margin-top: -30px;
                margin: -30px -15px 40px -15px;
            }
        }
    }

    @media screen and (max-width: 870px) {
        .col-lg-6 {
            width: 100%;

            & + .col-lg-6 {
                width: 100%;
                clear: both;
                margin-top: 30px;
            }

            .col-lg-6 {
                width: calc(50% - 10px);
                float: left;
                margin: 0;

                & + .col-lg-6 {
                    width: calc(50% - 10px);
                    clear: none;
                    margin: 0 0 0 20px;
                }
            }

        }
    }

    @media screen and (max-width: 500px) {
        .col-lg-6 {
            float: none !important;
            width: 100% !important;
            margin: 0 0 20px !important;
        }
    }
}

.contactInfo {
    font-size: 16px;
    @extend %clear;

    .title {
        font-size: 14px;
        margin: 20px 0 10px;
        text-align: left;
        text-transform: none;
    }

    //.mail {
        //background-position: 2px -277px
    //}
}

.callRequest {
    border: 1px dashed #dfdfdf;
    padding: 20px 50px;
    margin-left: 0;
    margin-bottom: 30px;
    margin-top: -30px;
    float: none !important;

    .callbackForm {
        @extend %clear;
    }

    p {
        font-size: 15px;
        font-weight: 300;
        margin: 0 0 20px;
        text-align: center;
        color: $color-grey;

        &.text-center {
            font-size: 22px;
            text-transform: none;
            margin: 10px 0 20px;
            color: $color-text;

            &:first-letter {
                text-transform: uppercase;
            }
        }
    }

    .name {
        margin: 0 0 19px;
        width: 100%;
    }

    .btn-primary {
        background-color: $color-blue;
        border-color: $color-blue;
        color: #fff;
        border-radius: 4px;
        line-height: 34px;
        transition: box-shadow $time;

        &:active,
        &:hover {
            background-color: $color-blue;
            border-color: $color-blue;
            color: #fff;
            box-shadow: 0 0 5px rgba(#000, .5);
        }

    }

    input[type="tel"] {
        width: calc(100% - 70px - 75px);
    }

    @media screen and (max-width: 1200px) {
        padding: 20px 30px;
    }

    @media screen and (max-width: 850px) {
        margin-left: -15px;
        margin-top: 0;
    }

    @media screen and (max-width: 500px) {
        padding: 20px 15px;

        .formPhone {
            label {
                display: block;
                float: none !important;
                line-height: 26px;
            }
            input[type="tel"] {
                width: calc(100% - 70px);
                margin-bottom: 20px;
            }
        }

        .btn-primary {
            width: 100%;
            float: none !important;
            margin-top: 20px;
        }
    }

}

.whenCall {
    line-height: 37px;
    font-weight: 700;
    border-bottom: 1px dashed #231f20;
    color: #231f20;

    &:hover {
        color: #f15732;
        border-bottom-color: #f15732
    }
}

.orderPage #content {
    padding-top: 64px;

    @media screen and (max-width: 1024px) {
        padding-top: 51px;

        .sf-sub-nav {
            display: none;
        }
    }
}

//.topBlock {
//    border-bottom: 1px solid #ebebeb;
//    padding: 0 0 19px
//}
//
//.topBlock .category {
//    display: block;
//    font-size: 10px;
//    color: #c3c3c3;
//    font-weight: 700
//}
//
//.topBlock .phones {
//    background: url(../../images/flags/ua.png) 0 7px no-repeat;
//    padding: 0 0 0 25px;
//    margin: 0
//}
//
//.topPhone {
//    margin: 0 -15px 0 -5px
//}

.thanksOrder {
    padding: 15px 0 30px;
    background-color: #fff;
}

.thanksLeft {
    //min-width: 400px;
    padding: 30px 0;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 30px 0;
    }
}

.thanksTop {
    margin: 0 0 25px;

    p {
        font-weight: 600;
        font-size: 16px;
        //padding: 0 0 0 30px;
        position: relative;
        line-height: 1.2;

        //&:before {
        //    display: block;
        //    position: absolute;
        //    top: 2px;
        //    left: 0;
        //    font-family: $fontIcon;
        //    content: "\e5ca";
        //    font-size: 20px;
        //    color: $green;
        //}

        span {
            color: $green;
            white-space: nowrap;
            font-weight: 600;
            font-size: 20px;
        }
    }
}

.print {
    background: url(../../images/icons_sprite.png) 0 -1742px no-repeat;
    color: #f15732;
    padding: 0 0 0 25px;

    span {
        border-bottom: 1px solid rgba(241, 87, 50, .3)
    }
}

.consAnswer {
    font-size: 14px;
    line-height: 20px
}

.orderStatus {
    //border: 1px dashed #ccc;
    font-size: 14px;
    //padding: 15px 20px;
    margin: 0 0 40px;
    position: relative;

    //i {
    //    position: absolute;
    //    top: 0;
    //    font-size: 36px;
    //    line-height: 70px;
    //}
    //
    //svg {
    //    width: 40px;
    //    height: 40px;
    //    position: absolute;
    //    top: 10px;
    //    left: 10px;
    //}

    a {
        color: $color-red;
        border-bottom: 1px solid rgba($color-red, .3);
        font-size: 12px;

        @media screen {
            &:hover {
                color: $color-blue;
                border-bottom: 1px solid rgba($color-blue, .3);
            }
        }
    }

    p {
        //background: url(../../images/icons_sprite.png) 0 -1794px no-repeat;
        line-height: 37px;
        margin: 0;
        padding: 0 0 0 50px
    }

    .num {
        background: rgba($red, .9);
        border-radius: 11px;
        display: block;
        position: absolute;
        top: 10px;
        left: 44px;
        color: #fff;
        font-size: 10px;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px
    }

}

//.getSale {
//    background: #f3f3f3;
//    position: relative;
//    padding: 25px 300px 23px 26px;
//    margin: 0 0 29px
//}
//
//.getSale p {
//    font-size: 20px;
//    font-weight: 700
//}
//
.copyCode span {
    background: #fff;
    border: 1px solid #e7e7e7;
    border-right: none;
    color: #cecdce;
    display: block;
    float: left;
    font-size: 16px;
    height: 44px;
    line-height: 42px;
    padding: 0 0 0 13px;

    input[type="text"] {
        border-color: #e7e7e7;
        border-left: none;
        border-right: none;
        font-size: 16px;
        width: 223px;
        float: left
    }

    input[type="submit"] {
        height: 44px;
        float: left
    }
}

.saleMan {
    background: url(../../images/girl.png) 0 0 no-repeat;
    display: block;
    width: 180px;
    height: 187px;
    position: absolute;
    bottom: 0;
    right: 42px
}

.socGroups {
    //border-bottom: 5px solid #ebebeb;
    border-bottom: 0;
    font-size: 18px;
    padding: 0;
    margin: 0 0 40px;
    line-height: 32px;

    & > span {
        display: block;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .socnet {
        padding: 0 0 0 10px;
        overflow: hidden;

        @media screen and (max-width: 535px) {
            clear: both;
            padding: 10px 0 0 0;
        }

        li {
            display: inline;
        }

        a {
            width: 32px;
            height: 32px;
            margin-right: 5px;
            border: 1px solid rgba($grey,.2);
            text-align: center;
            line-height: 32px;
            font-size: 16px;
            color: $grey;
            display: inline-block;

            @media screen {
                transition: color $time ease-in-out .1s;

                &:hover {
                    color: $text;
                }
            }
        }
    }

    //ul {
    //    float: left;
    //    margin: 0 0 0 10px
    //}
    //
    //li {
    //    float: left;
    //    margin: 0 10px 0 0
    //}
    //
    //a {
    //    border-radius: 20px;
    //    display: block;
    //    width: 32px;
    //    height: 32px;
    //    line-height: 32px;
    //    text-align: center;
    //    position: relative;
    //
    //    svg {
    //        vertical-align: middle;
    //        position: relative;
    //        top: -2px;
    //    }
    //    //background: url(../../images/icons_sprite.png) no-repeat;
    //
    //    &.fb {
    //        background-color: #3a589b;
    //        background-position: -275px -1731px;
    //    }
    //
    //    &.vk {
    //        background-color: #587ea3;
    //        background-position: -322px -1731px;
    //    }
    //
    //    &.inst {
    //        background-color: #d7ba8c;
    //        background-position: -372px -1731px;
    //    }
    //}

    @media screen and (max-width: 600px) {
        border: 0;
        margin: 0;
        padding: 0;
    }

}

.thanksQuiz {
    max-width: 400px;
    margin: 0 auto;

    & > p {
        font-size: 14px;
        font-weight: 600;
        margin: 0 0 30px;
    }
    div {
        margin: 0 0 15px;
        @extend %clear;

        &:nth-child(1),
        &:nth-child(2) {
            .question {
                line-height: 34px;
            }
            p {
                padding: 0;
            }
        }

        p {
            padding: 10px 0;
        }
    }
    .question {
        display: block;
        //width: 210px;
        //float: left
    }
    label {
        display: inline-block;
        //background: url(../../images/icons_sprite.png) 0 -1963px no-repeat;
        color: rgba($dark, .8);
        cursor: pointer;
        font-size: 13px;
        padding: 0 0 0 22px;
        margin: 0 15px 0 0;
        position: relative;
        line-height: 30px;

        &:before {
            @extend %before;
            width: 12px;
            height: 12px;
            border: 1px solid rgba($grey,.2);
            background-color: rgba($grey,.2);
            border-radius: 7px;
            top: 50%;
            left: 0;
            margin-top: -7px;
        }

        &:after {
            @extend %before;
            width: 6px;
            height: 6px;
            background-color: $dark;
            border-radius: 5px;
            top: 50%;
            left: 3px;
            margin-top: -4px;
            opacity: 0;
        }
    }
    input[type="text"],
    input[type="email"] {
        min-width: 300px;
        height: 34px;
        border-color: #efefef;
    }
    input:checked + label {
        //background-position: 0 -1989px
        &:after {
            opacity: 1;
        }
    }
    textarea {
        border: 1px solid #efefef;
        width: 100%;
        height: 53px;
        margin: 0 0 16px;
        padding: 5px 10px
    }
    input[type="submit"] {
        height: 40px;
        line-height: 38px;
        border-radius: 4px;
        border: 1px solid $color-blue;
        background-color: $color-blue;
        color: #fff;

        &:hover,
        &:active {
            border-color: $color-blue;
            background-color: $color-blue;
            color: #fff;
            box-shadow: 0 0 5px rgba(#000, .5);
        }
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.checkout-success {
    .footerFormSign,
    .p-card-list,
    .sf-footer {
        display: none;
    }

    h1 {
        opacity: 0;
    }
}

.thanksMap {
    float: left;
    width: 35%;
    margin: 0;

    .map {
        //background: url(../../images/mapSm.jpg) 50% 0 no-repeat;
        //min-height: 300px;
    }

    img {
        max-width: 100%;
    }

    @media screen and (max-width: 800px) {
        display: none;
    }
}

.thanksPayDel {
    color: #949494;
    font-size: 12px;
    line-height: 16px;
    padding: 30px;

    div {
        border-top: 1px solid #eee;
        margin: 0 0 30px;
        padding: 25px 0 0 40px;

        &:first-child {
            border-top: none;
            padding: 0 0 0 40px;
        }
    }

    p {
        padding: 0 0 0 40px;
        line-height: 22px;
        margin-left: -40px;

        a {
            color: #231f20;
            border-bottom: 1px solid rgba(35, 31, 32, .2);
            font-size: 10px;
            font-weight: 700
        }
    }

    .arrow {
        color: #f15732;
        font-size: 14px;

        &:hover {
            color: #231f20
        }
    }
}


.pay,
.del {
    p {
        position: relative;
        background: transparent;

        &:before {
            font-family: $fontIcon;
            font-size: 26px;
            position: absolute;
            left: 0;
            color: $red;
        }
    }
}

.pay p {
    &:before {
        content: "\e90f";
    }
}

.del p {
    &:before {
        content: "\e91f";
    }
}

.payPage {
    padding: 0 300px 35px 0;

    @media screen and (max-width: 1024px) {
        padding-right: 0;
    }

}

.payPageLeft {
    float: left;
    padding: 0 25px 0 0;
    width: 100%;

    p {
        font-size: 14px;
        margin-bottom: 20px;

        & + .deliveryPageLeft {
            margin-top: 30px;
        }
    }

    .panel p {
        margin: 15px 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    @media screen and (max-width: 1024px) {
        float: none;
        padding-right: 0;
    }

}

#payTab,
#deliveryTab {
    margin: 10px 0 0;

    li {
        margin-bottom: -1px;

        &.active {
            border: 0;
            border-bottom: 1px solid $color-blue;
        }
    }
}

.payTab,
.deliveryTab {
    border: 1px solid #ccc;
    margin: 0 0 40px;

    .tab-pane {
        padding: 30px;
        font-size: 15px;
        line-height: 29px;

        ul {
            border-top: 1px solid #ccc;
            margin: 35px -30px -30px;
            height: 65px;

            li {
                border-left: 1px solid #ccc;
                font-size: 11px;
                font-weight: 700;
                height: 65px;
                line-height: 20px;

                &:first-child {
                    border-left: none
                }

                div > span {
                    display: inline-block;
                    height: 65px
                }

                .nova {
                    background: url(../../images/icons_sprite.png) 0 -2098px no-repeat;
                    padding: 0 0 0 50px
                }

                .yourself {
                    background: url(../../images/icons_sprite.png) 0 -2046px no-repeat;
                    padding: 0 0 0 42px
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .col-lg-6 {
            width: 50%;
            float: left;
        }
    }

    @media screen and (max-width: 850px) {
        margin: 0;

        .col-lg-6 {
            float: none;
            width: 100%;
        }

        .tab-pane {
            padding: 15px;

            ul {
                margin-left: -15px;
                margin-right: -15px;
                margin-bottom: -15px;
                height: auto;

                li {
                    border-left: 0;

                    &:first-child {
                        border-bottom: 1px solid #ccc;
                    }
                }

                div {
                    text-align: left;
                    padding-left: 15px;

                    span {
                        line-height: 65px;

                        .child {
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }

}

.deliveryTab .tab-pane {
    min-height: 176px
}

.payPage h2 {
    font-size: 20px;
    margin-bottom: 25px;
    text-align: left;
    text-transform: none
}

#payAccord {
    font-size: 15px;
    margin: 0 0 100px;

    @media screen and (max-width: 1024px) {
        margin: 0;
    }
}

//#payAccord .prodAccLink {
//    text-transform: none
//}

//#payAccord .panel-collapse > div {
//    padding: 20px 0
//}

.payPage .getSale {
    font-size: 14px;
    margin: 0
}

.formQuestion {
    border: 2px solid #e7e7e7;
    border-top: none;
    padding: 27px;
    margin: 0 0 30px
}

.formQuestion textarea {
    width: 100%;
    height: 61px;
    margin: 0 0 17px;
    padding: 5px 11px
}

.formQuestion .btn {
    border-color: #f15732;
    color: #f15732;
    font-size: 14px;
    height: 40px;
    text-transform: none;

    @media screen {
        &:hover {
            color: #fff
        }
    }
}

.btnCat {
    border-color: #000;
    padding: 0 15px
}

.no-viewed .basketProd {
    opacity: 0;
    visibility: hidden;
}

//.basketProd > p,
//.payProducts > p {
//    font-weight: 300;
//    font-size: 22px;
//    margin-bottom: 20px
//}

.basketProd .btnBuy {
    margin-top: 10px
}

.basketProdOne {
    border: 1px solid #ebebeb;
    margin: 0 0 20px;
    padding: 10px;

    figure {
        width: 100%;;
        margin: 0 0 15px;

        img {
            max-width: 100%
        }
    }

    .name {
        color: #484848;

        &:hover {
            color: $red
        }
    }

    .price {
        margin: 10px 0 15px;
        padding: 0 0 10px;
    }

    label {
        color: #231f20;
        float: left;
        font-size: 11px;
        width: auto;
        margin: 0 10px 0 0;
        text-transform: uppercase
    }

    .selColor .btn,
    .selSize .btn {
        border: none;
        padding: 0;
        height: 20px;
        line-height: 20px;

        &.btn-group .btn .caret {
            right: 0
        }

        div.dropdown-menu {
            margin: 4px 0 0 -12px;
            width: 90px
        }
    }

    .selColor {
        .color {
            width: 52px
        }
    }

    .selSize {
        .size {
            background: #fff;
            border: 1px solid #dfdfdf;
            color: #231f20;
            display: block;
            width: 52px;
            height: 20px;
            text-align: center;
            font-size: 11px
        }

        .dropdown-menu > li > a {
            height: 32px;
            line-height: 18px;
            padding: 6px 20px 6px 11px
        }

    }

    .btnBuy {
        width: 100%;
        margin: 20px 0 0
    }
}

.basketTable .selColor .btn,
.basketTable .selSize .btn {
    border: none;
    padding: 0;
    height: 20px;
    line-height: 20px
}

.payPageRight {
    float: right;
    width: 300px;
    margin: 0 -300px 0 0;
    padding: 0;

    .recent-view {
        margin-left: 0;
    }


    //.btn-primary {
    //    height: 40px;
    //    line-height: 38px;
    //    border-radius: 4px;
    //    border: 1px solid $color-blue;
    //    background-color: $color-blue;
    //    color: #fff;
    //
    //    &:hover,
    //    &:active {
    //        border-color: $color-blue;
    //        background-color: $color-blue;
    //        color: #fff;
    //        box-shadow: 0 0 5px rgba(#000, .5);
    //    }
    //}

    @media screen and (max-width: 1024px) {
        display: none;
    }

}

//.payProducts {
//    figure {
//
//        a {
//            display: block;
//            line-height: 180px;
//            height: 180px;
//            text-align: center;
//        }
//
//        img {
//            display: inline-block;
//            max-width: 100%;
//            max-height: 100%;
//            width: initial;
//            vertical-align: middle;
//        }
//    }
//
//    ul {
//        @extend %tabl;
//
//        li {
//            display: inline-block;
//            vertical-align: top;
//            position: relative;
//            width: 50%;
//            margin: 0 0 -1px -1px;
//            border: 1px solid #ebebeb;
//        }
//
//        & + .btnBuy {
//            width: calc(100% - 1px);
//            margin: 20px 0;
//        }
//
//    }
//
//    .brand {
//        color: #8e8e8e;
//        font-weight: 600;
//        font-size: 11px;
//        margin: 0;
//        position: absolute;
//        top: auto;
//        bottom: 55px;
//        left: 0;
//        background-color: #fff;
//        z-index: 3;
//
//        a {
//            color: #8e8e8e;
//            padding: 2px 5px;
//            border: 1px solid #eee;
//            border-left: 0;
//
//            &:hover {
//                color: $red;
//            }
//        }
//    }
//
//    .price {
//        padding: 16px 0;
//        text-align: center;
//
//        span {
//            display: block;
//        }
//
//        &.salePrice {
//            padding: 0 0 10px;
//        }
//    }
//
//}

.basketPage {
    padding: 0;
    background-color: #fff;
    position: relative;

    @media screen and (min-width: 1025px) {
        min-height: calc(100vh - 65px);
    }

    .basket-block {
        @extend %clear;

        &:before {
            @extend %before;
            top: 0;
            bottom: 0;
            width: 2px;
            height: 100%;
            right: 451px;
            background-color: #eee;
        }

        @media screen and (max-width: 1200px) {
            &:before {
                right: 401px;
            }
        }

        @media screen and (max-width: 1024px) {
            &:before {
                display: none;
            }
        }
    }

    &[data-user="is-logged"]{

    }
}

.basketLeft {
    //float: left;
    //width: calc(100% - 450px);
    float: right;
    width: 450px;
    padding: 20px 30px 0;
    max-height: calc(100vh - 65px);
    overflow-y: scroll;

    .button-order-view {
        display: none;
        padding: 0 30px 0 15px;
        height: 36px;
        border: 1px solid #eee;
        font-size: 13px;
        border-radius: 4px;
        background-color: #fff;
        //background: transparent;
        position: absolute;
        top: -41px;
        right: 0;
        z-index: 1000;

        &:before {
            @extend %before;
            top: 50%;
            right: 10px;
            margin-top: -3px;
            background: url(../../images/icons_sprite.png) no-repeat -391px -675px;
            width: 9px;
            height: 5px;
        }

        span {
            font-size: 14px;
            font-weight: 700;
            margin-left: 5px;
        }

    }

    @media screen and (max-width: 1200px) {
        width: 400px;
        padding-right: 0;
        padding-left: 20px;
        overflow-x: hidden;
    }

    @media screen and (max-width: 1024px) {
        float: none;
        width: 100%;
        padding-left: 0;
        padding-top: 0;
        max-height: initial;
        overflow: initial;

        .basket-table-order {
            display: none;
            margin-top: 30px;

            .basketTable {
                margin-bottom: 20px;
            }
        }

        .button-order-view {
            display: initial;
            top: -44px;

            &.is-active {
                top: -74px;

                & + form div .basket-table-order {
                    display: block;
                }
            }
        }

    }

    @media screen and (max-width: 599px) {
        .button-order-view {
            position: relative;
            width: 100%;
            text-align: left;
            top: 15px !important;
            z-index: 99;
        }
    }


}

.basketRight {
    float: left;
    //width: 450px;
    width: calc(100% - 450px);
    display: block;
    //padding-bottom: 70px;

    .btn-primary {
        font-weight: 700;
        height: 48px;
        background-color: $dark;
        color: #fff;
    }

    @media screen and (max-width: 1200px) {
        //width: 400px;
        width: calc(100% - 400px);
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        float: none;
        //margin-bottom: 130px;
    }

    @media screen and (max-width: 570px) {
        //padding-bottom: 110px;
    }
}

.form-pay {
    position: relative;
    //margin-bottom: 20px;
    @extend %clear;

    .form-pay-submit {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        padding: 20px 30px 20px 180px;
        text-align: left;
        border-top: 2px solid #eee;
        z-index: 1;

        a {
            display: inline-block;
            vertical-align: top;
            height: 48px;
            line-height: 48px;
            border: 1px solid #eee;
            color: #8a8a8a;
            margin-left: 15px;
            text-align: center;
            min-width: 180px;
            //color: $red;
            //border-bottom: 1px dashed rgba($red, .3);
            //margin-right: 20px;

            @media screen {
                transition: color $time, border-color $time;
                &:hover {
                    color: $dark;
                    border-color: $dark;
                }
            }
        }
    }

    @media screen and (max-width: 1260px) {
        .form-pay-submit {
            padding-left: 90px;
        }
    }
    @media screen and (max-width: 1070px) {
        .form-pay-submit {
            padding-left: 70px;
        }
    }

    @media screen and (max-width: 1024px) {
        .form-pay-submit {
            padding-left: 80px;
        }
    }
    @media screen and (max-width: 800px) {
        .form-pay-submit {
            padding: 15px 0;
        }
    }

    @media screen and(max-width: 480px) {
        .form-pay-submit {
            padding-top: 20px;
            //padding-left: 15px;

            a {
                min-width: initial;
                border: 0;
            }

        }
    }

    //@media screen and(max-width: 374px) {
    //    .form-pay-submit {
    //        a {
    //            display: none;
    //        }
    //        input {
    //            width: 100%;
    //        }
    //    }
    //}

}

.basketTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 23px;
    margin: 0 0 12px;
    padding: 30px 0 0;
    opacity: 0;
    visibility: hidden;

    @media screen and (max-width: 1024px) {
        visibility: visible;
        opacity: 1;
    }

    @media screen and (max-width: 800px) {
        padding: 15px 0;
        font-size: 20px;
        margin-bottom: 0;
    }

    @media screen and (max-width: 480px) {
        padding: 15px 0 60px;
        font-size: 20px;
        margin-bottom: 0;
    }
}

.basket-table-head {
    //display: table;
    width: 100%;
    margin-top: -35px;
    display: none;

    ul {
        display: table-row;

        li {
            display: table-cell;
            width: 140px;
            height: 55px;
            font-size: 12px;
            text-transform: uppercase;
            vertical-align: bottom;

            &:first-child {
                width: auto;
            }
            &:last-child {
                width: 40px;
            }
            &:nth-child(3) {
                width: 100px;
            }
        }
    }

    //@media screen and (max-width: 800px) {
    //    display: none;
    //}
}

.basketTable {
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    th {
        text-align: left;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;
        width: 140px;
        padding-bottom: 10px;

        &:first-child {
            width: auto;
        }

        &:last-child {
            width: 40px;
        }
    }

    tr {
        &:first-child {
            td {
                padding-top: 0;
            }

            .basket-quantity-s {

                @media screen and (min-width: 481px) {
                    top: 38px;
                }
            }
        }
    }

    td {
        border-bottom: 1px solid #ebebeb;
        font-size: 15px;
        //padding: 15px 0;
        padding: 15px 0;
        vertical-align: top;
        width: 140px;

        &:first-child {
            width: auto;
            vertical-align: top;
        }

        &:last-child {
            width: 30px;
            text-align: center;
            line-height: 30px;

            button {
                width: 20px;
                height: 20px;
                border: 0 !important;
            }
        }

        &:nth-child(3) {
            width: 100px;
            text-align: center;
        }
    }

    .delete {
        border: 1px solid rgba($red, 0);
        background: rgba(#000, 0);
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;

        &:before,
        &:after {
            @extend %before;
            width: 15px;
            height: 1px;
            top: 50%;
            left: 50%;
            margin-left: -7px;
            background-color: #8e8e8e;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        @media screen {
            transition: border-color $time;

            &:before,
            &:after {
                transition: background-color $time;
            }
            &:hover {
                border-color: rgba($red, .5);

                &:before,
                &:after {
                    background-color: $red;
                }
            }
        }

    }

    .basket-card {
        min-height: 67px;
        position: relative;
        padding-left: 65px;
        line-height: 67px;

        figure {
            position: absolute;
            top: 0;
            left: 0;

            img {
                max-width: 55px;
                margin: 0;
                display: block;
            }
        }

        p {
            color: #8e8e8e;
            line-height: 1.2;
            font-size: 12px;
            margin: 0;
            //display: none;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;

            & + p {
                padding-top: 8px;
            }
        }

        .name {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 1.2;
            //display: inline-block;
            vertical-align: middle;
            display: none;

            a {
                color: $text;

                &:hover {
                    color: $red;
                }
            }
        }
    }

    .basket-quantity {
        font-size: 0 !important;
        //border: 0;
        width: 100%;
        padding: 10px 0 10px 65px !important;

        @media screen and (min-width: 481px) {
            width: 1px;
            padding: 0 !important;
        }

        .basket-quantity-f {

            @media screen and (min-width: 481px) {
                position: relative;
                display: inline-block;
            }
        }

        .basket-quantity-s {

            @media screen and (min-width: 481px) {
                position: absolute;
                left: 3px;
                width: 130px;
                top: 53px;
            }
        }

        input {
            width: 50px;
            padding: 0;
            text-align: center;
            border-color: #eee;
            height: 34px;
            font-size: 14px;
            margin-left: -1px;
            margin-right: -1px;
        }

        button {
            height: 34px;
            width: 40px;
            background-color: rgba(#000, .0);
            border: 1px solid #eee;
            cursor: pointer;
            font-size: 18px;
            color: $text;
            vertical-align: top;
        }

    }

    .price {
        font-size: 16px;
        font-weight: 600;
        color: $text;
        margin: 0;
        padding: 0;

        span {
            font-size: 12px;
        }
    }

    .salePrice {
        font-size: 16px;
        font-weight: 600;
        color: $text;
        position: relative;
        margin-bottom: 20px;

        span {
            font-size: 12px;
            font-weight: 400;

            &:first-child {
                color: #999;
                font-size: 14px;
                text-decoration: line-through;
                position: absolute;
                top: 22px;
            }
        }
    }



    @media screen and (max-width: 480px) {
        tr {
            display: block;
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid #ebebeb;
        }

        td {
            display: block;
            border: 0;
            width: 100%;
            padding: 0 0 0 65px;

            &:first-child,
            &:last-child,
            &:nth-child(3) {
                width: 100%;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                height: 0;
                text-align: right;

                button {
                    //transform: translateY(-70px);
                    position: absolute;
                    top: 15px;
                    right: 0;
                }
            }

            &:nth-child(3) {
                text-align: left;
            }

        }
    }
}

.basketTotal {
    position: absolute;
    text-align: left;
    left: 0;
    padding-left: 180px;
    padding-right: 240px;
    bottom: 10px;

    .btn-primary {
        position: absolute;
        top: 50%;
        margin-top: -21px;
        height: 40px;
        border: 2px solid $color-blue;
        background-color: $color-blue;
        color: #fff;
        border-radius: 4px;
        //background-color: $dark;
        //border-color: $dark;
        //color: #fff;
        right: 0;

        @media screen {
            transition: box-shadow $time;
        }

        &:hover {
            border: 2px solid $color-blue;
            background-color: $color-blue;
            color: #fff;
            box-shadow: 0 0 10px rgba(#000, .5);
        }
    }

    .payPrice {
        font-weight: 600;
        font-size: 16px;
        padding: 5px 0 0;
        color: #484848;

        .num {
            font-weight: 600;
            font-size: 20px;

            span {
                font-size: 14px;
            }
        }

        &-detail {
            font-size: 13px;
            color: #a8a8a8;

            span {
                font-weight: 600;
            }

            .save,
            .delivery {
                display: inline !important;
                margin-right: 15px;
            }

            .save {
                color: $color-red;
            }

            .delivery {
                color: $green;
            }

        }
    }

    .back-to-shop {
        position: absolute;
        left: 100%;
        top: 15px;
        color: $grey;
        white-space: nowrap;
        line-height: 20px;
        margin-left: 10px;
        z-index: 2;

        @media screen {
            transition: color $time ease-in-out;

            &:hover {
                color: $color-black;
            }
        }
    }

    @media screen and (max-width: 1355px) {
        .back-to-shop {
            white-space: normal;
            top: 10px;
            line-height: 15px;
        }
    }
    @media screen and (max-width: 1300px) {
        bottom: 30px;

        .back-to-shop {
            left: initial;
            right: 0;
            top: 100%;
            width: 200px;
            text-align: center;
            margin-top: 5px;
            margin-left: 0;
        }
    }

    @media screen and (max-width: 1260px) {
            padding-left: 90px;
    }
    @media screen and (max-width: 1070px) {
        padding-left: 70px;
    }
    @media screen and (max-width: 1024px) {
            padding-left: 80px;

        .back-to-shop {
            left: 100%;
            right: initial;
            top: 15px;
            width: initial;
            text-align: center;
            margin-top: 0;
            margin-left: 10px;
            white-space: nowrap;
        }
    }
    @media screen and (max-width: 800px) {
        padding-left: 0;

        .back-to-shop {
            left: initial;
            right: 0;
            top: 100%;
            width: 200px;
            text-align: center;
            margin-top: 5px;
            margin-left: 0;
        }
    }
    @media screen and (max-width: 570px) {
        padding-right: 0;
        bottom: 15px;

        .btn-primary {
            position: relative;
            top: initial;
            margin-top: 15px;
            right: initial;
            width: calc(100vw - 30px) !important;
        }

        .back-to-shop {
            position: relative;
            display: block;
            top: 0;
            left: 0;
            width: 140px;
            margin: 10px auto;
            line-height: 30px;
        }
    }
}

.basketOrder {
    padding: 10px 150px 70px;

    .title {
        margin: 0 0 20px;
        font-size: 24px;
        font-weight: 400;
        text-align: left;
        display: none;

        &-hidden {
            padding-left: 20px;
        }
    }

    .block {
        border: 0;
        padding: 10px 0;
        @extend %clear;

        & > p {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;
        }
    }

    .simplecheckout-customer-block {
        //label {
        //    margin-bottom: 10px;
        //}
        .block {
            padding: 10px 0 0;

            &.close {

                .title-hidden {
                    display: block !important;
                    width: 145px;
                    position: relative;

                    &:after {
                        font-family: $fontIcon;
                        font-size: 12px;
                        font-weight: normal;
                        content: "\e919";
                        line-height: 22px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: rotate(-180deg);
                    }
                }

                .clearfix {
                    display: none;
                }

                &.is-open {
                    .title-hidden {
                        &:after {
                            transform: rotate(-90deg);
                        }
                    }
                    .clearfix {
                        display: block;
                    }
                }
            }

        }
    }

    label {
        color: #6d6e70;
        width: 175px;
        padding-right: 40px;
        //width: 74px;
        height: 35px;
        line-height: 35px;
        float: left;
        text-align: right;
        margin-bottom: 20px;

        &.phone-label {
            width: 175px;
        }
    }

    .telCountry {
        width: 54px !important;
        height: 35px;

        .dropdown-menu > li > a,
        .btn {
            padding: 12px 0 0 7px;
            height: 35px;
            border-width: 1px;
        }

        .dropdown-menu > li > a {
            border: none;
        }

        div.dropdown-menu {
            margin: -1px 0 0;
            padding: 0
        }

    }

    input[type="tel"] {
        height: 35px;
        width: calc(100% - 175px - 66px);
        max-width: 170px;
        //width: calc(100% - 155px - 66px);
        padding: 0 6px
    }

    input[type="text"],
    .emailInp {
        width: calc(100% - 175px);
        //width: calc(100% - 75px);
        height: 35px;
        margin: 0;
        max-width: 223px;
    }

    .pickup-store {
        background-color: #f7f7f7;
        padding: 20px;
        margin-top: 10px;
        margin-left: 175px;
        display: none;
        clear: both;

        p {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 15px;
        }

        span {
            display: block;
            margin-bottom: 10px;

            & + span {
                margin-bottom: 0;
            }
        }

        a {
            border-bottom: 1px dashed rgba($red, .3);
            color: $red;

            &:hover {
                border-color: rgba($red, 0);
            }
        }

        &.is-show {
            display: block;
        }

        @media screen and (max-width: 800px) {
            margin-left: 0;
        }
    }

    input[value="pickup.pickup"] ~ .pickup-store {
        display: block;
    }


    @media screen and (max-width: 1300px) {
        padding-bottom: 100px;
    }

    @media screen and (max-width: 1260px) {
        //padding: 20px 60px;
        padding-left: 60px;
        padding-right: 60px;
    }
    @media screen and (max-width: 1070px) {
        //padding: 20px 40px;
        padding-left: 40px;
        padding-right: 40px;
    }

    @media screen and (max-width: 1024px) {
        //padding: 20px 50px 30px;
        padding-left: 50px;
        padding-right: 50px;

        .title {
            display: none;
        }
    }

    @media screen and (max-width: 800px) {
        //padding: 0 0 15px;
        padding-left: 0;
        padding-right: 0;

        input[type="text"],
        .emailInp {
            width: 100%;
            //width: calc(100% - 175px);
            //width: calc(100% - 75px);
            margin: 0 0 15px;
            max-width: 230px;
        }

        label {
            float: none;
            width: 100%;
            margin: 0;
            text-align: left;

            &.phone-label {
                width: 100%;
            }
        }

        .block {
            border: 0;
            padding: 30px 0 10px;
        }

        .telCountry {
            width: 62px !important;
        }

        .sf-tel-field {
            width: 62px;
            .sft-trigger {
                width: 62px;
            }
        }

        input[type="tel"] {
            width: calc(100% - 74px);
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 570px) {
        padding-bottom: 160px;
    }

}

.selDelivery {
    width: calc(100% - 1px) !important;
    height: 35px !important;
    margin: 0;

    .btn {
        color: #6d6e70;
        font-size: 13px;
        height: 35px;
        text-transform: none
    }

    &.btn-group .btn .filter-option,
    &.dropdown-menu > li > a {
        font-size: 13px;
        height: 34px;
        line-height: 34px
    }

    &.dropdown-menu > li > a {
        padding: 0 13px;

        @media screen {
            &:hover {
                color: $red;
            }
        }

    }
}

#simplecheckout_payment .block,
#simplecheckout_shipping {

    @media screen and (min-width: 801px) {
        & > p {
            float: left;
            width: 175px;
            margin: 0;
            text-align: right;
            padding-right: 40px;
            line-height: 34px;
        }

        .sf-select {
            width: calc(100% - 175px);
        }
    }
}

.ihave {
    margin: 0;
    padding: 0 0 0 30px;

    a {
        color: $text;
        font-weight: 400;
    }

    span {
        border-bottom: 1px dotted #231f20;
        line-height: 13px;
        display: inline-block;
    }

    @media screen {
        a {
            &:hover {
                color: $red;
            }
        }
        span {
            border-bottom-color: $red
        }
    }

    & + p {
        padding: 4px 0 0;
        display: none;

        input[type="text"] {
            float: left;
            font-weight: 400;
            margin: 0 10px 0 0;
            width: calc(100% - 112px);

            @media screen and (max-width: 400px) {
                padding: 0 8px;
            }
        }

        .btn-primary {
            float: left;
            font-size: 13px;
            height: 35px;
            width: 100px;
            padding: 0
        }
    }
}

.addCommLink,
.hideCommLink {
    border-bottom: 1px dashed $text;
    line-height: 20px;
    font-size: 13px;
    display: inline-block;
    color: $text;
    margin: 20px 0 10px;

    @media screen {
        transition: border-color $time, color $time;

        &:hover {
            border-bottom-color: $red;
            text-decoration: none;
        }
    }
}

.addCommLink {
    @media screen and (min-width: 801px) {
        margin-left: 30px;
    }
}

.accom {
    background: url(../../images/icons_sprite.png) -380px -2221px no-repeat
}

.coupon {
    background: url(../../images/icons_sprite.png) -380px -2246px no-repeat
}

.applied {
    height: 39px;
    padding: 4px 0 0;
    display: none;

    span {
        display: block;
        float: left;
        min-width: 50px;
        line-height: 35px
    }

    input {
        background: url(../../images/icons_sprite.png) 12px -877px no-repeat;
        border: none;
        width: 35px;
        height: 35px;
        padding: 0
    }
}

.is-coupon {
    @extend %clear;
}

.show-promo-code {
    height: 36px;
    border: 0;
    padding: 0;
    text-align: left;
    //width: auto;
    width: 120px;
    float: left;
    margin-left: 30px;
    font-size: 13px;
    background-color: #fff;

    span {
        line-height: 20px;
        color: $text;
        border-bottom: 1px dashed;
    }

    &.is-active {
        & + .promo-code-coupon {
            display: block;
        }
    }

    @media screen {
        &:hover {
            span {
                color: $red;
            }
        }
    }

    @media screen and (max-width: 800px) {
        margin-left: 0;
        //float: none;
    }

    @media screen and (max-width: 500px) {
        float: none;
    }
}

.promo-code-coupon {
    position: relative;
    //padding-top: 10px;
    float: left;
    width: calc(100% - 150px);
    display: none;
    margin-bottom: 0;
    @extend %clear;

    input {
        &.sf-promo-code {
            float: left;
            width: calc(100% - 150px) !important;
            margin-left: 30px;

            @media screen and (max-width: 800px) {
                width: calc(100% - 120px) !important;
                margin-left: 0;
                margin-bottom: 0 !important;
            }
        }
        &.btn-primary {
            height: 35px;
            border: 0;
            width: 120px;
            padding: 0;
            font-size: 14px;
            color: #fff !important;
            background-color: #8c8b8d !important;

            &:hover {
                background-color: $dark !important;
            }
        }
    }

    .simplecheckout-error-text {
        margin-left: 30px;

        @media screen and (max-width: 800px) {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 800px) {
        width: calc(100% - 120px);
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        padding-top: 10px;
    }
}

.withdrawn-box {
    text-align: center;

    a {
        display: inline-block;
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        font-size: 14px;
        color: #fff;
        background-color: $color-blue;
        border-radius: 4px;

        @media screen {
            transition: box-shadow $time ease-in-out;

            &:hover {
                box-shadow: 0 1px 3px rgba(#000, .3);
            }
        }
    }

    br + a {
        margin-top: 15px;
    }
}

.radio-list {
    width: calc(100% - 175px);
    overflow: hidden;
    padding-left: 1px;
    display: block;

    &-item {
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-right: 40px;
        line-height: 34px;

        &.is-np {
            white-space: nowrap;
            margin-right: 0;
        }

        input {
            display: initial;
            line-height: 34px;
            vertical-align: baseline;
            height: initial;
            margin-right: 3px;
        }

        label {
            display: initial;
            width: auto;
            padding: 0;
            float: none;
            line-height: 10px;

            &[for="courier_total.courier_total"]{
                white-space: nowrap;

                img:not(.is-nova-p) {
                    display: none !important;
                }
            }
        }

        a {
            margin-left: 10px;
            color: $text;
            border-bottom: 1px dashed;

            @media screen {
                &:hover {
                    color: $red;
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        float: none;
        margin-bottom: 20px;

        &-item {
            display: block;
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}

.blockComm {
    display: none;

    textarea {
        width: 100%;
        height: 100px;
        margin: 10px 0 0
    }

    @media screen and (min-width: 801px) {
        margin-left: 30px;
    }
}

#quickViewModal {
    @media screen and (max-width: 880px) {
        opacity: 0;
        visibility: hidden;
        display: none !important;
    }
}

.quickModal {
    //width: 735px;
    width: 862px;
    min-height: 402px;
    padding: 0;

    top: 50px;
    left: 50%;
    margin-left: -431px;
    bottom: initial;

    @extend %clear;

    .close {
        top: 20px !important;
        right: 0 !important;
        border: 0 !important;
        z-index: 10;
    }
}

.quickLeft {
    float: left;
    width: 440px;
    height: 100%;
    position: relative;

    //.popup-pr-slider {
    //    width: 400px;
    //    height: 400px !important;
    //    margin: 0;
    //
    //    .item {
    //        width: 400px;
    //        height: 400px;
    //    }
    //
    //    figure {
    //        display: block;
    //    }
    //
    //    img {
    //        max-height: 400px;
    //        width: initial;
    //        margin: 0 auto;
    //    }
    //
    //}
    //
    //.prodNext {
    //    right: 0
    //}
}

.quickRight {
    float: left;
    width: 400px;
    min-height: 400px;
    padding: 15px 0 15px 15px;
    position: relative;

    .quick-right-end {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 90px 20px 20px;
        background-color: #fff;
        display: none;
        z-index: 2;

        &.is-active {
            display: block;
        }

        p {
            color: #8c8b8d;

            &.quickTitle {
                color: $text;
            }
        }

        div {
            padding-top: 20px;
            white-space: nowrap;

            button {
                background-color: #fff;
                border: 2px solid #f7f7f7;
                border-radius: 4px;
                padding: 0 25px;
                height: 40px;
                color: $color-grey;

                &:first-child {
                    background-color: $color-blue;
                    border-color: $color-blue;
                    color: #fff;
                    margin-right: 10px;
                }

                @media screen {
                    transition: box-shadow $time, background-color $time, color $time;

                    &:hover {
                        &:nth-child(2) {
                            background-color: #f7f7f7;
                            color: $color-black;
                        }
                        &:first-child {
                            box-shadow: 0 0 10px rgba(#000, .5);
                        }
                    }
                }

            }
        }
    }

    .brand {
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 600;
        //text-transform: uppercase;
        color: $color-text;

        a {
            color: currentColor;

            @media screen {
                //transition: color $time;

                &:hover {
                    color: $color-blue;
                }
            }
        }
    }

    .quantity {
        margin-top: 0;
        padding: 0;
        margin-bottom: 10px;
        display: none;

        .is-stock {
            padding: 0;
            margin-top: 0;
        }

        ins {
            vertical-align: top;
        }
    }

    .price-block {
        margin-bottom: 25px;

        //span {
        //    display: block;
        //    color: $grey;
        //    font-size: 14px;
        //
        //    &:first-child {
        //        font-size: 20px;
        //        color: $dark;
        //
        //    }
        //
        //    & + span {
        //        text-decoration: line-through;
        //    }
        //}

        &.is-original {
            margin-bottom: 20px;

            .original-price-new {
                    font-size: 20px;
                    display: block;
            }
        }
    }

    .sale-price-box {
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding-right: 70px;
        text-align: center;

        .original-price {
            color: #8b8c8d;
            font-size: 13px;
            display: block;
            text-decoration: line-through;

            &-new {
                font-size: 20px;
                color: $dark;
                display: block;
            }
        }

        .sticker-price-down {
            display: block;
            padding: 3px 15px 3px 10px;
            position: absolute;
            top: 5px;
            right: 0;
            font-size: 14px;
            z-index: 1;
            white-space: nowrap;
            background-color: #fff;
            border: 1px solid $red;
            line-height: 1.4;
            //color: $red;
            color: $text;

            &:before,
            &:after {
                @extend %before;
                width: 0;
                height: 0;
                border: 5px solid transparent;

            }

            &:before {
                border-left-color: $red;
                border-top-color: $red;
                bottom: -10px;
                left: -1px;
            }

            &:after {
                border-left-color: #fff;
                border-top-color: #fff;
                bottom: -8px;
                left: 0;
            }

            b {
                font-weight: 400;
            }
        }

    }

    .quickTitle {
        font-size: 18px;
        color: $grey;
        margin-bottom: 20px;
        font-weight: 400;
    }

    .code {
        padding: 0;
        margin-bottom: 15px;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        color: $grey;
    }

    .price {
        font-size: 20px;
        padding: 0 10px 10px 0;

        span {
            color: #8e8e8e;
            font-size: 20px;
            margin-left: 15px;
            text-decoration: line-through;
        }
    }

    .prodOneParam {
        padding: 10px 0 15px;

        //border-top: 0;
        //padding: 0;
        //border-bottom: 0;
        //margin-bottom: 15px;

        //& + .prodOneParam {
        //    margin-top: -11px;
        //}

        //&.is-hidden {
        //    & + .prodOneParam {
        //        margin-top: 0;
        //    }
        //}

        .prodParamTxt {
            margin-bottom: 5px;
            display: block;
        }

        .filterColor {
            //display: inline-block;

            label {
                margin: 0 3px 3px 0;
                width: 40px;
                background-position: 15px -805px !important;
                border: 0 !important;
                border-radius: 4px;

                &:hover {
                    border: 0 !important;
                }
            }

            input:checked + label {
                box-shadow: 0 0 3px rgba(#000, .4);
                border: 0 !important;
            }
        }

        .filterSize {
            padding-right: 10px;
        }

        &.choose {
            background: #fff;
            //border: 0 !important;
            border: 1px solid $text;
            margin: -1px 0 8px -20px;
            padding: 12px 10px 5px 15px !important;
            z-index: 101;
            width: 390px;

            .p-line-tablelink {
                display: none;
            }
        }
        .chooseError {
            width: 390px !important;
        }


    }

    .more-original {
        //color: $red;
        color: $color-text;
        border-bottom: 1px dashed rgba($color-text, .3);

        @media screen {
            transition: color $time ease-in-out .1s, border-color $time ease-in-out .1s;
            &:hover {
                color: $color-blue;
                border-color: rgba($color-blue,.3);
            }
        }
    }

    .delBuy {
        padding: 0;
        margin-bottom: 15px;

        a {
            height: 40px;
            line-height: 38px;
            display: inline-flex;
            vertical-align: top;
            text-align: center;
            border-radius: 4px;

            span {
                margin: auto;
            }
        }

    }

    .prodDeliv {
        //width: 154px;
        top: 2px;
        position: relative;
        white-space: nowrap;
    }

    .fave-link {
        width: 48px;
        height: 40px;
        //text-align: center;
        border: 1px solid rgba($grey, .1);
        font-size: 18px;
        color: $grey;

        i {
            margin: auto;
        }

        @media screen {
            transition: background-color $time, color $time;

            &:hover {
                background-color: rgba($grey, .1);
                color: $dark;
            }
        }
    }

    .btnBuy {
        width: 200px;
        font-size: 14px;
        font-weight: 400;
        height: 48px;
        line-height: 48px;
        margin: 0 10px 0 0;
        border: 0;
        background-color: $color-blue;
        color: #fff;
        //border: 1px solid rgba($grey, .05);
        //background-color: rgba($grey, .2);
        //color: $grey;

        @media screen {
            transition: box-shadow $time;

            &:hover {
                //background-color: $text;
                //color: #fff;
                box-shadow: 0 0 10px rgba(#000, .5);
            }
        }
    }

    //.phone-block {
    //    //max-width: 390px;
    //    width: 100%;
    //    margin: 30px 0 15px;
    //    position: relative;
    //    @extend %clear;
    //
    //    .sf-tel-field {
    //        position: absolute;
    //        top: 0;
    //        left: 0;
    //        width: 50px;
    //
    //        .sft-trigger {
    //            height: 30px;
    //            border: 0 !important;
    //            background-color: transparent;
    //            width: 50px;
    //        }
    //
    //        .sft-list {
    //            top: 25px;
    //            padding: 5px 0 0 0;
    //            background-color: #f7f7f7;
    //            border: 0 !important;
    //
    //            .sft-selected {
    //                display: none;
    //            }
    //        }
    //
    //        //&.is-error {
    //        //    button {
    //        //        border-color: $red;
    //        //        border-right-color: #fff;
    //        //    }
    //        //}
    //    }
    //
    //    input {
    //        float: left;
    //
    //        &[type="button"]{
    //            width: 150px;
    //            padding: 0;
    //            height: 30px;
    //            border: 0;
    //            font-weight: 400;
    //            font-size: 13px;
    //            color: #fff;
    //            background-color: $dark;
    //            border-radius: 0 4px 4px 0;
    //
    //            @media screen {
    //                transition: background-color $time, box-shadow $time;
    //
    //                &:hover {
    //                    background-color: $color-blue;
    //                    box-shadow: 0 0 5px rgba(#000, .5);
    //                }
    //            }
    //        }
    //
    //        &[type="tel"] {
    //            width: calc(100% - 150px);
    //            height: 30px;
    //            border: 2px solid #f7f7f7;
    //            border-right: 0;
    //            font-size: 13px;
    //            background-color: #f7f7f7;
    //            padding: 0 10px 0 50px;
    //            -webkit-appearance: none;
    //            -moz-appearance: none;
    //            appearance: none;
    //            box-shadow: none !important;
    //            border-radius: 4px 0 0 4px;
    //
    //            @media screen {
    //                transition: border-color $time, background-color $time;
    //            }
    //
    //            &:focus {
    //                background-color: #fff;
    //            }
    //
    //            &.is-error {
    //                border-color: $red;
    //            }
    //        }
    //
    //    }
    //
    //    //@media screen and (max-width: 400px) {
    //    //    input {
    //    //        &[type="tel"] {
    //    //            width: calc(100% - 58px);
    //    //            border-right: 1px solid rgba($grey, .2);
    //    //        }
    //    //        &[type="button"]{
    //    //            width: 100%;
    //    //            //margin-top: -5px;
    //    //        }
    //    //    }
    //    //}
    //}

}

.productBig {

}

.next-buy-box {
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity $time, visibility $time;
    display: none;

    &.is-open {
        opacity: 1;
        visibility: visible;
        display: block;
    }


    .nbb {
        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(#000,.8);
            cursor: pointer;
        }

        &-close {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            cursor: pointer;
            text-align: center;
            color: $grey;
            font-size: 28px;
            line-height: 40px;
            z-index: 5;

            @media screen {
                &:hover {
                    color: $color-blue;
                }
            }
        }

        &-content {
            //width: 700px;
            width: 400px;
            text-align: center;
            //line-height: 400px;
            position: absolute;
            z-index: 5;
            background-color: #fff;
            top: 50%;
            left: 50%;
            //margin-left: -350px;
            margin-left: -200px;
            transform: translateY(-50%);
            border-radius: 4px;
            @extend %clear;

            .delBuy {
                //padding: 10px 0 0;
                //margin-right: -20px;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 20px;
                width: 100%;
                background-color: #fff;
                white-space: nowrap;
                border-radius: 4px;
            }

            button {
                background-color: #fff;
                //border: 1px solid #eee;
                //padding: 0 15px;
                height: 40px;
                color: $color-grey;
                //margin-top: 10px;
                width: calc(50% - 8px);
                vertical-align: top;
                border: 2px solid #f7f7f7;
                border-radius: 4px;
                font-size: 15px;
                padding: 0;

                &:first-child {
                    background-color: $color-blue;
                    border-color: $color-blue;
                    color: #fff;
                    margin-right: 16px;
                }

                @media screen {
                    transition: background-color $time ease-in-out, color $time ease-in-out, box-shadow $time ease-in-out;
                    &:hover {
                        background-color: #f7f7f7;
                        color: $color-black;

                        &:first-child {
                            background-color: $color-blue;
                            color: #fff;
                            box-shadow: 0 0 10px rgba(#000, .5);
                        }
                    }
                }

            }
        }

        &-thumb {
            padding: 70px 0;

            img {
                //max-height: 400px;
                display: inline-block;
                border-radius: 4px;
                max-width: 100%;
                max-height: 424px;
            }

        }

        &-info {
            //display: inline-block;
            //vertical-align: middle;
            //position: relative;
            //top: -10px;
            //width: 355px;
            padding: 20px;
            line-height: 1.2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            p {
                font-size: 14px;
                color: $grey;

                &.nbb-name {
                    font-size: 18px;
                    color: $dark;
                    padding: 5px 0 0;
                }
            }
        }
    }

    @media screen and (max-width: 424px) {
        .nbb {
            &-thumb {
                img {
                    max-height: 200px;
                }
            }
            &-info {
                width: 100%;
                padding: 5px 15px;
                text-align: center;

                p {
                    &.nbb-name {
                        padding-top: 15px;
                    }
                }

            }
            &-content {
                width: 96%;
                margin-left: 0;
                left: 2%;
                line-height: 1;

                .delBuy {
                    padding: 20px 0;
                    button {
                        font-size: 12px;

                        &:first-child {
                            font-size: 14px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.full {
    background: url(../../images/icons_sprite.png) -285px -785px no-repeat;
    color: #f15732;
    display: block;
    font-size: 10px;
    padding: 0 15px 0 0;

    &:hover span {
        border-bottom: 1px solid #f15732
    }
}

.quickTell {
    padding: 49px 0 0 82px;

    & > span {
        display: block;
        font-size: 10px;
        font-weight: 700;
        float: left;
        line-height: 12px;
        width: 82px;
        margin: 0 0 0 -82px;
        padding: 4px 0 0
    }
}

.seoBlock {
    border-top: 2px solid #f7f7f7;
    color: #4e4e4e;
    cursor: pointer;
    max-height: 180px;
    padding: 1px 0 40px;
    overflow: hidden;
    position: relative;
    transition: max-height $time;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    &.is-empty {
        display: none;
    }

    .page_text {
        padding: 0 !important;
        max-height: initial !important;
    }

    &.is-open {
        max-height: 10000px;
    }

    h1, h2, h3, h4 {
        color: $dark;
        font-weight: 600;
        text-transform: none;
        text-align: left;
        font-size: 16px;
    }

    hr {
        display: none;
    }

    p {

        &:first-child {
            margin-top: 20px;
        }

        //@media screen and (min-width: 600px) {
        //    column-count: 2;
        //}
    }

    .container {
        position: relative;
    }

    @media screen and (max-width: 1024px) {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }
}

.seo-btn {
    position: absolute;
    top: 140px;
    left: 15px;
    font-size: 14px;
    height: 22px;
    background-color: #fff;
    border: 0;
    padding: 0;
    z-index: 10;
    color: $color-blue;
    border-bottom: 1px dashed;

    &.is-active {
        top: initial;
        bottom: -20px;
    }

    @media screen {
        transition: border-color $time;
        &:hover {
            border-color: rgba($color-blue, 0);
        }
    }
}


.forgot {
    border-bottom: 1px dashed rgba($text, .3);
    color: $text;
    margin: 0 0 0 20px;
    //font-weight: 600;

    @media screen {
        transition: color $time ease-in-out .1s, border-color $time ease-in-out .1s;
    }
        &:hover {
            color: $color-blue;
            border-bottom-color: rgba($color-blue, .3);
        }
    //}
}

.regBlock {
    display: none
}

//.forgotModal {
//    width: 518px;
//    height: 330px;
//
//    .emailInp {
//        width: 391px;
//        margin: 0 0 20px
//    }
//
//    p {
//        margin: 0 0 20px
//    }
//
//    a {
//        color: #f15732;
//        border-bottom: 1px solid rgba($red, .3);
//
//        &:hover {
//            border-bottom-color: rgba($red, 0);
//        }
//    }
//}

//.favAdded a:hover,
//.favLogin a:hover {
//    color: #f15732;
//    border-bottom: 1px solid rgba(241, 87, 50, .3)
//}
//
//.favAdded .link:hover,
//.favLogin .link:hover {
//    border-bottom: none
//}
//
//.favLogin,
//.favAdded {
//    margin: 0 0 0 10px
//}

//.success .favIcon,
//.favLogin .favIcon,
//.favAdded .favIcon {
//    background: url(../../images/icons_sprite.png) -168px -2488px no-repeat;
//    display: inline-block;
//    margin: 0 0 30px;
//    height: 44px;
//    width: 45px
//}

//.success .favIcon,
//.favAdded .favIcon {
//    background-position: 0 -2488px;
//    width: 43px
//}
//
//.favAdded a,
//.favLogin a {
//    color: #231f20;
//    border-bottom: 1px solid rgba(35, 31, 32, .2);
//    font-weight: 600
//}
//
//.favAdded .link, .favLogin .link {
//    color: #f15732;
//    border-bottom: 1px solid rgba(241, 87, 50, .3);
//    font-weight: 700
//}

.regPage {
    .favLogin {
        border-bottom: 2px solid #000;
        margin: 30px auto;
        max-width: 320px;
        padding: 0 0 25px;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase
    }

    form {
        margin: 0 0 60px
    }

    .error-text {
        color: #d90000;
        font-size: 20px;
        line-height: 30px;
        margin: 20px 0 0
    }

    h2 {
        margin: 60px 0 40px;
        font-size: 24px;
        font-weight: 600;
    }

    .formBlock {
        width: 600px;
        max-width: 100%;
        margin: 0 auto;
    }

    input[type="text"],
    input[type="email"] {
        width: 100%;
        margin: 20px 0;
    }

    input[type="text"] {
        margin-top: 0;
        margin-bottom: 15px;
    }

    &.emptyCart {
        margin: 0 0 20px;

        .favLogin {
            padding-top: 30px;
            border: none
        }

        @media screen and (max-width: 1024px) {
             .favLogin {
                 margin-bottom: 0;
                 text-transform: none;
                 font-size: 16px;
             }
        }
    }

    @media screen and (max-width: 600px) {
        h2 {
            text-align: left;
            font-size: 20px;
            margin: 30px 0 20px;
        }

        form {
            margin-bottom: 30px;
        }
    }

}

.errorInp {
    border-color: #ff6262;
    color: #ff6262
}

.sf-login-heading {
    text-transform: uppercase;
    margin: 60px 0 40px;
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: 600px) {
        text-align: left !important;
        margin: 30px 20px;
    }
}

.formBlock {
    max-width: 400px;
    margin: 0 auto;
    min-height: 100px;

    label {
        text-align: left;
        margin: 0 0 5px
    }

    input[type="tel"] {
        width: calc(100% - 70px);
    }

    input[type="password"] {
        width: 100%;
        margin: 12px 0;
    }

}

.continue {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 50px
}

.continue a, .newClient a  {
    color: $color-blue;
    border-bottom: 1px solid rgba($color-blue, .2);
}

.logPage {
    padding: 30px 0;

    &.newClient {
        font-size: 14px;
        padding: 30px 0;
    }

    @media screen and (max-width: 600px) {
        padding: 0 0 30px;

        &.newClient {
            text-align: left;
            padding: 0 0 30px;
        }
        .subTitle {
            text-align: left;
        }
        .formBlock {
            max-width: 100%;
        }
    }
}

.subTitle {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px
}

.siteMap {

    li {
        border-top: 1px solid #eee;
        padding: 30px 0;
        overflow: hidden;

        &:first-child {
            border-top: 0
        }

        a {
            color: $text;
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase
        }

        li {
            border-top: 0;
            padding: 0;

            a {
                color: $red;
                font-size: 16px;
                line-height: 50px;
            }

            li {
                margin-bottom: 10px;

                a {
                    line-height: 1.2;
                }
            }
        }
    }

    .col-lg-12 {
        a {
            color: $text;
            font-size: 13px;
            font-weight: 400;
            text-transform: none;
        }

        @media screen {
            a {
                transition: color $time;

                &:hover {
                    color: $red;
                }
            }
        }
    }

}

.body404 {
    background: #1f2124;
    color: #fff;
    text-align: center
}

.page404 {
    width: 100%;
    height: 100%;

    & > .child {
        width: 600px
    }
}

.logo404 {
    display: block;
    //width: 127px; /*189px*/
    width: 114px;
    height: 40px;
    margin: 50px auto;
    position: relative;
    z-index: 100;

    img {
        min-height: 40px;
        width: auto;
    }
}

.error404 {
    height: 150px;
    margin: 0 0 80px;
    position: relative;

    .child {
        width: 95%
    }

    p {
        font-size: 24px;
        //font-weight: 300;
        font-weight: 400;
        line-height: 28px;
        max-width: 580px;
        opacity: .8;
        text-transform: uppercase
    }

    span {
        display: block;
        font-size: 200px;
        font-weight: 700;
        line-height: 130px;
        height: 150px;
        opacity: .03;
        position: absolute;
        margin: 0 0 0 -175px;
        left: 50%;
        top: 0;
        width: 350px
    }
}

.search404 {
    border: 1px solid #6d6e70;
    height: 37px;
    position: relative;
    padding: 0 45px 0 0;
    margin: 0 0 45px;

    .searchPopup {
        width: 100% !important;
        min-width: initial;
        left: 0 !important;
        right: 0 !important;
    }

    input[type="text"],
    input[type="submit"] {
        background: none;
        border: none;
        color: #fff;
        float: left;
        height: 35px; /*37px*/
        width: 100%
    }

    input[type="submit"] {
        //background: url(../../images/icons_sprite.png) 14px -655px no-repeat;
        border-left: 1px solid #fff;
        cursor: pointer;
        width: 45px;
        opacity: 1;
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;

        &:hover {
            background-color: transparent;
            border-left-color: #6d6e70;
            opacity: 1;

            & + .sf-icon-search {
                background-color: $red;
            }
        }
    }

    .sf-icon-search {
        position: absolute;
        top: 0;
        right:0;
        font-size: 16px;
        width: 45px;
        z-index: 2;
        height: 35px;
        line-height: 35px;
        transition: background-color $time;
    }

    .sf-form-close {
        position: absolute;
        right: 45px;
        height: 35px;
        background-color: transparent;
        border: 0;
        padding: 0 10px 0 0;
        z-index: 1;
        display: none;

        &.is-show {
            display: inline;
        }
    }

    .searchPopup {
        top: 40px !important;
    }

}

.menu404 {
    margin: 0 -35px 100px;

    li {
        display: inline-block;
        margin: 0 35px
    }

    a {
        color: #d2d2d2;
        text-transform: uppercase
    }
}

.copyr404 {
    color: #6d6e70;
    font-size: 12px;
    //font-weight: 300;
    font-weight: 400;
    text-align: center;
    position: absolute;
    bottom: 37px;
    left: 50%;
    margin: 0 0 0 -277px;
    padding: 0 20px;

    @media screen and (max-height: 600px) {
        position: relative;
        bottom: auto;
        left: 0;
        margin: 0;
        padding: 20px;
    }
}

.backMain {
    font-size: 16px;
    color: $color-blue;
    border-bottom: 1px solid rgba($color-blue, .2);

    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }

    @media screen {
        transition: color $time, border-color $time;

        &:hover {
            color: $color-text;
            border-bottom: 1px solid rgba($color-text, .2);
        }
    }
}

/*google maps */
.gmnoprint img {
    max-width: none
}

.map-holder {
    //width: 591px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.map-success {
    //width: 297px;
    width: 100%;
    //height: 300px
}

.map-holder-modal {
    width: 100%;
    height: 100%;
}

.time-range {
    margin: 0 15px 0 0;
    float: left;
    width: 210px;

    @media screen and (max-width: 1024px) {
        width: 100%;
        float: none;
    }
}

.ui-slider-horizontal {
    border-radius: 0;
    background: #f5f5f5;
    clear: both;
    border: 0;
    margin: 8px 0;

    .ui-slider-range {
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
        background: #c0c0c0;
        top: 0;
        bottom: 0;
    }

    .ui-slider-handle {
        border-radius: 0;
        background: #c0c0c0;
        width: 10px;
        height: 18px;
        position: absolute;
        top: 50%;
        margin-top: -9px;
        margin-left: -5px;
        z-index: 2;
        border: none;
        cursor: pointer;
    }

    span {
        outline: none
    }

}

#callbackForm.time-open input[type="submit"] {
    margin-top: 10px;
    max-width: calc(100% - 240px);
    padding: 0;
}

/* IE styles */
//.ie11 {
//    overflow-y: scroll
//}
//
//.ie .userLink.userMess, .ie11 .userLink.userMess {
//    width: 141px
//}
//
//.ie .subMenu, .ie11 .subMenu {
//    top: 62px
//}
//
//.ie .wear, .ie11 .wear {
//    left: -153px
//}
//
//.ie .nav > li:nth-child(3) .wear, .ie11 .nav > li:nth-child(3) .wear {
//    left: -308px
//}
//
//.ie .nav > li:nth-child(4) .wear, .ie11 .nav > li:nth-child(4) .wear {
//    left: -461px
//}
//
//.ie .nav > li:nth-child(5) .wear, .ie11 .nav > li:nth-child(5) .wear {
//    left: -581px
//}
//
//.ie .nav > li:last-child .brands, .ie11 .nav > li:last-child .brands {
//    left: auto;
//    right: 0
//}
//
/* progress bar */
.barCircle {
    //-moz-animation: loadingFade 1s infinite;
    animation: loadingFade 1s infinite
}

.progressPage {
    background: rgba(255, 255, 255, .6);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000001;

    .progressBar {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    .barCircle {
        float: left;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        background: #f15732;
        display: block;
        border-radius: 50%;
        opacity: 0;
        animation: loadingFade 1s infinite;

        &:nth-child(1) {
            animation-delay: 0s
        }
        &:nth-child(2) {
            animation-delay: 0.1s
        }
        &:nth-child(3) {
            animation-delay: 0.2s
        }
    }

    @media screen and (max-width: 1024px) {
        position: fixed;
        z-index: 10000010;
    }
}

@keyframes loadingFade {
    0%, 100% {
        opacity: 0
    }
    50% {
        opacity: .8
    }
}

.alert {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 15px
}

.star,
.simplecheckout-error-text,
.action-price .title {
    color: #FB0203
}

.simplecheckout-error-text {
    width: calc(100% - 30px);
    line-height: 1.2;
    font-size: 13px;
    padding-top: 10px;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

/* colorpicker */
div.sp-container {
    border-color: #c5c5c5;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    //font: 13px 'Open Sans', Helvetica, Arial, sans-serif
    font: 13px $fontName, Helvetica, Arial, sans-serif
}

.sp-container .sp-choose {
    background: #f15732;
    border: none;
    color: #fff;
    font-size: 14px;
    font-family: $fontName, sans-serif;
    //font-family: $font, sans-serif;
    //font-weight: 300;
    font-weight: 400;
    height: 36px;
    text-transform: none;
    padding: 0 15px;
    border-radius: 0;
    text-shadow: none
}

.sp-container .sp-choose:hover {
    background: #000;
    border: none;
    text-shadow: none
}

.sp-container button.sp-choose:active {
    border: 0;
    box-shadow: none;
    background: #000
}

.product-product {
    //.footerSignScroll {
    //    visibility: hidden;
    //}

}

.sizePopup {
    border: 1px solid #000;
    background: #fff;
    position: absolute;
    top: 2px;
    left: -19px;
    padding: 19px 18px 15px;
    width: 558px;
    z-index: 101;
    height: 229px;
    display: none
}

.humans {
    display: inline-block !important;

    @media screen {
        transition: opacity $time;

        &:hover {
            opacity: .8;
        }
    }

    @media screen and (max-width: 1024px) {
        display: block;
        position: absolute;
        top: 1px;
        right: 0;
        margin: 0 !important;
    }
}

.sitepage {
    section {
        &:first-child {
            padding-top: 109px;
        }
    }
}

@media screen and (max-width: 1024px) {
    body {
        //position: fixed;
        position: relative;
        width: 100vw;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: 100%;
        //overflow: hidden;
    }

    #header.sf-header {
        //position: absolute !important;
        position: fixed !important;
        //top: 0;
        //left: 0 !important;
        //width: 100% !important;
        height: 51px !important;
    }

    .sitepage {
        //position: absolute;
        //bottom: 0;
        //top: 0;
        //left: 0;
        //right: 0;
        position: relative;
        width: 100%;
        background-color: #f7f7f7;
        //overflow-y: auto;
        //overflow-x: hidden;
        //-webkit-overflow-scrolling: touch;
        //-webkit-transform: translate3d(0,0,0);

        section {
            &:first-child {
                padding-top: 51px;
            }
        }
    }

    .information-contact,
    .information-information,
    .orderPage {
          .sitepage {
              background-color: #fff;
          }
    }
}

.bottom-filter-sort-wrap {
    @media screen and (max-width: 1024px) {
        .filtersSortBottom {
            padding-bottom: 0;
        }

        a {
            &:not(.btn-view-all) {
                display: none !important;
            }
        }
    }
}
//.bottom-filter-sort-wrap {
//    margin-top: 10px;
//    a {
//        &:after {
//            background: url(../../images/icons_sprite.png) 0 -1209px no-repeat;
//            margin: 0;
//            top: 8px;
//            right: 0;
//            width: 16px;
//            height: 16px;
//        }
//        &:hover {
//            &:after {
//                background-position: 0 -1209px;
//            }
//        }
//    }
//}


//.quantity + script + .hidden + .delBuy,
//.quantity + script + .hidden + .hidden + .delBuy {
//    padding-top: 15px;
//}
//

.new-action-price {
    border-top: 1px solid #f7f7f7;
    padding: 15px 0;
    margin: 0;
    color: $color-black;
    @extend %clear;

    .time-holder {
        font-size: 11px;
        float: left;
        width: 70%;
        text-align: right;
        padding-right: 20px;

        strong {
            font-size: 24px;
            font-weight: 300;
            margin-right: 8px;

            & ~ strong {
                margin-right: 0;
                margin-left: 8px;
            }
        }

        i {
            font-family: $fontIcon;
            font-size: 24px;
            position: relative;
            margin-right: 15px;
            font-style: normal;
            color: #aaa;
            top: 1px;

            &:before {
                content: "\e910";
            }
        }
    }

    .timer-text {
        float: left;
        text-align: left;
        margin-top: -7px;
        margin-bottom: -5px;
        padding: 5px 0 5px 20px;
        border-left: 2px solid #f7f7f7;
        font-size: 11px;
        line-height: 13px;
        color: $color-red;
    }

    @media screen and (max-width: 415px) {
        padding: 35px 0 15px 0;

        .time-holder {
            width: 100%;
            float: none;
            text-align: center;
            padding: 20px 0 0 0;
        }

        .timer-text {
            width: 100%;
            float: none;
            text-align: center;
            padding: 0;
            transform: translateY(-40px);
            border: 0;

            br {
                display: none;
            }
        }
    }
}


.new-price-block {
    text-align: center;
    padding: 10px 0 5px;

    .npb {
        &-wrap {
            display: inline-block;
            vertical-align: top;
            width: auto;
            padding: 0 15px;
            position: relative;
        }
        &-old {
            font-size: 13px;
            color: $color-grey;
            position: absolute;
            right: 100%;
            text-decoration: line-through;
            white-space: nowrap;
        }
        &-now {
            font-size: 16px;
            font-weight: 600;
            color: $color-black;
        }
        &-nav {
            position: absolute;
            left: 100%;
            width: 100px;
            top: 1px;

            button {
                height: 20px;
                padding: 0;
                margin-right: 8px;
                font-weight: 300;
                font-size: 11px;
                text-transform: uppercase;
                background-color: transparent;
                color: $color-grey;
                border: 0;
                border-bottom: 1px solid rgba($color-grey, .3);

                @media screen {
                    transition: color $time, border-color $time;

                    &:hover {
                        color: $color-black;
                        border-color: rgba($color-grey, 0);
                    }
                }
                &.is-active {
                    color: $color-blue;
                    border-color: rgba($color-grey, 0);
                    display: none;
                }
            }
        }
    }
}

.product-product {
    .new-price-block {
        .npb-now.is-red {
            color: $color-red;
        }
    }
}


.productBig {
    @media screen and (max-width: 1024px) {
         .product-slides {
             margin-left: -15px;
             margin-right: -15px;
         }
    }
}

.is-as-wrap {
    @media screen and (max-width: 1024px) {
        display: none;
    }
}


.is-front-page {

    .newsBlock {
        display: none;
    }

    .is-footer-formsign {
        display: none;
    }

    .is-home-subscribe {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -85px;
        z-index: 2;
        width: 100%;

        @media screen and (max-width: 1023px) {
            position: relative;
            top: 0;
            margin: 15px 0 0;
        }
    }

}


.information-news {

    .sitepage {
        background-color: #fff;
    }

    .catalog-header {
        margin: 0;
    }

    .breadcrumb {
        margin: 0;

        li {
            padding-bottom: 0;

            a {
                line-height: 30px;
                padding: 0;
            }

        }
    }

    h1 {
        padding: 20px 0;
    }

    .newsOne {
        padding: 30px;
    }

    .bigNewsOne {
        padding-right: 50px;

        @media screen and (max-width: 1024px) {
            padding-right: 15px;
        }

        a {
            text-decoration: none;
        }
    }

    .sn-relate-post {

        & + .sn-relate-post {
            margin-top: 30px;
        }

        a {
            display: block;
            overflow: initial;
            height: initial;
            text-decoration: none;
            font-size: 16px;
            color: $color-black;

            img {
                max-width: 100%;
            }

            span {
                display: block;
                padding-top: 15px;
            }

            &:hover {
                color: $color-blue;
            }
        }
    }

    .allNews {
        top: 0;
        margin-top: 20px;
        margin-left: 0;
    }

    .slider-line {
        clear: both;
    }
}

.secure-page {

    .col15-lg-11 {
        margin-bottom: 30px;
    }

    h2 {
        text-transform: none;
        text-align: left;
        font-size: 24px;

        @media screen and (max-width: 600px) {
            text-align: center;
        }
    }

    p {
        font-size: 14px;
    }

    &-icon {
        float: left;
        margin-top: 30px;
        margin-left: 30px;
    }

    &-row {
        @extend %clear;

        img {
            max-width: 100%;
        }

        & + .secure-page-row {
            margin-top: 35px;
        }

        & > div {
            overflow: hidden;
            margin-left: 120px;
        }

        &:nth-of-type(2){
            .secure-page-icon {
                margin-left: 10px;
            }
        }
        &:nth-of-type(3){
            .secure-page-icon {
                margin-left: 25px;
            }
        }

        @media screen and (max-width: 600px) {
            .secure-page-icon {
                float: none;
                margin: 30px auto 0 !important;
            }

            & > div {
                margin-left: 0;
                text-align: center;
            }
        }

    }

}

.secure-quest {
    border: 4px solid #f7f7f7;
    padding: 20px;
    font-size: 14px;
    margin-bottom: 30px;

    span,
    i {
        color: $color-grey;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 24px;
        font-size: 16px;
    }

    li {
        padding-left: 30px;
        position: relative;
        line-height: 24px;
    }

    a {
        color: $color-grey;
        border-bottom: 1px solid rgba($color-blue,0);

        @media screen {
            transition: color $time, border-color $time;

            &:hover {
                color: $color-blue;
                border-color: currentColor;
            }
        }
    }
}

.secure-sale {
    padding: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
    max-width: 450px;

    @media screen and (max-width: 1200px) {
        max-width: 400px;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }

    b {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
    }

    img {
        max-width: 100%;
        opacity: .5;
    }
}

.mini-secure {
    img {
        max-width: 100%;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.secure-link {
    height: 30px;
    margin-top: 20px !important;
    display: block;

    img {
        max-height: 100%;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

@media screen and (min-width: 1921px)
{
	.midleimage {
		display:none !important;
	}
}
@media screen and (max-width: 1920px) {
	.bigimage
	{
		display:none !important;
	}
}
.addtowishlist{
    cursor: pointer;
	color: #f15732;
	position: relative;
	&:hover::after {
		content: "В избранное";
		position: absolute;
		left: 30px;
		top: 10px;
		z-index: 1;
		background: rgba(255,255,255,0.8);
		font-family: Arial, sans-serif;
		font-size: 11px;
		padding: 5px 10px;
		border: 1px solid #4a5548;
		color: #2d311f;
		width: 88px;
		-webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
		box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
	}
	&:before {
		display: inline-block;
		opacity: 0.9;
		margin-left: 0px;
	}
	&:hover::before {
		animation: nev 0.7s infinite alternate;
	}
}

@keyframes nev {
	0%  {
		opacity: 0.9;
		margin-left: 0px;
	}
	70%  {
		opacity: 0.9;
		margin-left: 0px;
		color: #f15732;
	}
	85%  {
		opacity: 1;
		margin-left: 1px;
		color: #ff0000;
	}
	100%  {
		opacity: 0.9;
		margin-left: 0px;
	}
}
.setting {
	padding-top: 20px;
	span {
		color: $red;
	}
	input{
		cursor: pointer;
	    height: auto;
	}
}
