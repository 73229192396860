//////////////////////////////////////////////////
//                  Header Nav
//////////////////////////////////////////////////

// Header

.sf-nav {
    padding: 0 75px 0 590px;
    position: relative;
    border-bottom: 1px solid #eee;
    user-select: none;

    ul {
        @include row-grid(0);
        white-space: nowrap;
    }

    li {
        display: inline-block;
        vertical-align: top;
        width: auto;
        position: static;
        margin-left: 0;
        padding: 0;

        a {
            display: block;
            text-align: center;
            white-space: nowrap;
            text-transform: none;
            color: $grey;
            line-height: 1;
            padding: 34px 20px 18px;
            font-size: 16px;
            position: relative;

            &.is-subnav {
                i {
                    display: none;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -10px;
                    font-size: 20px;
                }
                //
                //svg {
                //    display: none;
                //    width: 20px;
                //    height: 20px;
                //    fill: #8b8c8d;
                //
                //}
            }
        }

        &.active {

            a {
                color: $text;
            }

            ul a {
                color: $grey;
                white-space: normal;

                &.is-active {
                    color: $text;

                    @media screen and (max-width: 1023px) {
                        color: #fff;
                    }
                }

                @media screen {
                    &:hover {
                        color: $text;
                    }
                }

            }

        }

        &.sale {
            a {
                color: $red;
            }
        }

        &.for-brand-list {

            .sf-tab-list {

                ul {
                    padding-bottom: 40px;
                    min-height: initial;
                    position: relative;
                    @extend %clear;
                }

                .sf-all-button {
                    display: none;
                }
            }
        }

        &.kill-brendovaya-obuv {
            display: none;
        }

        &.sf-all-list {
            //display: block;
            display: none;
        }

        @media screen and (min-width: 1025px) {
            &:hover {

                & > .sf-sub-nav {
                    opacity: 1;
                    visibility: visible;
                }

                & > a {
                    color: $text;
                }
            }
        }
    }

    @media screen and (min-width: 1025px) {

        & > ul {
            text-align: center;

            ul {
                text-align: left;
            }
        }

        &.sf-all-list {
            display: none;
        }

        .sf-nav-close {
            display: none;
        }
    }

    @media screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 375px;
        height: 100%;
        border: 0;
        background-color: $dark;
        padding: 0;
        z-index: 1000;
        transform: translate3d(-100%, 0, 0);
        transition: transform 0.3s;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .sf-nav-close {
            //position: fixed;
            position: absolute;
            top: 0;
            left: 325px;
            border: 0;
            background-color: #000;
            width: 50px;
            height: 50px;
            z-index: 10000;

            span {
                display: block;
                position: absolute;
                top: 24px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: transparent;
                max-width: 20px;
                margin: auto;

                &:after,
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: rgba(#fff, 1);
                }

                &:before {
                    top: 0;
                    transform: rotate(45deg);
                }

                &:after {
                    bottom: 0;
                    transform: rotate(-45deg);
                }
            }

        }

        &.is-sub-open {
            overflow: hidden;
            -webkit-overflow-scrolling: hidden;

            li {
                display: none;

                &.is-su-sub-open {
                    display: block;

                    li {
                        display: block;
                    }
                }
            }
        }

        ul {
            display: block;
            white-space: normal;
            padding: 0 20px;
        }

        li {
            display: block;

            &:first-child {
                a {
                    border-top: 0;
                }
            }

            &.for-brand-list {
                border-bottom: 1px solid #323232;

                .sf-tab-nav {
                    .sf-all-button {
                        border: 0;
                        font-size: 15px;
                        line-height: 1;

                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }

                .sf-tab-list {
                    width: 375px;

                    ul {
                        padding-bottom: 20px;
                        position: absolute;
                    }
                    .sf-all-button {
                        display: none;
                    }
                }
            }

            &.active {
                background-color: transparent;

                a {
                    color: $red;

                    &.is-subnav {
                        color: $red;

                        svg {
                            fill: currentColor;
                        }

                    }
                }

                ul a {
                    color: #fff;
                    white-space: normal;

                    &.is-active {
                        color: $red;
                    }
                }
            }

            a {
                display: block;
                padding: 20px 0;
                font-size: 16px;
                font-weight: 600;
                text-transform: none;
                color: #fff;
                text-align: left;
                border-top: 1px solid #323232;

                &:before {
                    display: none;
                }

                &.is-subnav {
                    i {
                        display: initial;
                        transition: transform $time;
                    }
                    //svg {
                    //    display: block;
                    //    transition: transform $time;
                    //    fill: #fff;
                    //}

                    &.is-opened {

                        i {
                            transform: rotate(90deg);
                        }

                        & + .sf-sub-nav {
                            .sf-tab-nav {
                                display: block;
                                float: none;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .sf-sub-nav {
                transform: none;
                opacity: 1;
                visibility: visible;
                position: static;
                top: 0;
                border-top: 0;
                background-color: transparent;

                .sf-tab-nav {
                    padding: 0 0 15px 0;
                    display: none;

                    &:before {
                        display: none;
                    }
                    a {
                        display: block;
                        width: 100%;
                        margin: 0;
                        color: #fff;
                        height: 34px;
                        line-height: 34px;
                    }
                    button {
                        width: 100%;
                        margin: 0;
                        color: #fff;
                        text-align: left;
                        height: 34px;
                        text-transform: none;
                    }
                }

                .sf-tab-nav input {
                    display: none;
                }
            }

            &.sf-all-list {
                display: block;
            }
        }
    }

    @media screen and (max-width: 374px) {
        width: 100%;

        .sf-nav-close {
            left: auto;
            right: 0;
        }

        li {
            &.for-brand-list {
                .sf-tab-list {
                    width: 100%;
                }
            }
        }
    }

    .user-zone {
        right: auto;
        left: 0;
        width: 100%;
        padding: 0 10px;
        position: relative;
        top: 0;
        background: #000;

        .sf-dropdown {
            display: block;
            height: initial;

            .sf-dropdown-menu {
                position: relative;
                top: 0;
                left: auto;
                right: auto;
                width: 100%;
                background-color: transparent;
                padding-bottom: 5px;

                li {
                    margin-bottom: 3px;
                }

                a {
                    background-color: transparent;
                }
            }

            .sf-dropdown-toggle {
                height: 50px;
                line-height: 50px;
                background-color: transparent;

                &:before {
                    top: 20px;
                    right: 5px;
                }
            }
        }

        a {
            font-size: 14px;
            border: 0;

            &:before {
                display: none;
            }

            &.is-active {
                color: $red !important;
            }

            &.popup-link {
                line-height: 50px;

                i:not(.sf-icon-login) {
                    display: inline;
                    text-decoration: none;
                    font-style: normal;
                }
            }
        }
    }

    .sf-pre-nav {
        padding: 25px 10px 20px;

        a {
            color: #fff;
            margin: 0 10px;
            font-size: 14px;
        }
    }

}

.sf-featured {
    display: none;
}

.sf-sub-nav {
    position: absolute;
    top: 67px;
    border-top: 1px solid #eee;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #fff;
    opacity: 0;
    padding: 0 20px;
    visibility: hidden;
    @extend %clear;
}

.sf-tab-nav {
    position: relative;
    z-index: 4;
    padding: 25px 15px 20px 40px;
    display: block;
    width: 260px;
    float: left;
    white-space: normal;

    &:before {
        @extend %before;
        top: 30px;
        width: 1px;
        bottom: 0;
        background-color: #eee;
        left: 100%;
    }

    button {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        text-align: left;
        border: 0;
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        padding: 0 0 0 8px;
        text-transform: none;
        color: $grey;
        height: 40px;
        line-height: 1;
        position: relative;
        z-index: 2;

        //svg {
        //    width: 15px;
        //    height: 18px;
        //    position: absolute;
        //    right: 5px;
        //    //transform: rotate(180deg);
        //    fill: currentColor;
        //}

        i {
            font-size: 18px;
            position: absolute;
            right: 5px;
        }


        &.is-active {
            color: $dark;
            background-color: rgba(#eee, .2);
        }

        @media screen {
            transition: color $time, background-color $time;

            //svg {
            //    transition: fill $time;
            //}

            &:hover {
                color: $dark;
            }
        }

        @media screen and (max-width: 1024px) {
            &.is-active {
                color: $red;
                background-color: transparent;
            }
        }

    }

    strong {
        display: inline-block;
        vertical-align: top;
        border: 0;
        margin: 0;
        font-weight: 600;
        //font-family: $fontSbold;
        font-size: 12px;
        padding: 0;
        //text-transform: uppercase;
        text-transform: none;
        color: $dark;
        position: relative;
        z-index: 2;
    }

    input {
        //width: 450px;
        width: 100%;
        padding: 0;
        font-size: 14px;
        height: 30px;
        position: relative;
        z-index: 2;
        margin-bottom: 20px;
        //margin-left: 40px;
        border: 0;
        border-bottom: 1px solid rgba(#000, .1);
        transform: translateY(3px);
        display: none;

        &.is-notfound {
            border-color: $red;
            color: $red;
        }

        &.fss-instock {
            //width: 130px;
            //opacity: 0;
            //visibility: hidden;
            display: none;
        }
    }

    .sub-link {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin: 0;
        border: 0;
        font-weight: 400;
        padding: 0 0 0 10px;
        text-align: left;
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        position: relative;
        z-index: 2;
        color: $grey;
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase;
        }

        &.sale {
            color: $red;
        }

        &.active {
            color: $dark;
        }

        @media screen {
            transition: color $time;

            &:hover {
                color: $dark;
            }
        }
    }


}

.active .sf-tab-nav {
    .sub-link {
        color: $dark;

        &.active {
            color: $red;
        }

        @media screen {
            &:hover {
                color: $red;
            }
        }
    }

}

.sf-tab-list {
    position: relative;
    display: inline-block;
    width: calc(100% - 260px);
    vertical-align: top;
    z-index: 3;

    .subnav-search-list {
        position: absolute;
        padding-bottom: 10px;
        top: 0;
        left: 0;
        width: 100%;
        //margin-left: -30px;
        min-height: 350px;
        display: none;
        z-index: 2;
        padding-top: 20px;

        a {
            display: none;
            position: relative;
            font-size: 14px;
            border: 0;
            z-index: 3;
            text-align: left;
            font-weight: 400;
            //font-family: $font;
            line-height: 1.4;
            padding: 7px 0;
            white-space: normal;
            text-transform: none;

            &:before {
                display: none;
            }

            @media screen {
                transition: color $time;

                &:hover {
                    color: $red;
                }
            }

            &.is-show {
                display: block;
                margin-left: 30px;
                font-size: 15px;
                color: #000;
            }
        }

        &.is-show {
            display: block;
        }

    }

    ul {
        position: absolute;
        padding: 20px 0 30px;
        top: 0;
        left: 0;
        width: 100%;
        //margin-left: -30px;
        min-height: 350px;
        //padding-top: 20px;
        display: none;
        @extend %clear;

        &:before {
            display: none;
            //@extend %before;
            ////background-color: #f2f2f2;
            //background-color: #fff;
            //top: 5px;
            //width: 100vw;
            //left: 10px;
            //right: 0;
            //bottom: 0;
            //z-index: 1;
        }

        .nav-col {
            float: left;
            margin: 0;

            &.last {
                clear: right;
            }
        }

        &.sf-4-col {
            .nav-col {
                width: calc(25% - 30px);
                margin-left: 30px;
            }
        }

        &.sf-3-col {
            .nav-col {
                width: calc((100% / 3) - 30px);
                margin-left: 30px;
            }
        }

        &.sf-5-col {
            .nav-col {
                width: calc(20% - 30px);
                margin-left: 30px;
            }
        }

        li {
            display: block;
            padding: 0;
            position: relative;
            z-index: 2;
            margin-left: 0;

            a {
                text-transform: none;
                font-size: 14px;
                display: inline-block;
                vertical-align: top;
                font-weight: 400;
                //font-family: $font;
                white-space: normal;
                line-height: 1.4;
                text-align: left;
                padding: 12px 0;
                border: 0;

                &:first-letter {
                    font-weight: 600;
                    //font-family: $fontSbold;
                }

                @media screen {
                    padding: 7px 0;
                    transition: color $time;

                    &:hover {
                        color: $red;
                    }
                }

                &:before {
                    display: none;
                }

            }

            &.is-active {
                color: $red;
                font-size: 14px;

                a {
                    color: $red;
                    font-size: 14px;
                }

                @media screen and (max-width: 1024px) {
                    margin: 0 -20px;
                    padding: 0 20px;
                    background-color: rgba($red,.1);
                }

            }

        }

        &.is-show {
            display: block;
        }
    }

    //&.sft-instock {
    //    width: 100%;
    //}

    .open-subnav {
        top: 0;
        left: 0;
        position: absolute;
        width: 375px;
        height: 50px;
        background: #000;
        font-size: 14px;
        color: #fff;
        text-align: left;
        padding: 0 20px;
        z-index: 2;
        display: none;

        svg {
            width: 20px;
            height: 20px;
            fill: #fff;
            vertical-align: text-top;
        }
    }

    //&.is-open-sub {
    //    box-shadow: 0 1px 3px rgba(#000, .3);
    //}

    @media screen and (min-width: 1025px){
        //display: none;
        display: block;
        width: calc(100% - 260px);
        float: left;

        ul {
            position: relative;
        }

        &.is-open-sub {
            display: inline-block;
        }
    }

    @media screen and (max-width: 1024px) {
        display: block;
        position: fixed;
        top: 0;
        left: -100%;
        width: 375px;
        height: 100%;
        z-index: 50;
        //transform: translate3d(-100%,0,0);
        //transition: transform $time;

        .subnav-search-list {
            display: none;
        }

        ul {
            margin: 0;
            min-height: initial;
            display: block;
            padding: 0 20px 20px;
            top: 50px;
            bottom: 0;
            left: 0;
            width: 375px;
            background-color: $text;
            z-index: 1;
            overflow: hidden;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            transform: translate3d(0,0,0);
            transition: transform $time;

            &:before {
                display: none;
            }

            & ~ .open-subnav {
                transform: translate3d(-100%,0,0);
            }

            &.is-show {
                &.is-active-ul {
                    display: block;
                    transform: translate3d(100%,0,0);
                    //left: 375px;

                    & ~ .open-subnav {
                        //left: 375px;
                        transform: translate3d(100%,0,0);
                    }
                }
            }
        }

        .open-subnav {
            display: block;
        }
    }

    @media screen and (max-width: 374px) {
        width: 100%;

        .open-subnav {
            width: 100%;
        }

        ul {
            width: 100%;
        }
    }
}

.sf-wishlist {
    //position: absolute;
    //height: 45px;
    //line-height: 45px;
    //top: -45px;
    //right: 350px;
    //z-index: 4;
    //color: #fff;
    //display: none;

    color: $grey;

    svg {
        width: 20px;
        height: 20px;
        fill: currentColor;
        vertical-align: text-bottom;

        & + span {
            margin-left: 3px;
        }
    }

    @media screen {
        svg {
            transition: fill $time;
        }

        &:hover {
            color: $text;
            //
            //svg {
            //    fill: $text;
            //}
        }
    }

    //@media screen and (min-width: 1025px) {
    //    transition: color $time;
    //
    //    svg {
    //        display: none;
    //    }
    //
    //    ins {
    //        display: inline;
    //        font-style: normal;
    //        text-decoration: none;
    //
    //        &:before {
    //            content: '(';
    //        }
    //        &:after {
    //            content: ')';
    //        }
    //    }
    //
    //    &:hover {
    //        color: $red;
    //
    //        svg {
    //            fill: $red;
    //        }
    //    }
    //}
    //
    //@media screen and (min-width: 1025px) and (max-width: 1100px) {
    //    right: 335px;
    //}
    //
    //@media screen and (max-width: 1024px) {
    //    width: 50px;
    //    height: 50px;
    //    right: 135px;
    //    top: 0;
    //
    //    svg {
    //        display: initial;
    //        fill: $dark;
    //        margin-right: 0;
    //        vertical-align: middle;
    //        width: 26px;
    //        height: 26px;
    //    }
    //
    //    ins {
    //        text-decoration: none;
    //        position: absolute;
    //        top: 4px;
    //        left: 17px;
    //        display: block;
    //        width: 20px;
    //        height: 20px;
    //        background-color: rgba($red, .9);
    //        line-height: 20px;
    //        text-align: center;
    //        border-radius: 10px;
    //        font-size: 10px;
    //        font-weight: 700;
    //        //font-family: $fontBold;
    //
    //        &:before,
    //        &:after {
    //            display: none;
    //        }
    //    }
    //
    //    b {
    //        display: none;
    //    }
    //}

}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(#000, .4);
}
