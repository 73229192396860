/* perfect-scrollbar v0.6.7 */
.ps-container {
    -ms-touch-action: none;
    overflow: hidden !important;

    &.ps-in-scrolling {
        pointer-events: none;

        &.ps-x {

            & > .ps-scrollbar-x-rail {
                background-color: #eee;
                opacity: 0.9;

                & > .ps-scrollbar-x {
                    background-color: #999;
                }
            }

        }

        &.ps-y {

            & > .ps-scrollbar-y-rail {
                background-color: #eee;
                opacity: 0.9;

                & > .ps-scrollbar-y {
                    background-color: #999;
                }
            }

        }

        &:hover {
            pointer-events: none;
        }

    }

    & > .ps-scrollbar-x-rail {
        display: none;
        position: absolute;
        /* please don't change 'position' */
        border-radius: 0;
        opacity: 0;
        transition: background-color 0.2s linear, opacity 0.2s linear;
        bottom: 3px;
        /* there must be 'bottom' for ps-scrollbar-x-rail */
        height: 2px;

        & > .ps-scrollbar-x {
            position: absolute;
            /* please don't change 'position' */
            background-color: #aaa;
            border-radius: 4px;
            transition: background-color 0.2s linear;
            bottom: 0;
            /* there must be 'bottom' for ps-scrollbar-x */
            height: 2px;
        }
    }

    & > .ps-scrollbar-y-rail {
        display: none;
        position: absolute;
        /* please don't change 'position' */
        border-radius: 0;
        cursor: pointer;
        cursor: -webkit-grab;
        //opacity: 0;
        transition: background-color 0.2s linear, opacity 0.2s linear;
        right: 1px;
        background-color: #eee;
        /* there must be 'right' for ps-scrollbar-y-rail */
        width: 2px;

        & > .ps-scrollbar-y {
            position: absolute;
            /* please don't change 'position' */
            background-color: #999;
            border-radius: 0;
            transition: background-color 0.2s linear;
            right: 0;
            /* there must be 'right' for ps-scrollbar-y */
            width: 2px;
        }

    }

}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    opacity: 0.6;
}

.ps-container {

    &:hover {

        &.ps-in-scrolling {

            &.ps-x {
                & > .ps-scrollbar-x-rail {
                    background-color: #eee;
                    opacity: 0.9;

                    & > .ps-scrollbar-x {
                        background-color: #999;
                    }
                }
            }

            &.ps-y {
                 & > .ps-scrollbar-y-rail {
                     background-color: #eee;
                     opacity: 0.9;

                     & > .ps-scrollbar-y {
                         background-color: #999;
                     }
                 }
            }

        }

        & > .ps-scrollbar-x-rail,
        & > .ps-scrollbar-y-rail {
            opacity: 0.6;
        }

        & > .ps-scrollbar-x-rail:hover {
            background-color: #eee;
            opacity: 0.9;

            & > .ps-scrollbar-x {
                background-color: #999;
            }
        }

        & > .ps-scrollbar-y-rail:hover {
            background-color: #eee;
            opacity: 0.9;

            & > .ps-scrollbar-y {
                background-color: #999;
            }
        }

    }

}