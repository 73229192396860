////////////////////////////////////////////////////////
////    Media Query
////////////////////////////////////////////////////////

@media screen and (max-width: 1000px) {

    .privateOffice .col-lg-10,
    .brandAlphabet li {
        margin: 3px 15px
    }

    .brandsRow {
        padding: 15px 0 25px
    }

    .brandLetter {
        font-size: 41px;
        margin: 0 -15px 15px
    }

    .orderStatus {
        margin: 30px 0
    }

    .colorSizeRow .col-lg-6 {
        width: 50%;
        float: left
    }
    .sPhotoTitle {
        margin: 20px auto
    }

    .holder iframe {
        width: 100%
    }
}

@media screen and (max-width: 750px) {

    .page404 > .child {
        width: 490px
    }

    .saleMan {
        display: none
    }

    .brandAlphabet {
        padding: 12px
    }
    .brandAlphabet li {
        margin: 5px 15px
    }

    .copyCode input[type="text"] {
        border-right: 1px solid #e7e7e7
    }

    .sUpload {
        width: 122px
    }
    .sFormUpload {
        padding: 30px
    }

    .sParams {
        padding: 20px 30px;
    }
    .sFormInfo {
        padding: 30px 30px 0
    }

    .pagerTop,
    .addComm input[type="text"], .quickLeft, .quickRight,
    .sizeParams, .sizeQuest {
        float: none !important
    }

    .addComm input[type="text"] {
        margin: 0 0 15px
    }

    .payModal {
        height: 595px
    }

    .holder iframe {
        height: 300px
    }
    .privateFav td {
        display: block;
        float: left;
        height: 100px
    }
    .privateFav tr:first-child td {
        height: 80px
    }
    .privateFav .describe {
        width: 418px;
        padding: 19px 0 26px
    }
    .privateFav .describe p {
        max-width: none
    }
    .privateFav .salePrice, .privateFav .price, .privateFav .butt, .privateFav .butDel {
        border-bottom: none;
        line-height: 38px;
        padding-top: 10px
    }
    .privateFav .salePrice {
        text-align: left;
        margin: 0 0 0 72px
    }
    .privateFav .price {
        width: 150px
    }
    .privateFav tr:first-child .salePrice, .privateFav tr:first-child .price, .privateFav tr:first-child .butt, .privateFav tr:first-child .butDel {
        padding-top: 10px
    }
    .page404 {
        padding: 20px 0 0
    }
    .menu404 {
        margin: 0 -21px 60px
    }
    .menu404 li {
        margin: 0 21px
    }
    .copyr404 {
        bottom: -15px
    }
}

@media screen and (max-width: 570px) {
    .page404 > .child {
        width: 450px
    }
    .menu404 {
        margin: 0 -16px 100px
    }
    .menu404 li {
        margin: 0 16px
    }
    .copyr404 {
        position: relative;
        margin: 0 0 20px;
        left: auto;
        bottom: auto
    }
}

@media screen and (max-width: 509px) {

    .page404 > .child {
        width: 360px
    }

    .newClient {
        font-size: 13px
    }

    .sPhotoInfo p:first-child {
        font-size: 22px
    }
    .sInfographic {
        display: none
    }
    .sLoadblock {
        height: 100px;
        input[type="file"] {
            height: 100px
        }
        input[type=text],
        .btn {
            width: 100%
        }
        input[type=text] {
            margin: 0 0 10px
        }
    }

    //.selColor, .selSex, .selSize {
    //    margin: 0 0 10px
    //}
    //.sFormInfo .name, .sFormInfo .emailInp {
    //    width: 296px
    //}

    .tellFr {
        height: 72px
    }
    .social-likes__widget {
        margin: 0 15px 8px 0
    }

    .payModal {
        height: 750px;
        bottom: auto
    }

    .sizeQuest input[type="text"], .sizeQuest, .sizeQuest .emailInp, .sizeQuest textarea {
        width: 100%
    }

    .similarNews {
        margin: 0 0 0 -15px
    }

    #payTab {
        li {
            &:nth-child(2){
                line-height: 18;
                width: 50%;

                a {
                    padding: 8px 12px;
                    line-height: 18px;
                }
            }
        }
    }

    #deliveryTab li {
        width: 32.9%;

        a {
            height: 51px;
            line-height: 18px;
            padding: 7px 5px 0 4px
        }
    }

    .holder iframe {
        height: 240px
    }
    .privateFav .describe {
        width: 288px
    }
    .privateFav .salePrice {
        margin: 0;
        width: 75px
    }
    .privateFav .salePrice span {
        margin: 0
    }
    .privateFav .price {
        width: 118px;
        padding-right: 5px
    }
    .privateFav .price span {
        font-size: 20px
    }
    .error404 p {
        font-size: 21px
    }
    .menu404 {
        margin: 0 0 40px
    }
    .menu404 li {
        display: block;
        margin: 0 0 10px
    }
}

@media screen and (max-width: 374px) {

    .page404 > .child {
        width: 300px
    }

    .sPhotoInfo p:first-child {
        font-size: 18px
    }
    .sPhotoInfo p + p {
        font-size: 13px
    }
    .sFormUpload {
        padding: 20px;

        p {
            font-size: 15px
        }
        .urlInp {
            margin: 10px 0 0;
            width: 100%
        }
    }
    .sFormInfo {
        padding: 20px 20px 0
    }
    .holder iframe {
        height: 200px
    }
    .error404 p {
        font-size: 16px
    }
    .error404 span {
        font-size: 167px
    }

    .copyCode input[type="text"] {
        width: 149px
    }

    .privateFav .describe {
        width: 228px
    }
    .privateFav .salePrice, .privateFav .price, .privateFav tr:first-child .salePrice, .privateFav tr:first-child .price {
        height: 50px
    }
    .privateFav .price {
        width: 195px
    }
}

@-ms-viewport {
    width: auto !important
}
